<template>
  <div>
    <!-- Contacts table -->
    <div class="card pagination-margin"
         qid="contacts-section"
         id="update_contacts"
    >
      <div class="card-body" :class="{'pr-0 pl-0': showOnTask}">
        <div class="row">
          <div class="col-sm-6">
            <div class="float-left">
              <h5 class="mb-2" qid="measure-table-title" v-if="!showOnTask">
                {{ $t('maintenance.third_parties.contacts.title') }}
              </h5>
              <span v-if="!showOnTask">
                <span class="cursor-pointer task-label-open mr-2"
                      v-if="getTask('update_contacts')"
                >
                  <UpdateSingleTaskModal
                      :task="getTask('update_contacts')"
                      @reloadContent="reloadContent"
                      :editIcon="true"
                  ></UpdateSingleTaskModal>
                </span>
                <span v-if="getGuide('update_contacts')">
                  <GuideButton :guide="getGuide('update_contacts')"></GuideButton>
                </span>
              </span>
            </div>
          </div>
          <div class="col-sm-6 text-right">
            <span class="btn btn-success cursor-pointer float-right"
                    @click="showCreateContactModal()"
                    qid="retention-rule-create-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"
            >
              <font-awesome-icon icon="plus"/> {{ $t('maintenance.third_parties.contacts.add_new') }}
            </span>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('name')"
                qid="sort-s-name"
            >
              {{ $t('activities.measures.table.name') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
              </span>
            </th>
            <!-- <th scope="col">
              {{ $t('maintenance.third_parties.contacts.type') }}
            </th>
            <th scope="col">
              {{ $t('maintenance.third_parties.contacts.category') }}
            </th> -->
            <th scope="col">
              {{ $t('maintenance.third_parties.contacts.email') }}
            </th>
            <th scope="col">
              {{ $t('maintenance.third_parties.contacts.phone') }}
            </th>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('updated_at')"
                qid="sort-s-name"
            >
              {{ $t('system.last_updated') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
              </span>
            </th>
            <th width="180"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(object, index) in contactTableItems" :key="index">
              <td style="line-height: 1.8;">
                <span class="mr-2 float-left" v-if="!object.user">
                  <span class="rounded-circle user-avatar xs"
                        style="margin-top:-2px; position:relative;"
                        :style="{'background-image': getDeletedUserImage}"
                  ></span>
                </span>
                <span class="mr-2 float-left" v-if="object.user">
                  <Avatar :object="object.user" size="xs"></Avatar>
                </span>
                <span qid="tp-receiver-name">
                  {{ object.name }}
                </span>
              </td>
              <!-- <td>
                <span v-if="object && object.type" class="text-normal">
                  {{ $t('system.' + $options.filters.slugify(object.type)) }}
                </span>
              </td>
              <td>
                <span v-if="object && object.category" class="text-normal">
                  <font-awesome-icon icon="shapes"/>
                  {{ object.category }}
                </span>
              </td> -->
              <td>
                <span class="text-normal">
                  {{ object.email }}
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.phone }}
                </span>
              </td>
              <td>
                <span v-if="object" class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td class="text-right">
                <span v-if="isGranted([permissions.THIRD_PARTY_DOCUMENT_EDIT, permissions.THIRD_PARTY_DOCUMENT_EDIT_OWN], project) && (!getClient.parent || (getClient.parent && project.partition))"
                      class="btn btn-edit"
                      qid="edit-p-modal"
                      @click="showEditContactsModal(object)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                <span @click="openViewContactModal(object)"
                      class="btn btn-view cursor-pointer"
                >
                  <font-awesome-icon icon="eye"/>
                </span>
                <span class="btn btn-delete cursor-pointer"
                      qid="delete-contact-modal"
                      @click="showContactConfirmDeleteModal(object)"
                      v-if="isGranted([permissions.THIRD_PARTY_DOCUMENT_DELETE, permissions.THIRD_PARTY_DOCUMENT_DELETE_OWN], project) && (!getClient.parent || (getClient.parent && project.partition))"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
              </td>
            </tr>

            <tr v-if="!contactLoading && !contactTableItems.length" qid="no-results-row-rr" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>

            <tr  v-if="contactLoading && !contactTableItems.length" qid="no-results-row-rr" class="text-center">
              <td colspan="10">
                <div class="mt-2 mb-4">
                  <div class="swing-container">
                    <div class="swing">
                      <div class="swing-l"></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div class="swing-r"></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete document modal template -->
    <b-modal ref="delete-contact" modal-class="task-modal" @hide="hideContactConfirmDeleteModal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideContactConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-contact-title">
            {{ $t('maintenance.measures.delete.title') }}
          </h5>
        </div>
      </template>

      <div class="mb-2 mt-3" style="font-size:18px;" v-if="itemToDelete && itemToDelete.id">
        <div>
          {{ $t('maintenance.measures.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.name }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('maintenance.measures.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <span class="btn btn-secondary cursor-pointer"
                        @click="hideContactConfirmDeleteModal"
                        qid="delete-contact-cancel-button"
                  >
                    {{ $t('maintenance.measures.delete.cancel') }}
                  </span>
                  <b-button type="submit"
                            class="ml-3"
                            variant="danger"
                            @click="confirmDeleteContact(itemToDelete)"
                            :disabled="buttonDisabled"
                            qid="delete-contact-submit-button"
                  >
                    {{ $t('maintenance.measures.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Add new contact -->
    <b-modal ref="add-contact"
             hide-footer
             modal-class="task-modal"
             @hide="hideCreateContactModal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateContactModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="add-contact-title">
            {{$t('maintenance.third_parties.contacts.add_modal_title')}}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="add-contact-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="formSubmit" qid="add-contact-form">
        <div class="form-container">
          <!-- <b-form-group>
            <label qid="create-p-implementation-status-label">
              {{ $t('maintenance.third_parties.contacts.create.type_label') }}
            </label>
            <span class="text-red">*</span>
            <multiselect
                v-model="form.type"
                :options="types"
                :placeholder="$t('maintenance.third_parties.contacts.create.type_label')"
                label="name"
                track-by="value"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="create-p-form-implementation-status"
            ></multiselect>
          </b-form-group> -->
          <!-- <b-form-group :class="{'multiselect--show-input': categoryInput.length}">
            <label qid="create-do-type-label">
              {{ $t('maintenance.data_objects.create.category_label') }}
            </label>
            <span class="text-red">*</span>
            <multiselect
                v-model="form.category"
                :options="categoryItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('maintenance.data_objects.create.category_placeholder')"
                label="category"
                :preserveSearch="true"
                @search-change="handleCategoryInput"
                qid="create-do-form-category"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.data_objects.create.category_no_results') }}
              </template>
            </multiselect>
          </b-form-group> -->
          <b-form-group>
            <label qid="add-contact-name-label">
              {{ $t('maintenance.third_parties.contacts.create.email_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="add-contact-form-name"
              type="email"
              v-model="form.email"
              required
              @change="loadUserByEmail"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.contacts.create.email_placeholder')"
            ></b-form-input>
          </b-form-group>
          <div class="text-muted mt-2 mb-2">
            {{ $t('maintenance.third_parties.contacts.create.user_tip') }}
          </div>
          <br>
          <b-form-group>
            <label qid="add-contact-name-label">
              {{ $t('maintenance.third_parties.create.name_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="add-contact-form-name"
              type="text"
              v-model="form.name"
              required
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="add-contact-name-label">
              {{ $t('maintenance.third_parties.contacts.create.phone_label') }}
            </label>
            <b-form-input
              qid="add-contact-form-name"
              type="text"
              v-model="form.phone"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.contacts.create.phone_placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-3"
                          variant="secondary"
                          @click="hideCreateContactModal"
                          qid="add-contact-cancel-button"
                >
                  {{ $t('activities.measures.create.cancel') }}
                </b-button>

                <b-button type="submit"
                          class="mt-3 ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="add-contact-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('activities.measures.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Edit document  -->
    <b-modal ref="edit-contact"
             hide-footer
             modal-class="task-modal"
             @hide="hideEditContactModal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditContactModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="edit-contact-title">
            {{$t('maintenance.third_parties.contacts.edit_modal_title')}}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-contact-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="formEditSubmit" qid="edit-contact-form">
        <div class="form-container">
          <!-- <b-form-group>
            <label qid="create-p-implementation-status-label">
              {{ $t('maintenance.third_parties.contacts.create.type_label') }}
            </label>
            <span class="text-red">*</span>
            <multiselect
                v-model="editForm.type"
                :options="types"
                :placeholder="$t('maintenance.third_parties.contacts.create.type_label')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="create-p-form-implementation-status"
            ></multiselect>
          </b-form-group>
          <b-form-group :class="{'multiselect--show-input': categoryInput.length}">
            <label qid="create-do-type-label">
              {{ $t('maintenance.data_objects.create.category_label') }}
            </label>
            <span class="text-red">*</span>
            <multiselect
                v-model="editForm.category"
                :options="categoryItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('maintenance.data_objects.create.category_placeholder')"
                label="category"
                track-by="category"
                :preserveSearch="true"
                @search-change="handleCategoryInput"
                qid="create-do-form-category"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.data_objects.create.category_no_results') }}
              </template>
            </multiselect>
          </b-form-group> -->
          <b-form-group>
            <label qid="edit-contact-name-label">
              {{ $t('maintenance.third_parties.contacts.create.email_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="edit-contact-form-name"
              type="email"
              v-model="editForm.email"
              required
              @change="loadEditUserByEmail"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.contacts.create.email_placeholder')"
            ></b-form-input>
          </b-form-group>
          <div class="text-muted mt-2 mb-2">
            {{ $t('maintenance.third_parties.contacts.create.user_tip') }}
          </div>
          <br>
          <b-form-group>
            <label qid="edit-contact-name-label">
              {{ $t('maintenance.third_parties.create.name_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="edit-contact-form-name"
              type="text"
              v-model="editForm.name"
              required
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="edit-contact-name-label">
              {{ $t('maintenance.third_parties.contacts.create.phone_label') }}
            </label>
            <b-form-input
              qid="edit-contact-form-name"
              type="text"
              v-model="editForm.phone"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.contacts.create.phone_placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-3"
                          variant="secondary"
                          @click="hideEditContactModal"
                          qid="edit-contact-cancel-button"
                >
                  {{ $t('activities.measures.create.cancel') }}
                </b-button>

                <b-button type="submit"
                          class="mt-3 ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="edit-contact-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('system.save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- View contact modal -->
    <b-modal ref="view-contact"
             hide-footer
             modal-class="task-modal"
             @hide="hideViewContactModal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideViewContactModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="view-contact-title">
            {{$t('maintenance.third_parties.contacts.view_modal_title')}}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="view-contact-error"
      >
        {{ error }}
      </b-alert>

      <div v-if="selectedViewItem">
        <label qid="view-contact-name-label" class="mt-3">
          {{ $t('maintenance.third_parties.create.name_label') }}
        </label>
        <br>
        <span qid="view-contact-name">
          {{ selectedViewItem.name }}
        </span>
        <br>

        <!-- <label qid="view-contact-type-label" class="mt-3">
          {{ $t('maintenance.third_parties.contacts.create.type_label') }}
        </label>
        <br>
        <span qid="view-contact-type" v-if="selectedViewItem.type">
          {{ $t('system.' + $options.filters.slugify(selectedViewItem.type)) }}
        </span>
        <br>

        <label qid="view-contact-category-label" class="mt-3">
          {{ $t('maintenance.data_objects.create.category_label') }}
        </label>
        <br>
        <span qid="view-contact-category">
          {{ selectedViewItem.category }}
        </span>
        <br> -->

        <label qid="view-contact-email-label" class="mt-3">
          {{ $t('maintenance.third_parties.contacts.create.email_label') }}
        </label>
        <br>
        <span qid="view-contact-email">
          {{ selectedViewItem.email }}
        </span>
        <br>

        <label qid="view-contact-phone-label" class="mt-3">
          {{ $t('maintenance.third_parties.contacts.create.phone_label') }}
        </label>
        <br>
        <span qid="view-contact-phone">
          {{ selectedViewItem.phone }}
        </span>
        <br>
      </div>

      <div class="proxy-footer">
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="text-right">
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="hideViewContactModal"
                        qid="view-contact-cancel-button"
              >
                {{ $t('system.close') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import roleCheck from "@/util/ACL/roleCheck"
  // import ValidationList from '@/views/Validations/ValidationList.vue';
  // import ValidationForm from '@/views/Validations/ValidationForm.vue';
  // import MeasureCreateForm from '@/views/Measures/MeasureCreateForm.vue'
  // import Datepicker from 'vuejs-datepicker';
  import GuideButton from '@/components/guide/GuideButton.vue';
  import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';


  export default {
    name: 'ContactsTable',
    data: function() {
      return {
        form: {
          name: '',
          email: '',
          phone: '',
          category: [],
          type: [],
        },
        editForm: {
          name: '',
          email: '',
          phone: '',
          category: [],
          type: []
        },
        error: '',
        editError: '',
        loadingItems: false,
        // selectedDocument: [],
        selectedTableItem: [],
        // viewRRModal: false,
        // editRRModal: false,
        // selectedDocumentSort: '',
        // selectedDocumentDirection: '',
        // acValidationShow: false,
        contactTableItems: [],
        // documentSingleTableItems: [],
        contactLoading: true,
        // measureItems: [],
        // updateValidationList: false,
        itemToDelete: {},
        itemObject: {},
        // reCertificationOptions: [],
        // provisioningOptions: [],
        // selectedRC: [],
        // selectedP: [],
        // validationOptions: [],
        // validation: undefined,
        // disabledValidationOptions: [],
        // showRRDescription: false,
        buttonDisabled: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        selectedItem: {},
        selectedParentDocument: {},
        viewMeasureModal: false,
        myGuides: [],
        selectedAnswer: [],
        selectedDate: '',
        selectedNumber: '',
        inputLinkName: '',
        inputLink: '',
        allAnswers: [],
        selectedFiles: [],
        // categoryItems: [],
        // categoryInput: '',
        // types: [
        //   { name: this.$t('system.internal'), value: 'internal' },
        //   { name: this.$t('system.external'), value: 'external' }
        // ],
        checklistOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        showHistoricDocuments: false,
        hasEndDate: 1,
        selectedViewItem: {},
        foundUser: {},
        myTasks: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      showOnTask: {},
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      // ValidationList,
      // ValidationForm,
      // Datepicker,
      // MeasureCreateForm,
      GuideButton,
      UpdateSingleTaskModal
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getDeletedUserImage() {
        let link = require('@/assets/deleted-user-avatar.png');
        let url = 'url(' + link + ')';
        return url
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      getReloadContent() {
        return this.$store.state.updateContent
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContacts();
        // this.loadCategories();
      },
      // object: function (newValue) {
      //   if (newValue && newValue.id) {
      //     this.project = newValue
      //     this.loadContacts();
      //   }
      // },
      getReloadContent: function () {
        if (!this.showOnTask) {
          if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
            if (this.$store.state.updatedObject.id == this.project.id && this.$store.state.updatedObject.who_am_i == this.project.who_am_i) {
              this.project = this.$store.state.updatedObject
            }
          }
          this.reloadContent()
        }
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadContacts()
      }, 400),
      checkIfAdmin() {
        if (roleCheck('Client admin') || roleCheck('Partition admin')) {
          return true
        } else {
          return false
        }
      },
      reloadContent() {
        this.loadContacts();
      },
      loadUserByEmail() {
        let self = this
        piincHttp.get('users', { params:
          {
            email: this.form.email,
            client: this.getClient.id
          }
        }).then(function(response) {
          self.loading = false;
          if (response.data.items && response.data.items.length) {
            self.foundUser = response.data.items[0]
            self.form.name = self.foundUser.name;
            self.form.phone = self.foundUser.phone;
          }
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      loadEditUserByEmail() {
        let self = this
        this.foundUser = {}
        piincHttp.get('users', { params:
          {
            email: this.editForm.email,
            client: this.getClient.id
          }
        }).then(function(response) {
          self.loading = false;
          if (response.data.items && response.data.items.length) {
            self.foundUser = response.data.items[0]
            self.editForm.name = self.foundUser.name;
            self.editForm.phone = self.foundUser.phone;
          }
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      openViewContactModal(object) {
        this.selectedViewItem = object
        this.$refs['view-contact'].show()
      },
      hideViewContactModal () {
        this.selectedViewItem = []
        this.$refs['view-contact'].hide()
      },
      handleCategoryInput(input) {
        this.categoryInput = input;
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadContacts();
      },
      getTask(code) {
        let existingTask = _.find(this.myTasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Third party' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Third party',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.form.links.splice(index, 1);
      },
      showCreateContactModal() {
        // this.loadCategories();
        this.form.name = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.category = []
        this.form.type = []
        this.categoryInput = ''
        this.foundUser = {}

        this.$refs['add-contact'].show()
      },
      hideCreateContactModal() {
        this.error = ''
        this.selectedTableItem = []
        this.loadContacts()
        // this.loadCategories();
        this.$refs['add-contact'].hide()
      },
      showEditContactsModal(object) {
        this.selectedTableItem = object
        this.editForm.name = ''
        this.editForm.category = []
        this.categoryInput = ''
        this.editError = ''
        this.foundUser = {}

        // this.editForm.type = []
        // if (object.type) {
        //   this.editForm.type = _.find(this.types, function(item) {
        //     return item.value === object.type;
        //   });
        // }

        if (object.category) {
          this.editForm.category = []
          this.editForm.category = _.find(this.categoryItems, function(item) {
            return item.category === object.category;
          });
        }

        this.editForm.name = object.name;
        this.editForm.email = object.email;
        this.editForm.phone = object.phone;

        this.$refs['edit-contact'].show()
      },
      hideEditContactModal() {
        this.editError = ''
        // this.editForm.type = []
        // this.editForm.category = []
        this.editForm.name = '';
        this.editForm.email = '';
        this.editForm.phone = '';
        this.loadContacts()
        // this.loadCategories();
        this.$refs['edit-contact'].hide()
      },
      showContactConfirmDeleteModal(object) {
        this.itemToDelete = object;
        this.$refs['delete-contact'].show();
      },
      hideContactConfirmDeleteModal() {
        this.itemToDelete = {};
        this.loadContacts();
        this.$refs['delete-contact'].hide();
      },
      confirmDeleteContact(object) {
        let self = this;
        piincHttp.delete('projects/' + this.project.slug + '/contacts/' + object.id).then(function() {
          self.hideContactConfirmDeleteModal();
          self.loadContacts();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('maintenance.third_parties.contacts.delete_success'))
        }, function() {});
      },
      formSubmit(evt) {
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // if (this.form.type && this.form.type.value) {
        //   formData.append("type", this.form.type.value);
        // }

        // Additional check - if category is selected, append it to form data
        // if (this.form.category && this.form.category.category) {
        //   formData.append("category", this.form.category.category);
        // } else {
        //   if (this.categoryInput && this.categoryInput.length) {
        //     formData.append("category", this.categoryInput);
        //   }
        // }

        formData.append("name", this.form.name);
        formData.append("email", this.form.email);

        if (this.form.phone) {
          formData.append("phone", this.form.phone);
        }

        // if (
        //     ((this.form.category && this.form.category.category) || (this.categoryInput && this.categoryInput.length)) &&
        //     (this.form.type && this.form.type.value)
        // ) {
          this.buttonDisabled = true;
          piincHttp.post('/projects/' + this.project.slug + '/contacts', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.loadContacts(self.currentPage);
            self.$toastr('success', self.$t('maintenance.third_parties.contacts.create_success'))
            self.hideCreateContactModal();

            self.$emit('updated');
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        // } else {
        //   this.error = ''
        //   if (!this.form.type || (this.form.type && !this.form.type.value)) {
        //     this.error +=  ' ' + this.$t('maintenance.third_parties.contacts.type_error')
        //   }
        //   if ((!this.form.category || (this.form.category && !this.form.category.category)) && !this.categoryInput.length) {
        //     this.error +=  ' ' +  this.$t('maintenance.third_parties.contacts.category_error')
        //   }
        //   this.buttonDisabled = false;
        // }

      },
      formEditSubmit(evt) {

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // if (
        //   this.editForm.type && this.editForm.type.value &&
        //   ((this.editForm.category && this.editForm.category.category) || (this.categoryInput && this.categoryInput.length))
        // ) {
        //   this.buttonDisabled = true;
        //
        //   this.editForm.type = this.editForm.type ? this.editForm.type.value : ''
        //
        //   if (this.categoryInput && this.categoryInput.length) {
        //     this.editForm.category = this.categoryInput
        //   } else {
        //     if (this.editForm.category && this.editForm.category.category) {
        //       this.editForm.category = this.editForm.category.category
        //     } else {
        //       this.editForm.category = '';
        //     }
        //   }

          piincHttp.put('/projects/' + this.project.slug + '/contacts/' + this.selectedTableItem.id, this.editForm).then(function() {
            self.hideEditContactModal();
            self.loadContacts(self.currentPage);
            self.buttonDisabled = false;
            self.$toastr('success', self.$t('maintenance.third_parties.contacts.update_success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.editError = error.data.message;
            }
          })

        // } else {
        //   this.editError = ''
        //   if (!this.form.type || (this.form.type && !this.form.type.value)) {
        //     this.editError +=  ' ' + this.$t('maintenance.third_parties.contacts.type_error')
        //   }
        //   if ((!this.form.category || (this.form.category && !this.form.category.category)) && !this.categoryInput.length) {
        //     this.editError +=  ' ' +  this.$t('maintenance.third_parties.contacts.category_error')
        //   }
        //   this.buttonDisabled = false;
        // }

      },
      loadContacts(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('projects/' + this.project.slug + '/contacts', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
            client: this.getClient.slug
          }
        }).then(function(response) {

          self.contactLoading = false;
          self.contactTableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      // loadCategories(query) {
      //   let self = this;
      //   piincHttp.get('/projects/contacts/categories', { params:
      //     {
      //       client: this.getClient.slug,
      //       keyword: query ? query : undefined
      //     }
      //   }).then(function(response) {
      //     self.categoryItems = response.data
      //     if (self.categoryItems && !self.categoryItems.length && !query) {
      //       self.categoryItems.push({category: self.$t('system.no_results'), $isDisabled: true})
      //     }
      //   }, function() {});
      // },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadContacts(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadContacts(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContacts(1, self.selectedPerPage)

      },
    },
    created () {
      this.project = this.object
      // if (this.getClient && this.getClient.slug) {
      //   this.loadCategories();
      // }

      if (this.project && this.project.id) {
        // Table items
        this.loadContacts();
      }

      this.loadGuides('update_contacts')
    }
  }
</script>
