<template>
  <span>
    <b-button
          v-if="buttonType == 'small'"
          variant="edit"
          qid="users-create-button"
          @click="showInviteModal()"
          :disabled="buttonDisabled"
    >
      <span v-if="!buttonDisabled" class="mr-1">
        <font-awesome-icon icon="plus-circle"/>
      </span>
      <span v-if="buttonDisabled" class="mr-1">
        <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
      </span>

    </b-button>

    <b-button
          v-else
          variant="success"
          class="float-right"
          qid="users-create-button"
          @click="showInviteModal()"
          :disabled="buttonDisabled"
    >
      <span v-if="!buttonDisabled" class="mr-1">
        <font-awesome-icon icon="plus-circle"/>{{ $t('maintenance.users.invite_button') }}
      </span>
      <span v-if="buttonDisabled" class="mr-1">
        <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>{{ $t('maintenance.users.invite_button') }}
      </span>
    </b-button>


    <!-- Invite user 1 modal template -->
    <b-modal ref="invite-user-1"
             @hide="hideInviteModal"
             modal-class="task-modal"
             hide-footer
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideInviteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('maintenance.users.invite_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
        <span v-if="showGetMore" class="text-success cursor-pointer" @click="goToBilling()">
          {{ $t('administration.users.create.get_more_label') }}
        </span>
      </b-alert>

      <b-form @submit="checkUserFormSubmit" qid="edit-user-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-user-email-label">
              {{ $t('administration.users.create.email_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
              qid="create-user-form-email"
              type="email"
              v-model="userForm.email"
              required
              :placeholder="$t('administration.users.create.email_label')"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label qid="create-user-role-label">
              {{ $t('administration.users.create.role_label') }}<span class="mandatory"> *</span>
            </label>
            <multiselect
                v-model="selectedRole"
                :options="roles"
                track-by="id"
                :allow-empty="false"
                :placeholder="$t('administration.clients.members.select_role_placeholder')"
                label="name_translated"
                required
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="resetRole"
                qid="create-user-form-role"
            ></multiselect>
          </b-form-group>
          <b-alert v-if="selectedRole && (selectedRole.name === 'Client admin' || selectedRole.name === 'Partition admin')"
                   show
                   variant="warning"
                   qid="create-user-error"
          >
             {{ $t('administration.users.create.role_cost_notice') }}
          </b-alert>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideInviteModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('system.invite') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Invite user exist modal template -->
    <b-modal ref="invite-user-exist"
             @hide="hideInviteModal"
             modal-class="task-modal"
             hide-footer
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideInviteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('maintenance.users.invite_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
        <span v-if="showGetMore" class="text-success cursor-pointer" @click="goToBilling()">
          {{ $t('administration.users.create.get_more_label') }}
        </span>
      </b-alert>

      <b-form @submit="formSubmit" qid="edit-user-form">
        <div class="form-container">
          <div class="mb-3">
            <label qid="edit-user-phone-label">
              {{ $t('administration.users.create.organization_label') }}
            </label>
            <OrganizationTreeView
                @updatedCompany="updateSelectedCompany"
                :organization="selectedOrganization"
            ></OrganizationTreeView>
          </div>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideInviteModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('system.invite') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Invite user not exist modal template -->
    <b-modal ref="invite-user-not-exist"
             @hide="hideInviteModal"
             modal-class="task-modal"
             hide-footer
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideInviteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('maintenance.users.invite_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
        <span v-if="showGetMore" class="text-success cursor-pointer" @click="goToBilling()">
          {{ $t('administration.users.create.get_more_label') }}
        </span>
      </b-alert>

      <b-form @submit="formSubmit" qid="edit-user-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-user-name-label">
              {{ $t('administration.users.create.name_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
              qid="create-user-form-name"
              type="text"
              v-model="userForm.name"
              required
              :maxLength="255"
              :placeholder="$t('administration.users.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-user-phone-label">
              {{ $t('administration.users.create.phone_label') }}
            </label>
            <b-form-input
              type="text"
              qid="create-user-form-phone"
              v-model="userForm.phone"
              :placeholder="$t('administration.users.create.phone_label')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-user-authentication-label">
              {{ $t('administration.users.create.authentication_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-radio-group
              v-model="userForm.authentication"
              required
              :options="radioOptions"
              qid="create-user-form-authentication"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group v-if="userForm.authentication == 'Microsoft' || userForm.authentication == 'Application'">
            <label qid="create-user-upn-email-label">
              {{ $t('administration.users.create.upn_email_label') }}
            </label>
            <b-form-input
              qid="create-user-form-upn-email"
              type="email"
              v-model="userForm.upn_email"
              :placeholder="userForm.email"
            ></b-form-input>
          </b-form-group>
          <div class="mb-3">
            <label qid="edit-user-phone-label">
              {{ $t('administration.users.create.organization_label') }}
            </label>
            <OrganizationTreeView
                @updatedCompany="updateSelectedCompany"
                :organization="selectedOrganization"
            ></OrganizationTreeView>
          </div>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideInviteModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('system.invite') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </span>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import {config} from '@/config.js';
  import OrganizationTreeView from '@/components/organizations/OrganizationTreeView.vue';
  // import GuideButton from '@/components/guide/GuideButton.vue';
  // import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'UserInviteModal',
    data: function() {
      return {
        userForm: {
          slug: '',
          name: '',
          phone: '',
          client: '',
          organization: '',
          role: '',
          authentication: '',
          upn_email: '',
          dpo:'No'
        },

        error: '',
        roles: [],
        selectedRole: [],
        loading: true,
        selectedOrganization: {},
        radioOptions: [
          {text: this.$t(this.$options.filters.slugify('Microsoft')), value: 'Microsoft'},
          {text: this.$t(this.$options.filters.slugify('Google')), value: 'Google'},
          {text: this.$t(this.$options.filters.slugify('Application')), value: 'Application'}
        ],
        organizationItems: [],
        showGetMore: false
      }
    },
    props: {
      buttonType: String,
      buttonDisabled: Boolean,
    },
    components: {
      OrganizationTreeView,
      // GuideButton,
      // Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client;
      },
      getClientRoles() {
        return this.$store.state.client.clientRoles;
      },
      isEmailValid() {
        if (this.name) {
          return this.isValid(this.name);
        }
        return null;
      }
    },
    methods: {
      checkUserFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        piincHttp.get('users/email/check', { params:
          {
            email: this.userForm.email,
            client: this.getClient.id
          }
        }).then(function(response) {
          self.loading = false;

          if (response.data == 1) {
            self.buttonDisabled = false;
            if (self.organizationItems && self.organizationItems.length) {
              self.error = '';
              self.$refs['invite-user-exist'].show();
            } else {
              self.formSubmit();
            }
          } else {
            self.error = '';
            self.buttonDisabled = false;
            self.$refs['invite-user-not-exist'].show();
          }
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.email) {
              self.error = self.error + '\n\n' + error.data.errors.email[0];
            }
          }
        })
      },
      showInviteModal(object) {
        let self = this;
        this.error = '';
        this.buttonDisabled = false;
        this.userForm.slug = '';
        this.userForm.name = '';
        this.userForm.email = '';
        this.userForm.upn_email = '';
        this.userForm.phone = '';
        this.selectedOrganization = '';
        this.userForm.dpo = '';
        this.userForm.authentication = 'Microsoft';
        this.setRoles();
        this.resetRole();
        _.forEach(this.getClientRoles, function(role) {
          if (role.name == 'Member'){
            self.selectedRole = role;
          }
        })
        this.loadOrganizations()
        this.$refs['invite-user-1'].show()
      },
      hideInviteModal() {
        this.$refs['invite-user-1'].hide();
        this.$refs['invite-user-not-exist'].hide();
        this.$refs['invite-user-exist'].hide();
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        if (evt) {
          evt.preventDefault();
        }
        this.userForm.client = this.getClient.slug;
        this.userForm.role = this.selectedRole.id;
        if (this.userForm.authentication != 'Microsoft' && this.userForm.authentication != 'Application') {
          this.userForm.upn_email = '';
        }
        piincHttp.post('users', this.userForm).then(function(response) {
          self.$toastr('success', self.$t('administration.users.create.success'));
          self.buttonDisabled = false;
          self.$refs['invite-user-1'].hide();
          self.$refs['invite-user-not-exist'].hide();
          self.$refs['invite-user-exist'].hide();
          self.$emit('reloadUserList');
        }, function(error) {
          if (error.status !== 200) {
            self.showGetMore = false;
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.message == 'You are not allowed to add member. Please check subscription.') {
              self.error = self.$t('administration.users.create.subscription_error_member');
              if (!self.getClient.parent) {
                self.showGetMore = true;
              }
            }
            if (error.data.message == 'You are not allowed to add admin. Please check subscription.') {
              if (!self.getClient.parent) {
                self.showGetMore = true;
                self.error = self.$t('administration.users.create.subscription_error_admin');
              } else {
                self.error = self.$t('administration.users.create.subscription_error_partition_admin');
              }
            }
            if (error.data.message == 'You are not allowed to add validator. Please check subscription.') {
              self.error = self.$t('administration.users.create.subscription_error_validator');
              if (!self.getClient.parent) {
                self.showGetMore = true;
              }
            }
            if (error.data.errors.email) {
              self.error = self.error + '\n\n' + error.data.errors.email[0];
            }
          }
        })
      },
      setRoles() {
        let self = this;
        this.roles = [];

        if (this.getClient) {
          if (this.getClient.user_roles[0].name == "Client admin" || this.getClient.user_roles[0].name == "Partition admin") {
            if (this.getClient.parent) {
              _.forEach(this.getClientRoles, function(role) {
                if (role.name != 'Client admin'){
                  self.roles.push(role);
                }
              })
            } else {
              _.forEach(this.getClientRoles, function(role) {
                if (role.name != 'Partition admin'){
                  self.roles.push(role);
                }
              })
            }
          }

          if (this.getClient.user_roles[0].name == "Validator") {
            _.forEach(this.getClientRoles, function(role) {
              if (role.name == 'Validator' && role.name == 'Member'){
                self.roles.push(role);
              }
            })
          }

          if (this.getClient.user_roles[0].name == "Member") {
            _.forEach(this.getClientRoles, function(role) {
              if (role.name == 'Member'){
                self.roles.push(role);
              }
            })
          }
        }

        _.forEach(this.roles, function(role) {
          role.name_translated = self.$t('maintenance.users.role.' + self.$options.filters.slugify(role.name));
        })
      },
      resetRole() {
        this.selectedRole = [];
      },
      goToBilling() {
        window.location.href = '/client/' + this.getClient.slug + '?tabindex=1';
      },
      loadOrganizations() {
        let self = this;
        piincHttp.get('organizations', { params:
          {
            per_page: 1,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.loading = false;
          self.organizationItems = response.data.items;
        }, function() {});
      },
      updateSelectedCompany(item) {
        this.userForm.organization = item.id;
      },
    },
    created() {
    }
  }
</script>
