<template>
  <div>
    <div class="" qid="basic-info-section">
      <div>
        <div class="card mb-2" qid="basic-info-section" v-for="(object, index) in open_tasks" v-if="open_tasks && open_tasks.length && !loadingTasks">
          <div class="card-body">
            <div class="content-view-body">
              <div>
                <h5 class="h5">
                  {{ object.full_title }}
                </h5>
                <hr>

                <label for="" qid="create-p-users-label" v-if="object.response">
                  {{ $t('tasks.modal.response_label') }}
                </label>
                <div v-if="object.response" class="mb-4">
                  <span v-if="object.response_type == 'Datepicker'">
                    <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                    {{ object.response | formatDate }}
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="check-circle" class="icon-success" v-if="getIcon(object) == 'Positive'"/>
                    <font-awesome-icon icon="times-circle" class="icon-danger" v-if="getIcon(object) == 'Negative'"/>
                    <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(object) == 'Neutral'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="getIcon(object) == 'Very low'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="getIcon(object) == 'Low'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="getIcon(object) == 'High'"/>
                    <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="getIcon(object) == 'Very high'"/>
                    {{ object.response }}
                  </span>
                </div>

                <label for="" v-if="object.control_description && object.control_description.length">
                  {{ $t('tasks.modal.control_description_label') }}
                </label>
                <div v-if="object.control_description && object.control_description.length">
                  <div v-if="object.control_description && object.control_description.length > 300">
                    <p v-if="showFullTextControlDescription" class="text-formatted">{{ object.control_description }}</p>

                    <p v-if="!showFullTextControlDescription" class="text-formatted">{{ object.control_description | truncate(300) }}</p>

                    <div class="text-center mt-3" @click="showFullTextControlDescription=!showFullTextControlDescription">
                      <span class="cursor-pointer" v-if="!showFullTextControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="object.control_description && object.control_description.length <= 300"
                     class="text-formatted"
                  >{{ object.control_description }}</p>
                </div>
                <br>


                <label for="" v-if="object.performed_text && object.performed_text.length">
                  {{ $t('tasks.modal.performed_test_label') }}
                </label>
                <div v-if="object.performed_text && object.performed_text.length">
                  <div v-if="object.performed_text && object.performed_text.length > 300">
                    <p v-if="showFullTextPerformedTest" class="text-formatted">{{ object.performed_text }}</p>

                    <p v-if="!showFullTextPerformedTest" class="text-formatted">{{ object.performed_text | truncate(300) }}</p>

                    <div class="text-center mt-3" @click="showFullTextPerformedTest=!showFullTextPerformedTest">
                      <span class="cursor-pointer" v-if="!showFullTextPerformedTest">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextPerformedTest">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="object.performed_text && object.performed_text.length <= 300"
                     class="text-formatted"
                  >{{ object.performed_text }}</p>
                </div>
                <br>

                <label for="" v-if="object.remarks && object.remarks.length">
                  {{ $t('tasks.modal.remarks_label') }}
                </label>
                <div v-if="object.remarks && object.remarks.length">
                  <div v-if="object.remarks && object.remarks.length > 300">
                    <p v-if="showFullTextConclusion" class="text-formatted">{{ object.remarks }}</p>

                    <p v-if="!showFullTextConclusion" class="text-formatted">{{ object.remarks | truncate(300) }}</p>

                    <div class="text-center mt-3" @click="showFullTextConclusion=!showFullTextConclusion">
                      <span class="cursor-pointer" v-if="!showFullTextConclusion">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullTextConclusion">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>

                  <p v-if="object.remarks && object.remarks.length <= 300"
                     class="text-formatted"
                  >{{ object.remarks }}
                  </p>
                </div>
                <br>

                <div class="row">
                  <div class="col-sm-9">
                    <label for="">
                      Linked framework controls
                    </label>
                    <div class="">
                      <span v-for="item in getFrameworkControls(object)" v-if="getFrameworkControls(object).length">
                        <font-awesome-icon icon="book"/>
                        {{ item.name }}
                      </span>
                      <span v-if="!getFrameworkControls(object).length" class="text-muted">
                        No linked framework controls
                      </span>
                    </div>

                  </div>
                  <div class="col-sm-3">
                    <div class="">
                      <span @click="showAddFrameworkControlModal(object)" class="btn btn-secondary cursor-pointer float-right">
                        Link framework controls
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loadingTasks && !open_tasks.length">
          <div class="card measure-side-header">
            <div class="card-body" style="padding-left:12px;">
              <h5 class="mb-0">
                {{ $t('system.no_results') }}
              </h5>
            </div>
          </div>
        </div>

        <div class="mt-5" v-if="loadingTasks">
          <div class="swing-container">
            <div class="swing">
              <div class="swing-l"></div>
              <div></div>
              <div></div>
              <div></div>
              <div class="swing-r"></div>
            </div>
          </div>
        </div>


      </div>

    </div>


    <!-- Add new framework control -->
    <b-modal ref="add-framework-control" hide-footer @hide="hideAddFrameworkControlModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.framework_controls.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="frameworkControlFormSubmit" qid="create-framework-control-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_label') }}
            </label>
            <multiselect
                v-model="selectedFramework"
                :options="frameworkItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.framework_controls.create.framework_placeholder')"
                label="name"
                @search-change="loadFrameworkItems"
                @select="handleFrameworkChange"
                track-by="slug"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group v-if="selectedFramework && selectedFramework.id">
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_control_label') }}
            </label>
            <multiselect
                v-model="selectedFrameworkControls"
                :options="frameworkControlItems"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('generic_controls.framework_controls.create.framework_control_placeholder')"
                label="full_name"
                @search-change="loadFrameworkControlItems"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <div class="text-muted mt-2 mb-3">
            {{ $t('generic_controls.framework_controls.create.framework_control_tip') }}
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideAddFrameworkControlModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.framework_controls.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.framework_controls.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Re-assign modal -->
    <b-modal ref="re-assign" hide-footer @hide="hideEditUsersModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('tasks.re_assign.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="reassignFormSubmit" qid="create-p-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('tasks.re_assign.users_label') }}
            </label>
            <multiselect
                v-model="selectedAssignUsers"
                :options="allUsers"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('tasks.re_assign.users_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group v-if="itemObject.data_collection_on">
            <label qid="create-p-users-label">
              {{ $t('tasks.re_assign.data_collection_users_label') }}
            </label>
            <multiselect
                v-model="selectedDataAssignUsers"
                :options="allUsers"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('tasks.re_assign.users_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditUsersModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Add from template modal -->
    <b-modal ref="add-task" hide-footer modal-class="task-modal" @hide="hideAddTaskModal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideAddTaskModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-p-title">
            {{ $t('tasks.add_task.modal_title') }}
          </h5>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="addTaskFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('tasks.add_task.select_question_label') }}
            </label>
            <multiselect
                v-model="selectedQuestion"
                :options="questions"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('tasks.add_task.select_question_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
            <template slot="singleLabel" slot-scope="props">
              <span v-if="props.option.reference">
                <span>{{ props.option.reference }}: </span>
              </span>
              {{ props.option.name }}
            </template>
            <template slot="option" slot-scope="props">
              <span v-if="props.option.reference">
                <span>{{ props.option.reference }}: </span>
              </span>
              {{ props.option.name }}
            </template>

            </multiselect>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-2"
                          variant="secondary"
                          @click="hideAddTaskModal"
                          qid="create-p-cancel-button"
                >
                  {{ $t('projects.create.cancel') }}
                </b-button>
                <b-button type="submit"
                          class="mt-2 ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-p-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('projects.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

      </b-form>
    </b-modal>


    <!-- Delete task modal template -->
    <b-modal ref="delete-task" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.full_title }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <TaskViewModal
        :selectedTask="selectedTask"
        :showModal="viewTaskModal"
        @closeViewModal="hideViewTaskModal"
    ></TaskViewModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import AssessmentSingleTaskModal from '@/views/Tasks/AssessmentSingleTaskModal.vue';
  import AssessmentGlobalSingleTaskModal from '@/views/Tasks/AssessmentGlobalSingleTaskModal.vue';
  import AssessmentGlobalMeasureSingleTaskModal from '@/views/Tasks/AssessmentGlobalMeasureSingleTaskModal.vue';
  import RiskSingleTaskModal from '@/views/Tasks/RiskSingleTaskModal.vue';
  import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';
  import ValidationSingleTaskModal from '@/views/Tasks/ValidationSingleTaskModal.vue';
  import TaskViewModal from '@/views/Tasks/TaskViewModal.vue';
  import DownloadAllAttachmentsModal from '@/views/Projects/DownloadAllAttachmentsModal.vue';
  import AssessmentTaskSideFilter from '@/components/tasks/AssessmentTaskSideFilter.vue';
  import AssessmentTaskTopBar from '@/components/tasks/AssessmentTaskTopBar.vue';

  export default {
    name: 'TasksTable',
    data: function() {
      return {
        sForm: {
          items: []
        },
        form: {
          due_date: '',
          start_date: '',
          message: ''
        },
        eform: {
          due_date: '',
          start_date: '',
          message: ''
        },
        keywordValue: '',
        filters: {
          category: '',
          keywordValue: ''
        },
        activity: {},
        sTableItems: [],
        selectedS: [],
        sLoading: true,
        error: '',
        itemToDelete: {},
        itemObject: {},
        buttonDisabled: false,
        open_tasks: {},
        loadingTasks: false,
        closed_tasks: {},
        showStatusForm: false,
        selectedStatus: [],

        SideFilterRenderComponent: true,
        selectedCategory: {},

        options: [
          { name: this.$t('projects.status.pending'), value: 'pending' },
          { name: this.$t('projects.status.in_progress'), value: 'in progress' },
          { name: this.$t('projects.status.completed'), value: 'completed' },
          { name: this.$t('projects.status.closed'), value: 'closed' }
        ],
        selectedTask: [],
        selectedAssignUsers: [],
        selectedDataAssignUsers: [],
        selectedItem: {},
        showUsersModal: false,
        viewTaskModal: false,
        allUsers: [],
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],

        questions: [],
        selectedQuestion: [],

        showFullTextNotes: false,
        showFullTextControlDescription: false,
        showFullTextPerformedTest: false,
        showFullTextConclusion: false,
        showFullTextControlDescriptionDocumentationCollection: false,

        selectedFrameworkControls: [],
        selectedFramework: [],
        selectedFrameworkControl: [],
        frameworkControlItems: [],
        frameworkItems: [],

        // Tasks pagination
        tasksPerPage: '',
        tasksCurrentPage: 1,
        tasksSelectedPerPage: "10",
        tasksTotalRows: 0,
        updateChildList: false,
        editUserType: '',
        loadFirstTime: true
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      Datepicker,
      ShowMoreModal,
      RiskSingleTaskModal,
      AssessmentSingleTaskModal,
      AssessmentGlobalSingleTaskModal,
      AssessmentGlobalMeasureSingleTaskModal,
      TaskViewModal,
      DownloadAllAttachmentsModal,
      UpdateSingleTaskModal,
      AssessmentTaskTopBar,
      AssessmentTaskSideFilter,
      ValidationSingleTaskModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      getUser() {
        return this.$store.state.user
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getTasksPerPage() {
        let page = this.tasksCurrentPage;
        let selectedFrom = (page-1) * Number(this.tasksSelectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.tasksSelectedPerPage) + Number(this.tasksSelectedPerPage);
        let selectedOf = this.tasksTotalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      tasksRows() {
        // Use computed value for rows because of dynamic update
        return this.tasksTotalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadTasks()
      }, 400),
      reloadTasks() {
        this.loadTasks(this.tasksCurrentPage)
      },
      tasksPageChanged(page) {
        this.loadTasks(page)
      },
      tasksPageChanged(page) {
        this.loadTasks(page)
      },
      getIcon(object) {
        let foundObject = {}
        let responseOptions = JSON.parse(object.response_type_options)

        foundObject = _.find(responseOptions, function(item) {
          return item.title === object.response;
        });

        if (foundObject) {
          return foundObject.value.value
        } else {
          return ''
        }

      },
      getFrameworkControls(object) {
        let foundItems = []
        let self = this;

        _.forEach(object.framework_controls, function (item) {
          if (item.client == self.getClient.slug) {
            foundItems.push(item)
          }
        })

        if (foundItems && foundItems.length) {
          return foundItems
        } else {
          return []
        }

      },
      showAddFrameworkControlModal(object) {
        this.selectedTask = object
        this.assessmentControlOpen = true
        this.selectedFramework = [];
        this.error = '';
        this.selectedFrameworkControls = [];
        this.loadFrameworkItems();
        this.$refs['add-framework-control'].show()
      },
      hideAddFrameworkControlModal() {
        this.selectedTask = []
        this.assessmentControlOpen = false
        this.error = ''
        this.selectedFramework = [];
        this.selectedFrameworkControls = []
        this.$refs['add-framework-control'].hide()
      },
      handleFrameworkChange(item) {
        this.selectedFramework = item
        this.loadFrameworkControlItems()
      },
      loadFrameworkItems(query) {
        let self = this;
        piincHttp.get('frameworks', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.frameworkItems = response.data.items
          if (self.frameworkItems && !self.frameworkItems.length && !query) {
            self.frameworkItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadFrameworkControlItems(query) {
        let self = this;
        piincHttp.get('framework-controls', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            framework: this.selectedFramework.id,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.frameworkControlItems = response.data.items
          if (self.frameworkControlItems && !self.frameworkControlItems.length && !query) {
            self.frameworkControlItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      frameworkControlFormSubmit(evt) {
        let self = this
        evt.preventDefault();

        let form = {};

        if (this.selectedFrameworkControls && this.selectedFrameworkControls.length) {
          form.framework_controls = []
          for (var i = 0; i < this.selectedFrameworkControls.length; i++) {
            form.framework_controls.push(this.selectedFrameworkControls[i].id);
          }
        }

        if (this.selectedFrameworkControls && this.selectedFrameworkControls.length) {
          piincHttp.put('tasks/' + this.selectedTask.id, form).then(function(response) {
            self.$toastr('success', self.$t('tasks.update.framework_controls_success'))
            self.hideAddFrameworkControlModal();
            self.loadTasks();
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (!this.selectedFrameworkControls.length) {
            this.error += ' ' + this.$t('tasks.update.framework_controls_error')
          }
        }

        this.hideAddFrameworkControlModal()


      },
      updateSelectedFilter(item) {
        if (!item) {
          this.filters.category = {}
          this.selectedFilterName = ''
        }else{
          this.filters.category = item
          this.selectedFilterName = item.name
        }

        this.selectedCategory = this.filters.category;
        this.loadingTasks = true;
        this.open_tasks = []
        this.debounceInput()
      },
      navigateToContent(object, type) {
        if (type == 'model') {
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.model.slug, }})
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.model.slug, }})
          }
        } else {
          if (object.scope_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.scope.slug, }})
          }
          if (object.scope_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.scope.slug, }})
          }
        }
      },
      tasksPerPageChanged(perPage) {
        let self = this;
        self.tasksSelectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.tasksSelectedPerPage);
        // Load content when page changed listener is triggered
        this.loadTasks(1, perPage.value)
      },
      tasksPerPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.tasksSelectedPerPage = self.perPageOptions[0].value
        this.loadTasks(1, self.tasksSelectedPerPage)
      },
      showViewTaskModal(object) {
        this.selectedTask = object
        this.viewTaskModal = true
      },
      hideViewTaskModal() {
        this.selectedTask = {}
        this.viewTaskModal = false
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.updateChildList = false;
        this.$refs['delete-task'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.updateChildList = true;
        this.$refs['delete-task'].hide()
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      resetFields() {
        this.selectedRC = null
      },
      cancelForm() {
        this.showStatusForm = false
      },
      hideAddTaskModal() {
        this.selectedQuestion = []
        this.updateChildList = true;
        this.$refs['add-task'].hide()
      },
      showAddTaskModal() {
        this.error = '';
        this.loadQuestions()
        this.updateChildList = false;
        this.$refs['add-task'].show()
      },
      addTaskFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        evt.preventDefault();

        if (this.selectedQuestion && this.selectedQuestion.id) {
          piincHttp.post('projects/' + this.object.slug + '/tasks', {
            question_id: this.selectedQuestion.id
          }).then(function(response) {
            self.hideAddTaskModal();
            self.loadTasks()
            self.$toastr('success', self.$t('tasks.add_task_success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          self.error = self.$t('tasks.add_task.task_required')
          self.buttonDisabled = false;
        }
      },
      forceSideFilterRenderComponent(object) {
        // store selected category provided from the top bar component
        this.selectedCategory = object
      },
      hideEditUsersModal() {
        this.selectedTask = {}
        this.itemObject = {}
        this.editUserType = ''
        this.$refs['re-assign'].hide()
      },
      showEditUsersModal(object) {
        let self = this;
        this.error = '';

        this.itemObject = object;

        let role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        role_name.push(self.permissions.ROLE_MEMBER);
        role_name.push(self.permissions.ROLE_VALIDATOR);

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        this.selectedAssignUsers = [];
        this.selectedDataAssignUsers = [];

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            role_name: role_name,
            per_page: 100
          }
        }).then(function(response) {
          let users = response.data.items

          _.forEach(object.users, function (user) {
            if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
              if (user.id != self.getUser.id) {
                users.push(user)
              }
            }

            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedAssignUsers.push(matchedUser)
          })
          _.forEach(object.data_users, function (user) {
            if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
              if (user.id != self.getUser.id) {
                users.push(user)
              }
            }

            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedDataAssignUsers.push(matchedUser)
          })

          self.allUsers = _.uniq(users)
        }, function() {});

        this.selectedTask = object
        this.$refs['re-assign'].show()
      },
      reassignFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        let datauserIds = [];
        evt.preventDefault();

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }
        if (this.selectedDataAssignUsers && this.selectedDataAssignUsers.length) {
          for (var i = 0; i < this.selectedDataAssignUsers.length; i++) {
            datauserIds.push(this.selectedDataAssignUsers[i].id);
          }
        }

        // if (userIds && userIds.length && datauserIds && datauserIds.length) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            users: userIds,
            data_users: datauserIds,
          }).then(function(response) {
            self.hideEditUsersModal();
            self.loadTasks()
            self.$toastr('success', self.$t('tasks.re_assign.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        // } else {
        //   self.error = self.$t('tasks.re_assign.required')
        //   self.buttonDisabled = false;
        // }
      },
      showFormFunction() {
        let self = this
        this.selectedStatus = []
        if (this.selectedS.status) {
          this.selectedStatus = _.find(this.options, function(item) {
            return item.value === self.selectedS.status;
          });
        }
        this.showStatusForm = true
      },
      formStatusSubmit() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.processing_role = this.processingRole ? this.processingRole.value : '';

        piincHttp.put('projects/' + this.selectedS.slug, {
          status: this.selectedStatus.value
        }).then(function(response) {
          self.selectedS = response.data
          self.cancelForm();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      sendReminders(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + object.slug + '/reminders').then(function(response) {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      openTasksTabClicked() {
        this.loadTasks()
        this.showClosedTasksTab = false
        this.showOpenTasksTab = true
      },
      closedTasksTabClicked() {
        this.loadClosedTasks()
        this.showClosedTasksTab = true;
        this.showOpenTasksTab = false
      },
      loadTasks(page, perPage) {
        let self = this;
        this.loadingTasks = true;

        if (this.getStorePerPage) {
          this.tasksSelectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.tasksSelectedPerPage;

        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            client: this.getClient.slug,
            page: pageNumber,
            not_code: 'update_measures',
            control_objective: this.selectedCategory && this.selectedCategory.id ? this.selectedCategory.id : undefined,
            per_page: perPageNumber,
          }
        }).then(function(response) {
          self.open_tasks = response.data.items;
          // Set number of total items
          self.tasksTotalRows = response.data.total_count;
          self.loadingTasks = false;
          self.loadFirstTime = false;
          self.$emit('updated');
        }, function() {});
      },
      loadClosedTasks() {
        let self = this;
        // Call API for closed tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            status: 'Completed',
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.closed_tasks = response.data.items;
        }, function() {});
      },
      downloadTasks() {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks/download',{responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Project tasks.xlsx');

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});


      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      showEditModal(object) {
        let self = this;
        this.error = '';

        this.selectedS = object
        this.loadTasks()
        this.loadClosedTasks()
        this.eform.due_date = this.object.end_date;
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      editFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.eform.due_date = this.eform.due_date ? moment(this.eform.due_date).format('YYYY-MM-DD') : '';

        piincHttp.put('projects/' + this.selectedS.slug, {end_date: this.eform.due_date}).then(function(response) {
          self.hideEditModal();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showEditSModal(object) {
        this.eform.due_date = object.end_date;
        this.$refs['edit-system'].show()
      },
      hideEditSModal() {
        this.$refs['edit-system'].hide()
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('tasks/' + object.id).then(function() {
          self.hideConfirmDeleteModal();
          self.loadTasks();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('tasks.delete.success'))
          self.$emit('updated');
        }, function() {});
      },
      showCreateModal() {
        let self = this;
        this.error = '';
        this.$refs['create-item'].show()
      },
      hideCreateModal() {
        this.selectedS = {}
        this.selectedUsers = []
        this.form.due_date = ''
        this.form.start_date = ''
        this.$refs['create-item'].hide()
      },
      createFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let formData = new FormData();

        this.form.due_date = this.form.due_date ? moment(this.form.due_date).format('YYYY-MM-DD') : '';
        formData.append("due_date", this.form.due_date);

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '';
        formData.append("start_date", this.form.start_date);

        formData.append("message", this.form.message);

        piincHttp.post('projects/' + this.$route.params.slug + '/projects', formData, {

        }).then(function(response) {
          self.hideCreateModal();
          self.loadProjects();
          // Emit event to parent component to update newest validation
          self.$toastr('success', self.$t('projects.create.project_success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.start_date) {
              self.error += ' ' + error.data.errors.start_date[0]
            }
            if (error.data.errors.due_date) {
              self.error += ' ' + error.data.errors.due_date[0]
            }
          }
        })
      },
      loadQuestions() {
        let self = this;
        piincHttp.get('questions/no-tasks', { params:
          {
            client: this.getClient.slug,
            assessment: this.activity.assessment ? this.activity.assessment.id : undefined,
            project: this.activity.id,
            per_page: 100
          }
        }).then(function(response) {
          self.questions = response.data.items;
        }, function() {});
      },
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadTasks();
    }
  }
</script>
