<template>
  <span>
    <b-button
        variant="edit"
        @click="showTaskModal(task)"
        qid="delete-s-cancel-button"
    >
      <span v-if="task">
        <font-awesome-icon icon="pen"/>
      </span>
      <span v-if="!task">
        {{ $t('tasks.open_first_task') }}
      </span>
    </b-button>

    <!-- Task modal -->
    <b-modal ref="task-modal" @hide="hideTaskModal" size="xl" modal-class="bottom-left task-modal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="minimizeTask" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="window-minimize"/>
          </span>
          <h5 class="mb-1 text-center" v-if="currentView != 'data_collection' && selectedTask && selectedTask.id && selectedTask.model && selectedTask.code != 'system_impact_assessment'">
            {{ $t('tasks.modal.assessment_control_task') }}
            {{ $t('system.for') }}
            {{ selectedTask.full_title }}
          </h5>
          <h5 class="mb-1 text-center" v-if="currentView == 'data_collection' && selectedTask && selectedTask.id && selectedTask.model && selectedTask.code != 'system_impact_assessment'">
            {{ $t('tasks.modal.data_collection_task') }}
            {{ $t('system.for') }}
            {{ selectedTask.full_title }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container mt-0 mb-2">
        <div class="row mt-1">

          <!-- Tables container -->
          <div class="col-sm-12">
            <MeasuresTable :object="selectedTask.project"></MeasuresTable>
          </div>

        </div>
      </div>


      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="task-modal-info" v-if="selectedTask && selectedTask.id">
            <div class="row">
              <div class="col text-center pt-3 pb-3" v-if="currentView != 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-if="selectedTask.users && selectedTask.users.length" v-for="user in getReducedUsers(selectedTask.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.users && selectedTask.users.length && selectedTask.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView == 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-if="selectedTask.data_users && selectedTask.data_users.length" v-for="user in getReducedUsers(selectedTask.data_users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.data_users && selectedTask.data_users.length && selectedTask.data_users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.data_users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.due_date') }}</strong>
                </div>
                <span class="text-normal">
                  <span v-if="getDifference(selectedTask.project.end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) <= 3 && getDifference(selectedTask.project.end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(selectedTask.project.end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView != 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('system.task_status') }}</strong>
                </div>
                <span v-if="selectedTask.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="selectedTask.status === 'pending'" class="pr-2">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6"
                  />
                </span>
                <span class="text-normal">
                  {{ $t('system.' + $options.filters.slugify(selectedTask.status)) }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3" v-if="currentView == 'data_collection'">
                <div class="mb-3">
                  <strong>{{ $t('tasks.table.data_collection_status') }}</strong>
                </div>
                <span v-if="selectedTask.data_status && selectedTask.data_status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="selectedTask.data_status && selectedTask.data_status === 'pending'" class="pr-2">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6"
                  />
                </span>
                <span v-if="selectedTask.data_status">
                  {{ $t('system.' + $options.filters.slugify(selectedTask.data_status)) }}
                </span>
                <span v-if="!selectedTask.data_status">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6"
                  />
                  {{ $t('system.pending') }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.project') }}</strong>
                </div>
                <span v-if="isOwner(selectedTask.project)" class="text-success breadcrumb-item cursor-pointer" @click="navigateToProject(selectedTask)">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
                <span v-if="!isOwner(selectedTask.project)" class="text-success breadcrumb-item" style="pointer-events:none;">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.owners_label') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-for="user in getReducedUsers(selectedTask.project.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.project.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask.project)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.project.users.length - 3 }}
                </span>
              </div>
            </div>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
                <span>
                  <vm-progress
                      type="circle"
                      :percentage="project_progress"
                      style="vertical-align: middle;"
                      :width="50"
                      :stroke-width="2"
                      stroke-color="#00c29e"
                  >
                    {{project_progress}}%
                  </vm-progress>
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                          v-if="selectedTask.status != 'completed' && selectedTask.code != 'system_impact_assessment' && currentView != 'data_collection'"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeDataTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                          v-if="selectedTask.data_status != 'completed' && selectedTask.code != 'system_impact_assessment' && currentView == 'data_collection'"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status != 'completed' && selectedTask.code == 'system_impact_assessment'"
                          @click="completeSystemRiskTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="secondary"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('system.save_without_complete') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

      </template>
    </b-modal>


    <!-- Re-open data collection task modal template -->
    <b-modal ref="reopen-task" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideReopenDataCollectionModal" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('tasks.modal.data_collection_reopen_button') }}
          </h5>
        </div>
      </template>

      <div class="form-container">
        <h5>
          {{ $t('tasks.modal.comment_label') }}
        </h5>
        <hr>
        <br>

        <b-alert v-if="reopenError.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ reopenError }}
        </b-alert>

        <b-form-group>
          <label qid="create-p-users-label">
            {{ $t('tasks.modal.recipient_label') }}
          </label>
          <multiselect
              v-model="selectedAssignUsers"
              :options="allUsers"
              :multiple="true"
              :taggable="false"
              track-by="slug"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('tasks.modal.recipient_label')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <span class="mr-3 user-tag">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-p-users-label">
            {{ $t('tasks.modal.question_to_recipient_label') }}
          </label>
          <b-form-textarea
            qid="create-s-form-description"
            v-model="comment"
            rows="6"
            max-rows="6"
            :placeholder="$t('tasks.modal.question_to_recipient_label')"
          ></b-form-textarea>
        </b-form-group>

        <br>
        <h5>
          {{ $t('tasks.modal.previous_comments_label') }}
        </h5>
        <hr>
        <br>
        <div v-if="previousComments && previousComments.length">
          <div v-for="comment in previousComments" class="mt-3">
            <p>
              <Avatar :object="comment.user" size="xs"></Avatar>
              <span class="ml-1">
                {{ comment.user.name }}
              </span>
              {{$t('system.on')}}
              <span class="text-normal">
                {{ comment.updated_at | formatDateTime }}
              </span>
            </p>
            <p>
              {{ comment.comment }}
            </p>
          </div>
        </div>
        <div v-if="!previousComments.length" class="text-center">
          <h5 class="mt-3">
            {{ $t('system.no_results') }}
          </h5>
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <b-button class=""
                        variant="secondary"
                        @click="hideReopenDataCollectionModal"
                        qid="edit-p-cancel-button"
              >
                {{ $t('maintenance.measures.create.cancel') }}
              </b-button>
              <b-button
                        class="ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        @click="reopenFormSubmit()"
                        qid="edit-p-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('maintenance.measure_categories.create.submit') }}
              </b-button>
            </div>
          </div>
        </div>

      </template>
    </b-modal>


    <ConfirmRemoveAttachment
        :object="selectedFile"
        :show="showDeleteAttachmentModal"
        type="file"
        @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
        :object="selectedLink"
        :show="showDeleteLinkModal"
        type="link"
        @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>

    <GuideViewModal
        :guide="selectedGuide"
        :showModal="viewGuideModal"
        @closeViewModal="closeViewGuideModal"
    ></GuideViewModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Datepicker from 'vuejs-datepicker';
  import {config} from '@/config.js';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import GuideViewModal from '@/components/guide/GuideViewModal.vue';
  import roleCheck from "@/util/ACL/roleCheck"
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import draggable from 'vuedraggable'
  import CreateObservationModal from '@/views/Projects/CreateObservationModal.vue';
  import MeasuresTable from '@/views/Projects/MeasuresTable.vue';


  export default {
    name: 'AssessmentGlobalMeasureSingleTaskModal',
    data: function() {
      return {
        taskForm: {
          remarks: '',
          data_remarks: '',
          control_description: '',
          performed_text: '',
          rto: '',
          rpo: ''
        },
        selectedTask: [],
        buttonDisabled: false,
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        inputLinkName: '',
        descriptionLength: config.descriptionLength,
        showFullProjectMessage: false,
        showFullTaskDescription: false,
        showFullDataCollectionDescription: false,
        showFullGenericControlDescription: false,
        error: '',
        taskList: [],
        selectedAnswer: [],
        selectedDate: '',
        selectedDataCollectionAnswer: [],
        allAnswers: [],
        impactItems: [
          { name: this.$t('impact.very_low'), value: 'Very low' },
          { name: this.$t('impact.low'), value: 'Low' },
          { name: this.$t('impact.high'), value: 'High' },
          { name: this.$t('impact.very_high'), value: 'Very high' }
        ],
        intervalOptions: [
          {text: this.$t('system.interval_seconds'), value: 'Seconds'},
          {text: this.$t('system.interval_minutes'), value: 'Minutes'},
          {text: this.$t('system.interval_hours'), value: 'Hours'},
          {text: this.$t('system.interval_days'), value: 'Days'},
          {text: this.$t('system.interval_months'), value: 'Months'},
          {text: this.$t('system.interval_years'), value: 'Years'},
        ],
        selectedConfidentialityImpact: [],
        selectedIntegrityImpact: [],
        selectedAvailabilityImpact: [],
        rtoInterval: [],
        rpoInterval: [],
        project_progress: 0,
        taskCompleted: false,
        welcomeGuide: {},
        selectedGuide: {},
        viewGuideModal: false,
        selectedItem: {},
        showUsersModal: false,
        currentView: '',
        files: [],
        data_collection_files: [],
        showLinksSection: false,
        showDataCollectionLinksSection: false,
        uploader: false,
        showDropActive: false,
        allUsers: [],
        comment: '',
        reopenError: '',
        selectedAssignUsers: [],
        previousComments: []
      }
    },
    props: {
      task: {}
    },
    components: {
      Datepicker,
      ConfirmRemoveAttachment,
      GuideViewModal,
      Avatar,
      ShowMoreModal,
      MeasuresTable,
      draggable,
      CreateObservationModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getUser() {
        return this.$store.state.user
      },
      getReportMedia() {
        let media = []

        media = _.filter(this.selectedTask.project.media, function(mediaItem) {
          return mediaItem.collection_name == 'documents' || mediaItem.collection_name == 'project';
        });

        return media
      },
      getReportLinks() {
        let links = []

        links = _.filter(this.selectedTask.project.links, function(link) {
          return link.collection == null;
        });

        return links
      }
    },
    watch: {
      getClient: function () {

      },
      includeScheduled: function () {
        this.loadCA()
      }
    },
    methods: {
      getMedia(type) {
        let media = []
        if (type == 'task') {
          media = _.filter(this.selectedTask.media, function(mediaItem) {
            return mediaItem.collection_name == 'documents';
          });
        }

        if (type == 'data_documents') {
          media = _.filter(this.selectedTask.media, function(mediaItem) {
            return mediaItem.collection_name == 'data_documents';
          });
        }

        if (type == 'question') {
          media = _.filter(this.selectedTask.media, function(mediaItem) {
            return mediaItem.collection_name == 'question';
          });
        }

        if (type == 'data_collection') {
          media = _.filter(this.selectedTask.media, function(mediaItem) {
            return mediaItem.collection_name == 'data_collection';
          });
        }
        return media
      },
      getDifference(endDate) {
        var moment = require('moment');
        let currentDate = moment()
        endDate = moment(endDate);
        let difference = endDate.diff(currentDate, 'days');
        return difference;
      },
      getLinks(type) {
        let links = []
        if (type == 'task') {
          links = _.filter(this.selectedTask.links, function(link) {
            return link.collection == 'task';
          });
        }

        if (type == 'question') {
          links = _.filter(this.selectedTask.links, function(link) {
            return link.collection == 'question' || link.collection == null;
          });
        }

        if (type == 'data_documents') {
          links = _.filter(this.selectedTask.links, function(link) {
            return link.collection == 'data_documents';
          });
        }

        if (type == 'data_collection') {
          links = _.filter(this.selectedTask.links, function(link) {
            return link.collection == 'data_collection';
          });
        }
        return links
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      showReopenDataCollectionModal(object) {
        let self = this;
        this.comment = ''
        this.reopenError = ''

        this.selectedAssignUsers = [];

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            per_page: 100
          }
        }).then(function(response) {
          self.allUsers = response.data.items
          _.forEach(self.selectedTask.data_users, function (user) {
            self.selectedAssignUsers.push(user)
          })
        }, function() {});

        this.loadComments()

        this.$refs['reopen-task'].show()
      },
      hideReopenDataCollectionModal() {
        this.selectedAssignUsers = []
        this.$refs['reopen-task'].hide()
      },
      loadComments() {
        let self = this;
        this.previousComments = []
        piincHttp.get('tasks/' + this.selectedTask.id + '/comments', { params:
          {
            client: this.getClient.id,
            per_page: 100,
            sort: 'created_at',
            order: 'desc'
          }
        }).then(function(response) {
          self.previousComments = response.data.items
        }, function() {});
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      switchCurrentView(type) {
        this.showLinksSection = false;
        this.showDataCollectionLinksSection = false;
        this.loadComments()
        this.currentView = type;
      },
      showTaskModal(object) {

        let self = this;
        this.error = '';

        if (object != null) {
          self.selectedTask = object
        }

        if (object == null && this.tasks && this.tasks.length) {
          self.selectedTask = this.tasks[0]
        }


        this.loadTaskById(this.selectedTask.id);

        // Checking the type of the users so the right view is displayed
        if (this.isOwner(this.selectedTask.project)) {
          // if the user is owner on the project od admin

          this.currentView = 'default'
        } else {
          if (this.isOwner(this.selectedTask)) {
            this.currentView = 'default'
          } else {
            if (this.isDataOwner(this.selectedTask)) {
              this.currentView = 'data_collection'
            }
          }
        }

        this.loadComments()

        this.$refs['task-modal'].show()
        this.loadTaskGuide()
      },
      hideTaskModal() {
        let self = this;
        this.error = '';

        setTimeout(function(){
          self.$emit('reloadContent');
          self.selectedTask = {}
        }, 500);

        if (this.taskCompleted) {
          self.$store.commit('storeReloadTasks');
        }

        this.$refs['task-modal'].hide()
      },
      navigateToProject(task) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', task);
        this.$router.push({ name: 'projects.view', params: { slug: task.project.slug }})
      },
      populateForm() {
        let self = this;

        self.taskForm.remarks = self.selectedTask.remarks
        self.taskForm.data_remarks = self.selectedTask.data_remarks
        self.taskForm.control_description = self.selectedTask.control_description
        self.taskForm.performed_text = self.selectedTask.performed_text

        self.allAnswers = JSON.parse(self.selectedTask.response_type_options)

        self.selectedAnswer = []
        if (self.selectedTask.response) {
          if (self.selectedTask.response_type != 'Datepicker') {
            self.selectedAnswer = _.find(self.allAnswers, function(item) {
              if (item.title === self.selectedTask.response){
                return self.selectedTask.response;
              }
            });
          } else {
            self.selectedDate = self.selectedTask.response;
          }
        }

        self.selectedDataCollectionAnswer = []
        if (self.selectedTask.data_response) {
          self.selectedDataCollectionAnswer = _.find(self.allAnswers, function(item) {
            if(item === self.selectedTask.data_response){
              return self.selectedTask.data_response;
            }
          });
        }

        this.selectedConfidentialityImpact = []
        if (self.selectedTask.confidentiality) {
          self.selectedConfidentialityImpact = _.find(self.impactItems, function(item) {
            if(item.value === self.selectedTask.confidentiality){
              return item
            }
          });
        }

        this.selectedIntegrityImpact = []
        if (self.selectedTask.integrity) {
          self.selectedIntegrityImpact = _.find(self.impactItems, function(item) {
            if(item.value === self.selectedTask.integrity){
              return item
            }
          });
        }

        this.selectedAvailabilityImpact = []
        if (self.selectedTask.availability) {
          self.selectedAvailabilityImpact = _.find(self.impactItems, function(item) {
            if(item.value === self.selectedTask.availability){
              return item
            }
          });
        }

        this.rtoInterval = []
        if (self.selectedTask.rto_type) {
          self.rtoInterval = _.find(self.intervalOptions, function(item) {
            if(item.value === self.selectedTask.rto_type){
              return item
            }
          });
        }

        this.rpoInterval = []
        if (self.selectedTask.rpo_type) {
          self.rpoInterval = _.find(self.intervalOptions, function(item) {
            if(item.value === self.selectedTask.rpo_type){
              return item
            }
          });
        }

        this.taskForm.rto = this.selectedTask.rto_number
        this.taskForm.rpo = this.selectedTask.rpo_number

      },
      showViewGuideModal(object) {
        this.selectedGuide = object
        this.viewGuideModal = true
      },
      closeViewGuideModal() {
        this.selectedGuide = ''
        this.viewGuideModal = false
      },
      openWelcomePage() {
        this.showViewGuideModal(this.welcomeGuide)
      },
      showDropActiveFunction(value) {
        this.showDropActive = value;
      },
      loadTaskGuide() {
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';
        let self = this;
        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Task',
            scope: 'assessment_task',
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.welcomeGuide = response.data.items[0]
          }
        }, function() {});
      },
      navigateToTask(object) {
        if (this.isOwner(object.model)) {
          this.$store.commit('storeTaskToolbarState', true);
          this.$store.commit('storeActiveTask', object);
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.model.slug }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.model.slug }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.model.slug, }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.model.slug, }, query: { task: object.id }})
          }
        }
      },
      isOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = _.find(object.users, function(user) {
          return user.id == self.getUser.id
        });

        if (existingUser) {
          allow = true
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || roleCheck('Validator') || this.getUser.isAdmin) {
            allow = true
          }
        }

        return allow
      },
      isDataOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = _.find(object.data_users, function(user) {
          return user.id == self.getUser.id
        });

        if (existingUser) {
          allow = true
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || roleCheck('Validator') || this.getUser.isAdmin) {
            allow = true
          }
        }

        return allow
      },
      minimizeTask() {
        this.saveTask();
        var element = document.getElementsByClassName('modal');
        element[0].classList.add("bottom-left");
        // this.submitForm = true
        this.$store.commit('storeActiveTask', this.selectedTask);
        this.$store.commit('storeTaskToolbarState', true);
      },
      reopenFormSubmit() {
        this.buttonDisabled = true;
        let self = this;

        let userIds = [];

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }

        if (
          this.comment != '' &&
          this.selectedAssignUsers && this.selectedAssignUsers.length
        ) {
          piincHttp.put('tasks/' + self.selectedTask.id, {
            data_status: 'pending',
            data_users: userIds
          }).then(function(response) {
            self.buttonDisabled = false;

            let formData = new FormData();

            formData.append("comment", self.comment);

            piincHttp.post('tasks/' + self.selectedTask.id + '/comments', formData).then(function() {
              self.loadTaskById(self.selectedTask.id, true);
              self.hideReopenDataCollectionModal();
              self.showInput = false
            }, function() {})

          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.reopenError = '';
          self.buttonDisabled = false;
          if (this.selectedAssignUsers && !this.selectedAssignUsers.length) {
            this.reopenError += ' ' + this.$t('tasks.modal.recipient_error_message')
          }
          if (!this.comment) {
            this.reopenError += ' ' + this.$t('tasks.modal.comment_error_message')
          }
        }
      },
      completeTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        var moment = require('moment');
        let responseAnswer = '';

        if (
          ((this.selectedTask && this.selectedTask.add_attachments == 'Mandatory' && ((this.selectedTask.media && this.selectedTask.media.length) || (this.selectedTask.links && this.selectedTask.links.length))) ||
          (this.selectedTask && this.selectedTask.add_attachments != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_description == 'Mandatory' && this.taskForm.remarks) ||
          (this.selectedTask && this.selectedTask.add_description != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_control_description == 'Mandatory' && this.taskForm.control_description) ||
          (this.selectedTask && this.selectedTask.add_control_description != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_performed_text == 'Mandatory' && this.taskForm.performed_text) ||
          (this.selectedTask && this.selectedTask.add_performed_text != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.add_response == 'Mandatory' && ((this.selectedAnswer && this.selectedAnswer.title) || (this.selectedDate && this.selectedDate != ''))) ||
          (this.selectedTask && this.selectedTask.add_response != 'Mandatory'))
        ) {

          if (this.selectedTask.response_type != 'Datepicker') {
            responseAnswer = this.selectedAnswer && this.selectedAnswer.title ? this.selectedAnswer.title : undefined
          } else {
            responseAnswer = this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : undefined
          }

          piincHttp.put('tasks/' + this.selectedTask.id, {
            remarks: this.taskForm.remarks,
            control_description: this.taskForm.control_description,
            performed_text: this.taskForm.performed_text,
            response: responseAnswer,
            status: 'completed'
          }).then(function(response) {
            self.buttonDisabled = false;
            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1]
              } else {
                self.selectedTask = self.taskList[0]
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.taskCompleted = true;
              self.$store.commit('storeTaskToolbarState', false);
              self.hideTaskModal()
            }
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (this.selectedTask.add_control_description == 'Mandatory' && !this.taskForm.control_description) {
            this.error += ' ' + this.$t('tasks.modal.control_description_error_message')
          }
          if (this.selectedTask.add_performed_text == 'Mandatory' && !this.taskForm.performed_text) {
            this.error += ' ' + this.$t('tasks.modal.performed_test_error_message')
          }
          if (this.selectedTask.add_response == 'Mandatory' && (!this.selectedAnswer || (this.selectedAnswer && !this.selectedAnswer.title))) {
            this.error += ' ' + this.$t('tasks.modal.response_error_message')
          }
          if (this.selectedTask.add_description == 'Mandatory' && !this.taskForm.remarks) {
            this.error += ' ' + this.$t('tasks.modal.conclusion_error_message')
          }
          if ((this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.media && !this.selectedTask.media.length) && (this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.links && !this.selectedTask.links.length)) {
            this.error += ' ' + this.$t('tasks.modal.attachments_error_message')
          }

        }
      },
      completeDataTask() {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        if (
          ((this.selectedTask && this.selectedTask.data_collection_add_attachments == 'Mandatory' && ((this.getMedia('data_documents') && this.getMedia('data_documents').length) || (this.getLinks('data_documents') && this.getLinks('data_documents').length))) ||
          (this.selectedTask && this.selectedTask.data_collection_add_attachments != 'Mandatory')) &&
          ((this.selectedTask && this.selectedTask.data_collection_add_description == 'Mandatory' && this.taskForm.data_remarks) ||
          (this.selectedTask && this.selectedTask.data_collection_add_description != 'Mandatory'))
        ) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            data_remarks: this.taskForm.data_remarks,
            data_status: 'completed'
          }).then(function(response) {
            self.buttonDisabled = false;
            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1]
              } else {
                self.selectedTask = self.taskList[0]
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.$store.commit('storeTaskToolbarState', false);
              if (self.isOwner(self.selectedTask)) {
                self.currentView = 'default'
              } else {
                self.hideTaskModal()
              }
            }
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          if (this.selectedTask.data_collection_add_description == 'Mandatory' && !this.taskForm.data_remarks) {
            this.error += ' ' + this.$t('tasks.modal.comment_error_message')
          }
          if ((this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getMedia('data_documents') && !this.getMedia('data_documents').length) && (this.selectedTask.data_collection_add_attachments == 'Mandatory' && this.getLinks('data_documents') && !this.getLinks('data_documents').length)) {
            this.error += ' ' + this.$t('tasks.modal.attachments_error_message')
          }

        }
      },
      completeSystemRiskTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        if (
          this.taskForm.remarks != '' &&
          this.selectedConfidentialityImpact && this.selectedConfidentialityImpact.value &&
          this.selectedIntegrityImpact && this.selectedIntegrityImpact.value &&
          this.selectedAvailabilityImpact && this.selectedAvailabilityImpact.value &&
          this.taskForm.rto != '' &&
          this.taskForm.rpo != '' &&
          this.rtoInterval && this.rtoInterval.value &&
          this.rpoInterval && this.rpoInterval.value
        ) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            remarks: this.taskForm.remarks,
            confidentiality: this.selectedConfidentialityImpact ? this.selectedConfidentialityImpact.value : '',
            integrity: this.selectedIntegrityImpact ? this.selectedIntegrityImpact.value : '',
            availability: this.selectedAvailabilityImpact ? this.selectedAvailabilityImpact.value : '',
            rto_number: this.taskForm.rto,
            rto_type: this.rtoInterval ? this.rtoInterval.value : '',
            rpo_number: this.taskForm.rpo,
            rpo_type: this.rtoInterval ? this.rpoInterval.value : '',
            status: 'completed'
          }).then(function(response) {
            self.$toastr('success', self.$t('tasks.modal.success'))
            self.buttonDisabled = false;

            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1]
              } else {
                self.selectedTask = self.taskList[0]
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.$refs['task-modal'].hide()
            }
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = '';
          self.buttonDisabled = false;
          this.error = this.$t('tasks.modal.all_fields_error_message')
        }
      },
      saveTask() {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let responseAnswer = '';

        if (this.selectedTask.response_type != 'Datepicker') {
          responseAnswer = this.selectedAnswer && this.selectedAnswer.title ? this.selectedAnswer.title : ''
        } else {
          responseAnswer = this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : ''
        }

        piincHttp.put('tasks/' + this.selectedTask.id, {
          data_remarks: this.taskForm.data_remarks,
          remarks: this.taskForm.remarks,
          performed_text: this.taskForm.performed_text,
          control_description: this.taskForm.control_description,
          confidentiality: this.selectedConfidentialityImpact ? this.selectedConfidentialityImpact.value : '',
          integrity: this.selectedIntegrityImpact ? this.selectedIntegrityImpact.value : '',
          availability: this.selectedAvailabilityImpact ? this.selectedAvailabilityImpact.value : '',
          rto_number: this.taskForm.rto,
          rto_type: this.rtoInterval ? this.rtoInterval.value : '',
          rpo_number: this.taskForm.rpo,
          rpo_type: this.rtoInterval ? this.rpoInterval.value : '',
          response: responseAnswer,
          data_response: this.selectedDataCollectionAnswer && this.selectedDataCollectionAnswer.length ? this.selectedDataCollectionAnswer : '',
        }).then(function(response) {
          self.buttonDisabled = false;
          self.$emit('reloadContent', response.data);
          self.hideTaskModal()
          self.$toastr('success', self.$t('tasks.modal.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("collection", "task");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        piincHttp.post('links', formData).then(function() {
          self.inputLink = ''
          self.inputLinkName = ''
          self.loadTaskById(self.selectedTask.id, true);
          self.showInput = false
        }, function() {})
      },
      handleDataCollectionLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("collection", "data_documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        piincHttp.post('links', formData).then(function() {
          self.inputLink = ''
          self.inputLinkName = ''
          self.loadTaskById(self.selectedTask.id, true);
          self.showInput = false
        }, function() {})
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {
          responseType: 'blob',
          params: {
            task: this.selectedTask.id
          }
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      loadTaskById(id, ignorePopulate) {
        let self = this;
        piincHttp.get('tasks/' + id).then(function(response) {
          self.selectedTask = response.data;
          self.project_progress = self.selectedTask.project.user_progress.progress;
          if (!ignorePopulate) {
            self.populateForm()
          }
        }, function() {});
      },
      onFileChangeEdit(file) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.files[i].file);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.files = []
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.error = ''
            self.loadTaskById(self.selectedTask.id, true);
            self.files = []
          }, function(error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
      onDataFileChange(file) {
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        // Append list of attachments to FormData
        for (var i = 0; i < this.data_collection_files.length; i++) {
          // Equal to 10 MB
          if (this.data_collection_files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("document[]", this.data_collection_files[i].file);
        }

        formData.append("name", "documents");
        formData.append("collection", "data_documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.data_collection_files = []
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.error = ''
            self.loadTaskById(self.selectedTask.id, true);
            self.data_collection_files = []
          }, function(error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      }
    },
    created () {

    }
  }
</script>
