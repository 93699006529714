<template>

<div>
  <div class="card"
       v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], project)"
       qid="integrity-section"
  >
    <div class="card-header">
      <span class="float-right btn btn-success"
            @click="showFormFunction"
            qid="integrity-edit-button"
            v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"
      >
        <font-awesome-icon icon="pen"/> {{ $t('system.edit') }}
      </span>

      <h5 class="float-left"
          qid="integrity-table-title"
      >
        {{ $t('projects.view.generic_controls_assignees_title') }}
      </h5>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card-body">

          <h5 qid="integrity-table-title" v-if="project.type != 'security_measure' && project.type != 'control' && project.type != 'risk_assessment'">
            {{ $t('projects.create.task_assignees') }}
          </h5>

          <div class="mw-500" v-if="showForm">
            <div class="" v-if="project.type != 'validation'">
              <b-form-checkbox
                v-model="form.control_scope_owners"
                name="checkbox-1"
                class="mt-1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('table.accountables') }}
              </b-form-checkbox>
            </div>
            <br>
            <div class="" v-if="project.type != 'validation'">
              <b-form-checkbox
                v-model="form.control_scope_members"
                name="checkbox-2"
                class="mt-1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('table.responsibles') }}
              </b-form-checkbox>
            </div>
            <br>
            <div>
              <multiselect
                  v-model="selectedUsers"
                  :options="users"
                  :multiple="true"
                  :taggable="false"
                  @search-change="loadUsers"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="slug"
                  :placeholder="$t('table.alternative_responsible')"
                  label="name"
                  qid="create-p-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="mt-2">
              <span class="text-muted" v-if="project.type != 'validation'">
                {{ $t('projects.create.task_assignees_explainer_text') }}
              </span>
              <span class="text-muted" v-if="project.type == 'validation'">
                {{ $t('projects.create.task_assignees_validation_explainer_text') }}
              </span>
            </div>
          </div>
          <div v-if="!showForm">
            <label>{{ $t('table.accountables') }}</label>
            <div class="section-container">
              <span class="mt-1 text-dark" v-if="project.control_scope_owners">
                <font-awesome-icon icon="check-circle" class="icon-success"/>
                {{ $t('system.yes') }}
              </span>
              <span class="mt-1 text-dark" v-if="!project.control_scope_owners">
                <font-awesome-icon icon="times-circle" class="icon-danger"/>
                {{ $t('system.no') }}
              </span>
            </div>
            <br>
            <label>{{ $t('table.responsibles') }}</label>
            <div class="section-container">
              <span class="mt-1 text-dark" v-if="project.control_scope_members">
                <font-awesome-icon icon="check-circle" class="icon-success"/>
                {{ $t('system.yes') }}
              </span>
              <span class="mt-1 text-dark" v-if="!project.control_scope_members">
                <font-awesome-icon icon="times-circle" class="icon-danger"/>
                {{ $t('system.no') }}
              </span>
            </div>
            <br>
            <label>{{ $t('table.alternative_responsible') }}</label>
            <div class="section-container" v-if="project.control_scope_users && project.control_scope_users.length">
              <span class="mr-1" v-for="user in project.control_scope_users" qid="activity-users-list">
                <Avatar :object="user" size="xs"></Avatar>
              </span>
            </div>
            <p v-if="!project.control_scope_users || !project.control_scope_users.length" class="text-muted section-container">
              {{ $t('system.missing_input') }}
            </p>
            <div class="mt-2">
              <span class="text-muted" v-if="project.type != 'validation'">
                {{ $t('projects.create.task_assignees_explainer_text') }}
              </span>
              <span class="text-muted" v-if="project.type == 'validation'">
                {{ $t('projects.create.task_assignees_validation_explainer_text') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showForm">
      <div class="col-sm-12">
        <div class="card-body">
          <div v-if="showForm">
            <div>
              <b-button class="mt-3"
                        variant="secondary"
                        @click="cancelForm"
                        qid="risk-form-cancel-button"
              >
                {{ $t('processes.legal_basis.create.cancel') }}
              </b-button>
              <b-button class="mt-3 ml-3"
                        variant="success"
                        @click="formSubmit"
                        qid="risk-form-submit-button"
              >
                {{ $t('processes.legal_basis.create.submit') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ControlScopeTable',
    data: function() {
      return {
        form: {
          scopeOptions: '',
          control_scope_users: [],
          control_scope_owners: '',
          control_scope_members: ''
        },
        selectedImpact: [],
        selectedProbability: [],
        description: '',
        error: '',
        loadingItems: false,
        validationShow: false,
        updateValidationList: false,
        showForm: false,
        project: {},
        scopeOptions: [],
        radioOptions: [
          {text: this.$t('system.yes'), value: 'Yes'},
          {text: this.$t('system.no'), value: 'No'},
        ],
        users: [],
        selectedUsers: []
      }
    },
    props: {
      object: {
        type: Object
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    components: {
      Avatar
    },
    watch: {
      object: function (value) {
        this.project = value
      }
    },
    methods: {

      formSubmit(evt) {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.control_scope_users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.form.control_scope_users.push(this.selectedUsers[i].id);
          }
        }

        piincHttp.put('projects/' + this.project.slug, {
          control_scope_users: this.form.control_scope_users,
          control_scope_owners: this.form.control_scope_owners,
          control_scope_members: this.form.control_scope_members,
        }).then(function(response) {
          self.project = response.data
          self.$emit('updated');
          self.showForm = false;
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      handleValidationModalClose() {
        this.loadProcess();
        this.updateValidationList = true
      },
      cancelForm() {
        this.showForm = false
        this.form.scopeOptions = ''
      },
      showFormFunction() {
        let self = this;
        this.loadUsers();

        this.selectedUsers = [];
        _.forEach(this.project.control_scope_users, function (user) {
            self.selectedUsers.push(user)
        })

        this.form.control_scope_owners = this.project.control_scope_owners;
        this.form.control_scope_members = this.project.control_scope_members;
        this.showForm = true
      },
      loadProcess() {
        let self = this;
        piincHttp.get('processes/' + this.project.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.project = response.data;
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        let role_name = [];

        role_name = [self.permissions.ROLE_CLIENT_ADMIN, self.permissions.ROLE_PARTITION_ADMIN];

        if (self.project.type == 'validation') {
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        if (self.project.type == 'control' || self.project.type == 'security_measure' || self.project.type == 'risk_assessment') {
          role_name.push(self.permissions.ROLE_MEMBER);
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            role_name: role_name,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
        }, function() {});
      },
    },
    created () {
      this.project = this.object
      this.loadUsers();
    }
  }
</script>
