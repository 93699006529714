<template>

<div>
  <div class="card"
       v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], project)"
       qid="integrity-section"
  >
    <div class="card-header">
      <span class="float-right btn btn-secondary btn-sm"
            @click="showFormFunction"
            qid="integrity-edit-button"
            v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"
      >
        <font-awesome-icon icon="pen"/>
      </span>
      <h5 class="mb-2"
          qid="integrity-table-title"
      >
        {{ $t('projects.measures.mapping_task_title') }}
      </h5>
      <div>
        <span v-if="getGuide('system_description_task')">
          <GuideButton :guide="getGuide('system_description_task')"></GuideButton>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card-body">

          <div class="mw-500" v-if="showForm">
            <div>
              <b-form-group>
                <label>
                  {{ $t('projects.measures.mapping_task_assignee_label') }}
                </label>
                <multiselect
                    v-model="selectedUsers"
                    :options="users"
                    :multiple="true"
                    :taggable="false"
                    @search-change="loadUsers"
                    select-label="✔"
                    selected-label="✔"
                    deselect-label="x"
                    track-by="slug"
                    :placeholder="$t('projects.measures.mapping_task_assignee_placeholder')"
                    label="name"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <span class="mr-3 user-tag">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group>
                <label>
                  {{ $t('projects.measures.mapping_task_status_label') }}
                </label>
                <multiselect
                    v-model="selectedStatus"
                    :options="statusOptions"
                    :placeholder="$t('projects.measures.mapping_task_status_placeholder')"
                    label="text"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-p-form-pg"
                ></multiselect>
              </b-form-group>

              <div>
                <b-button class="mt-3"
                          variant="secondary"
                          @click="cancelForm"
                          qid="risk-form-cancel-button"
                >
                  {{ $t('processes.legal_basis.create.cancel') }}
                </b-button>
                <b-button class="mt-3 ml-3"
                          variant="success"
                          @click="formSubmit"
                          qid="risk-form-submit-button"
                >
                  {{ $t('processes.legal_basis.create.submit') }}
                </b-button>
              </div>
              <br>
            </div>
            <br>
          </div>
          <div v-if="!showForm">
            <div class="">
              <label for="" class="pr-2">
                {{ $t('projects.measures.mapping_task_assignee_label') }}:
              </label>
              <span class="" v-if="measureTask.users && measureTask.users.length">
                <span class="mr-1" v-for="user in measureTask.users" qid="activity-users-list">
                  <Avatar :object="user" size="xs"></Avatar>
                </span>
              </span>
              <span v-if="!measureTask.users || !measureTask.users.length" class="text-muted">
                <font-awesome-icon
                    icon="exclamation-triangle"
                    class="risk-6 mr-2"
                />
                {{ $t('system.missing_input') }}
              </span>
              <label for="" class="pl-3 pr-2">
                {{ $t('projects.measures.mapping_task_status_label') }}:
              </label>
              <span class="" v-if="measureTask.status">
                <span v-if="measureTask.status === 'completed'">
                  <font-awesome-icon icon="check-circle" class="icon-success mr-2"/>
                  {{ $t('system.completed') }}
                </span>
                <span v-if="measureTask.status === 'pending' || measureTask.status == null">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6 mr-2"
                  />
                  {{ $t('system.pending') }}
                </span>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'MeasureScopeTable',
    data: function() {
      return {
        form: {
          scope_users: []
        },
        error: '',
        showForm: false,
        showStatusForm: false,
        project: {},
        statusOptions: [
          {text: this.$t('system.pending'), value: 'pending'},
          {text: this.$t('system.completed'), value: 'completed'},
        ],
        myGuides: [],
        selectedStatus: [],
        users: [],
        selectedUsers: [],
      }
    },
    props: {
      object: {
        type: Object
      },
      measureTask: {
        type: Object
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    components: {
      Avatar,
      GuideButton
    },
    watch: {
      object: function (value) {
        this.project = value
      }
    },
    methods: {
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Task' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Task',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      formSubmit(evt) {
        let self = this;

        this.form.scope_users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.form.scope_users.push(this.selectedUsers[i].id);
          }
        }

        piincHttp.put('tasks/' + this.measureTask.id, {
          users: this.form.scope_users,
          status: this.selectedStatus && this.selectedStatus.value ? this.selectedStatus.value : null,
        }).then(function(response) {
          self.$emit('updated');
          self.showForm = false;
          //self.measureTask = response.data
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      cancelForm() {
        this.showForm = false;
        this.error = ''
      },
      showFormFunction() {
        let self = this;
        this.loadUsers();

        this.selectedStatus = []
        if (self.measureTask.status) {
          self.selectedStatus = _.find(self.statusOptions, function(item) {
            if(item.value === self.measureTask.status){
              return item
            }
          });
        }

        this.selectedUsers = [];
        _.forEach(this.measureTask.users, function (user) {
          self.selectedUsers.push(user)
        })

        this.showForm = true
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
        }, function() {});
      },
    },
    created () {
      this.project = this.object
      this.loadUsers();
      this.loadGuides('system_description_task');
    }
  }
</script>
