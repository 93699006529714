<template>
  <div>
    <!-- System table -->
    <b-button
            class="btn btn-secondary float-right mr-0"
            @click="showEditSModal()"
            qid="processes-button-create"
            v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
            :title="$t('activities.process.create_button')"
    >
      <font-awesome-icon icon="plus-circle"/>
    </b-button>

    <!-- Delete system modal template -->
    <b-modal ref="delete-ac" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h6 class="text-center" v-if="showListOfTasks">
        {{ $t('projects.tab.tasks') }}
      </h6>
      <table class="table table-responsive-md" v-if="showListOfTasks && itemToDelete && itemToDelete.model">
        <thead>
        <tr>
          <th>
            {{ $t('projects.table.name') }}
          </th>
          <th>
            {{ $t('system.status') }}
          </th>
        </tr>
        </thead>

        <tbody v-if="open_tasks && open_tasks.length">
          <tr v-for="(object, index) in open_tasks" :key="index">
            <td v-if="(object.model && object.model.id == itemToDelete.model.id) || (object.scope && object.scope.id == itemToDelete.model.id)">
              <p>
                <span v-if="object.code == 'business_risk_assessment' || object.code == 'privacy_risk_assessment' || object.code == 'system_impact_assessment'">
                  {{ $t('maintenance.assessments.system_impact_assessment_label') }}
                </span>
                <span v-else>{{ object.title}}</span><br style="height:0px;"/>
                <span class="text-muted">
                  {{ object.model.name }}
                </span>
              </p>
            </td>
            <td v-if="(object.model && object.model.id == itemToDelete.model.id) || (object.scope && object.scope.id == itemToDelete.model.id)">
              <span v-if="object.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
              </span>
              <span v-if="object.status === 'pending'" class="pr-2">
                <font-awesome-icon
                    icon="exclamation-triangle"
                    class="risk-6"
                />
              </span>
              {{ object.status | capitalize }}
            </td>
          </tr>
        </tbody>

        <tbody v-if="open_tasks && !open_tasks.length">
          <tr qid="no-results-row" class="text-center">
            <td colspan="10">
              <h5 class="mt-3">
                {{ $t('system.no_results') }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add activities modal -->
    <b-modal ref="edit-system"
             hide-footer
             @hide="hideEditSModal"
             size="xl"
             modal-class="task-modal"
    >

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <h5 class="mb-1 text-center" qid="update-f-title">
            {{ $t('projects.activity.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="addItemsFormSubmit" qid="create-p-form">
        <div>
          <div class="row">
            <div class="col">
              <b-form-group>
                <label qid="edit-lb-category-label">
                  {{ $t('projects.activity.create.add_label') }}
                </label>
                <div>
                  <b-form-input
                    type="text"
                    v-model="keywordValue"
                    @click="showSelectionTable = true"
                    qid="search-do-input"
                    v-on:input="debounceInput"
                    :placeholder="$t('projects.activity.create.search_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>
              <div>
                <label class="float-right cursor-pointer ml-3 pt-2"
                       @click="unselectAll"
                       qid="system-unselect-button"
                >
                  {{ $t('system.unselect_all') }}
                </label>
                <label class="float-right cursor-pointer pt-2"
                       @click="selectAll"
                       qid="system-select-button"
                >
                  {{ $t('system.select_all') }}
                </label>
              </div>
              <br>
              <div class="form-control scrollable-height-400"
                   qid="tp-source-data-objects"
              >
                <div>
                  <div class="table-item-div cursor-pointer"
                       v-for="(item, index) in DOItems"
                       :key="index"
                       @click="selectDataObject(index, item)"
                       :class="{'table-item-div-selected': item.selected}"
                  >
                    <div class="text-bold">
                      {{ item.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="item.tags_translated && item.tags_translated.length" qid="activity-tags-list">
                          <span v-for="(tag, index) in getReducedTags(item.tags_translated)"
                                :key="index"
                                :title="tag.name_translated"
                                qid="p-tags-list"
                          >
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="item.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(item, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ item.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3" v-if="item.organizations">
                        <span class="mr-1"
                              v-for="(organization, index) in item.organizations"
                              :key="index"
                              qid="p-organizations-list"
                        >
                          <span class="badge">
                            <font-awesome-icon icon="sitemap"/>
                            {{organization.name}}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sLoading && !DOItems.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="text-muted mt-2 mb-3">
                {{ $t('projects.create.selected_items_tip') }}
              </div>
            </div>
            <div class="col-1">
              <b-button class="mt-300 btn-block"
                        variant="success"
                        @click="addData"
                        size="sm"
                        qid="system-select-button"
              >
                {{ $t('system.add') }} >
              </b-button>
            </div>
            <div class="col">
              <b-form-group>
                <label qid="edit-s-data-label">
                  {{ $t('projects.activity.create.select_item_label') }}
                </label>
                <div>
                  <b-form-input
                    type="text"
                    v-model="keywordFilterValue"
                    qid="search-do-input"
                    v-on:input="filterSelectedData"
                    :placeholder="$t('projects.activity.create.filter_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>

              <br>
              <div class="form-control scrollable-height-400 mt-3" qid="tp-source-data-objects">
                <div>
                  <div class="table-item-div cursor-pointer"
                       v-for="(itemDO, index) in allDataObjectsFiltered"
                       :key="index"
                       :class="{'table-item-div-selected': itemDO.related}"
                  >
                    <span class="pt-2 pb-2 pl-2 pr-2 float-right">
                      <font-awesome-icon
                          icon="times"
                          size="sm"
                          @click="removeDataItem(itemDO)"
                          qid="tp-source-data-object-remove"
                          v-if="itemDO.new_item"
                      />
                    </span>
                    <div class="text-bold">
                      {{ itemDO.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="itemDO.tags_translated && itemDO.tags_translated.length" qid="activity-tags-list">
                          <span v-for="(tag, index) in getReducedTags(itemDO.tags_translated)"
                                :key="index"
                                :title="tag.name_translated"
                                qid="p-tags-list"
                          >
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="itemDO.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(itemDO, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ itemDO.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3" v-if="itemDO.organizations">
                        <span class="mr-1"
                              v-for="(organization, index) in itemDO.organizations"
                              :key="index"
                              qid="p-organizations-list"
                        >
                          <span class="badge">
                            <font-awesome-icon icon="sitemap"/>
                            {{organization.name}}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div v-for="(itemDO, index) in sTableItemsFull"
                       :key="index"
                  >
                    <div class="table-item-div cursor-pointer"
                         :class="{'table-item-div-selected': itemDO.related}"
                         v-if="itemDO.model"
                    >
                      <div class="text-bold">
                        {{ itemDO.model.name }}
                      </div>
                      <div>
                        <span class="mr-3">
                          <span v-if="itemDO.model.tags_translated && itemDO.model.tags_translated.length" qid="activity-tags-list">
                            <span v-for="(tag, index) in getReducedTags(itemDO.model.tags_translated)"
                                  :title="tag.name_translated"
                                  :key="index"
                                  qid="p-tags-list"
                            >
                              <span class="badge" :title="tag.name_translated">
                                <font-awesome-icon icon="tag"/>
                                {{ tag.name_translated | truncate(35) }}
                              </span>
                            </span>
                            <span v-if="itemDO.model.tags_translated.length > 1"
                                  class="badge cursor-pointer"
                                  @click="openTagsModal(itemDO.model, $event)"
                            >
                              <font-awesome-icon icon="tags"/>
                              + {{ itemDO.model.tags_translated.length - 1 }}
                            </span>
                          </span>
                        </span>
                        <span class="mr-3" v-if="itemDO.model.organizations">
                          <span class="mr-1"
                                v-for="(organization, index) in itemDO.model.organizations"
                                :key="index"
                                qid="p-organizations-list"
                          >
                            <span class="badge">
                              <font-awesome-icon icon="sitemap"/>
                              {{organization.name}}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sTableItemsFull.length && !allDataObjectsFiltered.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="proxy-footer" style="width:1360px;">
          <div class="alert alert-menu-color color-secondary mb-0 " role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-3"
                          variant="secondary"
                          @click="hideEditSModal"
                          qid="create-p-cancel-button"
                >
                  {{ $t('systems.create.cancel') }}
                </b-button>
                <b-button type="submit"
                          class="mt-3 ml-3"
                          :disabled="buttonDisabled"
                          variant="success"
                          qid="create-p-submit-button"
                >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
                  {{ $t('projects.activity.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

      </b-form>
    </b-modal>


    <!-- Show data objects modal -->
    <b-modal ref="view-data-objects" hide-footer @hide="hideDataObjectsModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('maintenance.third_parties.data_objects.title') }}
        </h4>
      </div>
      <div class="mt-5" v-if="filteredDataObjects && filteredDataObjects.length">
        <div v-for="(dataObject, index) in filteredDataObjects" :key="index">
          <div class="mb-4">
            <span class="badge float-right">
              {{ dataObject.classification }}
            </span>
            <span>{{ dataObject.name }}</span>
          </div>
        </div>
      </div>
      <div class="float-right">
        <b-button class="mt-3"
                  variant="success"
                  @click="hideDataObjectsModal"
                  qid="delete-tp-cancel-button"
        >
          {{ $t('maintenance.third_parties.data_objects.close') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Edit system modal -->
    <b-modal ref="edit-item" hide-footer @hide="hideEditModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('projects.activity.update.title') }}
        </h4>
      </div>
      <b-form @submit="editFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('table.alternative_responsible') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                @search-change="loadUsers"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('table.alternative_responsible')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';

  export default {
    name: 'ActivitiesProjectTable',
    data: function() {
      return {
        sForm: {
          items: []
        },

        activity: {},

        // Systems
        sTableItems: [],
        sTableItemsFull: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        sItems: [],
        sLoading: true,
        selectedItem: {},

        currentPage: 1,
        totalRows: 0,
        perPage: '',
        selectedPerPage: "10",
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' },
        ],

        // Data objects
        allDataObjects: [],
        allDataObjectsFiltered: [],
        dataObjects: [],
        systemDataObjects: [],
        newDataObjects: [],
        DOItems: [],
        selectedDataObjects: [],
        availableDataObjects: [],

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        selectedUsers: [],
        users: [],
        radioOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0}
        ],
        buttonDisabled: false,
        keywordValue: '',
        keywordFilterValue: '',
        showSelectionTable: false,
        selectedFiles: [],
        inputLink: '',
        showDocuments: false,
        filteredDataObjects: [],
        showUsersModal: false,
        showTagsModal: false,
        open_tasks: [],
        showListOfTasks: false
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      ShowMoreModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value;
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadDO(this.keywordValue)
      }, 400),
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.sForm.links.push(this.inputLink)
          // Reset link input form
          this.inputLink = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.sForm.links.splice(index, 1);
      },
      addData() {
        let self = this;
        _.forEach(this.DOItems, function (item) {
          if (item.selected) {
            self.updateDOList(item)
          }
        })
        this.keywordValue = '';
        this.loadDO()
      },
      filterSelectedData() {
        let self = this;
        this.allDataObjectsFiltered = [];
        if (self.keywordFilterValue) {
          _.forEach(this.allDataObjects, function (item) {
            if (
              item.name.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1 ||
              (item.category && item.category.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1) ||
              (item.classification && item.classification.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1)
            ) {
              self.allDataObjectsFiltered.push(item)
            }
          })
        } else {
          this.allDataObjectsFiltered = _.cloneDeep(this.allDataObjects);
        }
      },
      resetFields() {
        this.selectedRC = null
        this.selectedP = null
      },
      showDataObjectsModal(object, classification) {
        let self = this;
        this.selectedS = object

        if (!object) {
          this.availableDataObjects = [];
        }

        _.forEach(object.data_objects, function (item) {
          if (item.classification == classification) {
            self.filteredDataObjects.push(item)
          }
        })

        this.$refs['view-data-objects'].show()
      },
      hideDataObjectsModal() {
        this.selectedS = {}
        this.filteredDataObjects = []
        this.$refs['view-data-objects'].hide()
      },
      showEditModal(object) {
        let self = this;
        this.error = '';
        this.loadUsers();

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
              self.selectedUsers.push(user)
          })
        }

        this.selectedS = object
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      editFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let responsibles = []
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            responsibles.push(this.selectedUsers[i].id);
          }
        }

        piincHttp.put('projects/' + this.$route.params.slug + '/models/' + this.selectedS.id, {
          users: responsibles
        }).then(function(response) {
          self.hideEditModal();
          self.loadActivities();
          self.loadActivitiesFull();
          // Emit event to parent component to update newest validation
          self.$emit('reloadContent', response.data);
          self.$toastr('success', self.$t('projects.activity.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.users) {
              self.error += ' ' + error.data.errors.users[0]
            }
          }
        })
      },
      showCreateSModal() {
        this.$refs['add-access-control'].show()
      },
      hideCreateSModal() {
        this.error = ''
        this.sForm.links = [];
        this.selectedFiles = [];
        this.selectedS = []
        this.$refs['add-access-control'].hide()
      },
      showSConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.showListOfTasks = false
        this.loadTasks(object.model);
        this.$refs['delete-ac'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-ac'].hide()
      },
      openTagsModal(object, evt) {
        this.selectedItem = object
        this.showTagsModal = true
        evt.stopPropagation();
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 1) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 1);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedUsers(users, type) {
        let reducedUsers = []

        if (type == 'owner') {
          _.forEach(users, function (item) {
            if (item.owner) {
              reducedUsers.push(item)
            }
          })
        } else if (type == 'member') {
          _.forEach(users, function (item) {
            if (!item.owner) {
              reducedUsers.push(item)
            }
          })
        } else {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }

        return reducedUsers;
      },
      showDataObjects(object) {
        this.availableDataObjects = []
        if (object && object.data_objects && object.data_objects.length) {
          this.availableDataObjects = object.data_objects;
        }
      },
      hideDataObjects() {
        this.availableDataObjects = []
      },
      handleDO(index, item) {
        // Toggle related/not related property
        item.related = item.related ? false : true;
        this.$set(this.allDataObjects, index, item)
      },
      selectDataObject(index, item) {
        // Toggle related/not related property
        item.selected = item.selected ? false : true;
        this.$set(this.DOItems, index, item)
      },
      selectAll() {
        let newDataObjectsArray = []
        let self = this;
        _.forEach(this.DOItems, function (item) {
          item.selected = true;
          newDataObjectsArray.push(item)
        })
        this.DOItems = newDataObjectsArray;
      },
      unselectAll() {
        let newDataObjectsArray = []
        let self = this;
        _.forEach(this.DOItems, function (item) {
          item.selected = false;
          newDataObjectsArray.push(item)
        })
        this.DOItems = newDataObjectsArray;
      },
      relateAll() {
        let newDataObjectsArray = []
        _.forEach(this.allDataObjects, function (item) {
          item.related = true;
          newDataObjectsArray.push(item)
        })
        // make cloned array to avoid double actions on arrays
        this.allDataObjects = _.cloneDeep(newDataObjectsArray);
        this.allDataObjectsFiltered = _.cloneDeep(newDataObjectsArray);
      },
      unrelateAll() {
        let newDataObjectsArray = []
        _.forEach(this.allDataObjects, function (item) {
          item.related = false;
          newDataObjectsArray.push(item)
        })
        // make cloned array to avoid double actions on arrays
        this.allDataObjects = _.cloneDeep(newDataObjectsArray);
        this.allDataObjectsFiltered = _.cloneDeep(newDataObjectsArray);
      },
      updateDOList(selectedOption) {
        let self = this;
        let inArray = false;
        _.forEach(this.allDataObjects, function (item, index) {
          if (item.id == selectedOption.id) {
            // if item is already in array
            item.related = true;
            self.$set(self.allDataObjects, index, item)
            inArray = true;
            self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
          }
        })
        if (!inArray) {
          // If not in array, push it in array as related
          selectedOption.related = true;
          selectedOption.new_item = true;
          self.allDataObjects.push(selectedOption)
          self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
        }
      },
      removeDataItem(item) {
        // find indexes for both arrays
        let filteredIndex = _.findIndex(this.allDataObjectsFiltered, function(o) { return o.id == item.id; });
        let itemIndex = _.findIndex(this.allDataObjects, function(o) { return o.id == item.id; });

        // Remove item from data objects arrays
        this.allDataObjectsFiltered.splice(filteredIndex, 1);
        this.allDataObjects.splice(itemIndex, 1);

        this.loadDO()
      },
      showEditSModal(object) {
        let self = this;
        let inArray = false;
        self.allDataObjects = [];
        self.allDataObjectsFiltered = [];

        self.sTableItemsFull = []

        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
            {
              per_page: 100,
              model_type: 'App\\Activity\\Models\\Activity',
              client: this.getClient.slug
            }
        }).then(function(response) {
          self.sTableItemsFull = response.data.items;
          self.loadDO();
        }, function() {});

        this.$refs['edit-system'].show()
      },
      hideEditSModal() {
        this.selectedDataObjects = []
        this.keywordValue = ''
        this.keywordFilterValue = ''
        this.showSelectionTable = false
        this.allDataObjects = []
        this.systemRoleInput = ''
        this.error = ''
        this.$refs['edit-system'].hide()
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('projects/' + this.$route.params.slug + '/models/' + object.id).then(function() {
          self.hideSConfirmDeleteModal();
          self.loadActivities();
          self.loadActivitiesFull();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('projects.activity.delete.success'))
        }, function() {});
      },

      addItemsFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        self.sForm.items = []

        _.forEach(this.allDataObjects, function (item) {
          self.sForm.items.push({model: item.who_am_i, id: item.id})
        })

        if (self.sForm.items && self.sForm.items.length) {
          if (self.activity.type === 'control_assessment') {
            piincHttp.post('/projects/' + this.$route.params.slug + '/models', {
              items: this.sForm.items
            }).then(function() {
              self.$toastr('success', self.$t('projects.activity.create.success'))
              self.hideEditSModal();
              self.loadActivities();
              self.loadActivitiesFull();
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
                if (error.data.errors.process_id) {
                  self.error += ' ' + error.data.errors.process_id[0]
                }
              }
            })
          } else {
            piincHttp.post('/projects/' + this.$route.params.slug + '/models', {
              project_template: this.$route.params.slug,
              items: this.sForm.items
            }).then(function() {
              self.$toastr('success', self.$t('projects.activity.create.success'))
              self.hideEditSModal();
              self.loadActivities();
              self.loadActivitiesFull();
              self.$emit('reloadContent');
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
                if (error.data.errors.process_id) {
                  self.error += ' ' + error.data.errors.process_id[0]
                }
              }
            })
          }
        } else {
          this.error = this.$t('projects.activity.create.required')
          self.buttonDisabled = false;
        }

      },
      loadActivities(page, perPage) {
        let self = this;

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        self.sTableItems = []
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;

        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
            {
              page: pageNumber,
              per_page: perPageNumber,
              model_type: 'App\\Activity\\Models\\Activity',
              client: this.getClient.slug
            }
        }).then(function(response) {
          self.sLoading = false;
          self.totalRows = response.data.total_count;
          self.sTableItems = response.data.items;
        }, function() {});
      },
      loadActivitiesFull() {
        let self = this;

        self.sTableItemsFull = []

        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
            {
              per_page: 100,
              model_type: 'App\\Activity\\Models\\Activity',
              client: this.getClient.slug
            }
        }).then(function(response) {
          self.sTableItemsFull = response.data.items;
        }, function() {});
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadActivities(page)
      },
      perPageChanged(perPage) {
        let self = this;
        // Load Third parties when page changed listener is triggered
        this.loadActivities(1, perPage.value)
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadActivities(1, self.selectedPerPage)

      },
      loadDO(query) {
        let self = this;
        self.DOItems = []
        piincHttp.get('activities', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          let results = response.data.items
          _.forEach(results, function (item) {
            if (_.findIndex(self.sTableItemsFull, function(o) {
              if (o.model) {
                return o.model.id == item.id
              }
             }) == -1 && _.findIndex(self.allDataObjects, function(o) { return o.id == item.id }) == -1) {
              self.DOItems.push(item)
            }
          })
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        let role_name = [];

        role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        if (self.activity.type == 'validation') {
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        if (self.activity.type == 'control' || self.activity.type == 'security_measure' || self.activity.type == 'risk_assessment' || self.activity.type == 'bia_assessment') {
          role_name.push(self.permissions.ROLE_MEMBER);
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            role_name: role_name,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTasks(model) {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            client: this.getClient.slug,
            model_type: 'App\\Activity\\Models\\Activity',
            model_id: model.id,
            per_page: 100
          }
        }).then(function(response) {
          self.open_tasks = response.data.items;

          piincHttp.get('projects/' + self.object.slug + '/tasks', { params:
            {
              client: self.getClient.slug,
              scope_type: 'App\\Activity\\Models\\Activity',
              scope_id: model.id,
              per_page: 100
            }
          }).then(function(response) {
            if (response.data.items.length) {
              self.open_tasks.push(...response.data.items)
            }
            if (self.open_tasks.length) {
              self.showListOfTasks = true;
            }
          }, function() {});
        }, function() {});
      }
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {
      }
      // Table items
      this.loadActivities();
      this.loadActivitiesFull();
    }
  }
</script>
