<template>
  <div>
    <!-- Project table -->
    <div class="card"
         qid="systems-section"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="float-left" qid="systems-table-title">
              {{ $t('projects.projects_table.title') }}
            </h5>
          </div>
          <div class="col-sm-6 text-right">
            <span class="btn btn-success cursor-pointer"
                  @click="showRunManuallyModal(activity)"
                  qid="complete-project"
                  v-if="activity.type !== 'risk_assessment' || (activity.type === 'risk_assessment' && activity.assessment)"
            >
              {{ $t('projects.button.run_manually') }}
            </span>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('projects.projects_table.name') }}
            </th>
            <th scope="col">
              {{ $t('projects.projects_table.start_date') }}
            </th>
            <th scope="col">
              {{ $t('projects.projects_table.due_date') }}
            </th>
            <th scope="col">
              {{ $t('projects.projects_table.schedule') }}
            </th>
            <th scope="col">
              {{ $t('projects.projects_table.status') }}
            </th>
            <th>
              {{ $t('table.progress') }}
            </th>
            <th scope="col">
              {{ $t('system.last_updated') }}
            </th>
            <th width="140"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(object, index) in sTableItems" :key="index">
              <td>
                <router-link :to="{ name: 'projects.view', params: { slug: object.slug }}">
                  {{object.title}}
                </router-link>
              </td>
              <td>
                <span class="text-normal">{{ object.start_date | formatDate }}</span>
              </td>
              <td>
                <span class="text-normal">{{ object.end_date | formatDate }}</span>
              </td>
              <td>
                <span class="text-normal">{{ $t('projects.schedule.' + $options.filters.slugify(object.schedule_type)) }}</span>
              </td>
              <td>
                <span class="text-normal">{{ $t('projects.status.' + $options.filters.slugify(object.status)) }}</span>
              </td>
              <td class="pt-2 pb-2">
                <vm-progress
                    type="circle"
                    :percentage="object.progress"
                    style="vertical-align: middle;"
                    :width="50"
                    :stroke-width="2"
                    stroke-color="#00c29e"
                >
                  {{object.progress}}%
                </vm-progress>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td class="text-right">
                <span class="btn btn-secondary"
                      qid="clone-s-modal"
                      v-if="isGranted([permissions.PROJECT_CREATE, permissions.PROJECT_CREATE_OWN], activity)"
                      @click="showConfirmCloneModal(object)"
                >
                  <font-awesome-icon icon="copy"/>
                </span>

                <span class="btn btn-delete"
                      qid="delete-s-modal"
                      v-if="isGranted([permissions.PROJECT_DELETE, permissions.PROJECT_DELETE_OWN], activity)"
                      @click="showConfirmDeleteModal(object)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </span>
              </td>
            </tr>
            <tr v-if="!sLoading && !sTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete project modal template -->
    <b-modal ref="delete-project"  modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-task-title">
            {{ $t('activities.system.delete.title') }}
          </h5>
        </div>
      </template>

      <div class="mb-2 mt-3" style="font-size:18px;" v-if="itemToDelete && itemToDelete.id">
        <div>
          {{ $t('activities.system.delete.description') }}
          <span v-if="itemToDelete" class="text-bold">
            {{ itemToDelete.title }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('activities.system.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-12">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideConfirmDeleteModal"
                            qid="delete-project-cancel-button"
                  >
                    {{ $t('activities.system.delete.cancel') }}
                  </b-button>
                  <b-button @click="confirmDeleteProject(itemToDelete)"
                            class="mt-3 ml-3"
                            variant="danger"
                            :disabled="buttonDisabled"
                            qid="delete-project-submit-button"
                  >
                    {{ $t('activities.system.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Clone project modal template -->
    <b-modal
        ref="clone-project"
        @hide="hideConfirmCloneModal"
        modal-class="task-modal"
        hide-footer
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
        <span @click="hideConfirmCloneModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
          <font-awesome-icon icon="times"/>
        </span>
          <h5 class="mb-1 text-center" qid="run-p-title">
            {{ $t('projects.clone.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <div class="mt-2" v-if="activity.scheduled">
        <font-awesome-icon
            icon="exclamation-triangle"
            class="risk-9 mr-1"
        />
        {{ $t('projects.schedule.next_run_date_label') }}  {{ activity.nextRun | formatDate }}
      </div>

      <b-form @submit="confirmCloneProject(itemToClone)" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label class="mt-2">{{ $t('table.name') }}</label>
            <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="form.title"
                :placeholder="$t('table.name')"
                qid="create-p-links"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.start_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-start-date"
                calendar-class="left"
                v-model="form.start_date"
                :required="true"
                :clear-button="true"
                @selected="updateDisabled"
                :placeholder="$t('projects.schedule.start_date_placeholder')"
                :disabledDates="disabledDates"
            ></datepicker>
          </b-form-group>

          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.due_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.due_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.due_date_placeholder')"
                :disabledDates="disabledEndDates"
            ></datepicker>
          </b-form-group>

          <b-form-group v-if="activity.type === 'control_assessment'">
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.data_due_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.data_collection_due_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.data_due_date_placeholder')"
                :disabledDates="disabledEndDates"
            ></datepicker>
          </b-form-group>

          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.message_label') }}
            </label>
            <b-form-textarea
                qid="project-message-description"
                v-model="form.schedule_message"
                rows="6"
                max-rows="6"
                :placeholder="$t('projects.schedule.message_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-rr-attachments-label">
              {{ $t('maintenance.retention_rules.create.attachments') }}
              &nbsp;
              <span class="text-muted">({{ $t('attachments.file_size_label') }})</span>
            </label>
            <br>
            <p v-for="file in getFiles" :key="file.name">{{ file.name }}</p>
            <label class="btn btn-secondary cursor-pointer" qid="create-rr-attachments-button">
              <input type="file"
                     name="fileupload"
                     value="fileupload"
                     multiple
                     id="file-rr"
                     @change="onFileChange"
                     @click="resetFile"
              >
              <span>
              <font-awesome-icon icon="cloud-upload-alt" />
              {{ $t('attachments.upload_button') }}
            </span>
            </label>
          </b-form-group>

          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-tp-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>
            <p v-for="(link, index) in form.links" :key="index">
            <span class="float-right text-muted cursor-pointer"
                  @click="removeLinkItem(index)"
                  qid="create-tp-link-remove"
            >
              <font-awesome-icon icon="trash-alt" />
            </span>
              <a :href="link.url" target="_blank" qid="create-p-link-item">{{ link.name ? link.name : link.url }}</a>
            </p>
            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                  type="text"
                  aria-describedby="inputGroup"
                  v-model="inputLink"
                  :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                  qid="create-p-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                  type="text"
                  aria-describedby="inputGroup"
                  v-model="inputLinkName"
                  :placeholder="inputLink ? inputLink : $t('table.name')"
                  qid="create-p-links"
              ></b-form-input>
            </div>
            <span class="btn btn-link cursor-pointer float-right"
                  @click="handleLink"
                  qid="create-tp-add-link"
            >
              + {{ $t('system.add_link') }}
            </span>
          </b-form-group>

          <b-form-checkbox
              v-model="includeAttachments"
              name="checkbox-disable"
              :value="1"
              :unchecked-value="0"
              v-if="activity.type !== 'control_assessment'"
          >
            {{ $t('system.include_project_attachments') }}
          </b-form-checkbox>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideConfirmCloneModal"
                            qid="clone-project-cancel-button"
                  >
                    {{ $t('projects.clone.cancel') }}
                  </b-button>
                  <b-button @click="confirmCloneProject(itemToClone)"
                            class="mt-3 ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="clone-project-submit-button"
                  >
                    {{ $t('projects.clone.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Edit Project modal -->
    <b-modal id="modal-tabs"
             ref="edit-item"
             hide-footer
             @hide="hideEditModal"
             size="lg"
             class="model-content"
    >
      <div class="d-block text-center row">
        <h4 qid="delete-tp-title" class="mb-2">
          {{ $t('projects.projects_table.update.title') }}
        </h4>
        <h4 qid="delete-tp-title">
          "{{ selectedS.title }}"
        </h4>
        <div class="col-sm-12 text-center row ml-5">
          <span v-if="selectedS && selectedS.project_template && selectedS.project_template.users.length" class="mr-2 ml-4">
            {{ $t('table.owners') }}:
          </span>
          <span v-if="selectedS && selectedS.project_template && selectedS.project_template.users && selectedS.project_template.users.length"
                class="mr-3"
                qid="activity-users-list"
          >
            <span class="mr-1"
                  v-for="(user, user_index) in selectedS.project_template.users"
                  :key="user_index"
                  qid="activity-users-list"
            >
              <Avatar :object="user" size="xs"></Avatar>
            </span>
          </span>
          <span class="mr-2">{{ $t('projects.table.type') }}:</span>
          <span v-if="selectedS.type == 'update'" class="badge badge-info mr-4">
            {{ $t('projects.view.update_label') }}
          </span>
          <span v-if="selectedS.type == 'validation'" class="badge badge-info mr-4">
            {{ $t('projects.view.validation_label') }}
          </span>

          <span class="mr-2">{{ $t('projects.table.scope') }}:</span>
          <span class="badge badge-info mr-4" v-if="selectedS.scope == 'system'">
            {{ $t('projects.system.label') }}
          </span>
          <span class="badge badge-info mr-4" v-if="selectedS.scope == 'process'">
            {{ $t('projects.process.label') }}
          </span>
          <span class="badge badge-info mr-4" v-if="selectedS.scope == 'activity'">
            {{ $t('projects.activity.label') }}
          </span>
          <span class="badge badge-info mr-4" v-if="selectedS.scope == 'third_party'">
            {{ $t('projects.third_party.label') }}
          </span>
        </div>
      </div>
      <b-tabs class="mt-3">
        <!-- Basic info tab -->
        <b-tab title="Basic info">
          <div class="row mt-3">

            <div class="col-md-12" v-if="selectedS.status">
              <h6 class="text-dark">{{ $t('projects.projects_table.status') }}</h6>
              <p class="mt-2" v-if="!showStatusForm">
                <span class="cursor-pointer float-right" @click="showFormFunction()">
                  <font-awesome-icon icon="pen"/>
                </span>
                {{ selectedS.status }}
              </p>
              <div v-if="showStatusForm">
                <multiselect
                    v-model="selectedStatus"
                    :options="options"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('projects.projects_table.status')"
                    label="name"
                    track-by="name"
                    class="mw-300 float-left"
                    qid="status-dropdown"
                ></multiselect>
                <div class="float-left">
                  <b-button class="ml-3"
                            variant="secondary"
                            @click="cancelForm()"
                            qid="edit-pr-cancel-button"
                  >
                    {{ $t('activities.processing_type.cancel') }}
                  </b-button>
                  <b-button class="ml-2"
                            variant="success"
                            @click="formStatusSubmit"
                            qid="edit-pr-submit-button"
                  >
                    {{ $t('activities.processing_type.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <h6 class="text-bold">{{ $t('projects.scheduling') }}</h6>
            </div>
            <div class="col-sm-5 card-menu mt-2">
              <div class="text-dark m-2 pb-3">
                <div class="mt-3 p-1">{{ $t('projects.projects_table.start_date') }}</div>
                <div class="mt-4 p-1">{{ $t('projects.table.due_date') }}</div>
                <div class="mt-4 p-1">{{ $t('projects.projects_table.message') }}</div>
              </div>
            </div>
            <div class="col-sm-7 mt-2">
              <div class="text-dark m-2">
                <div class="mt-3">
                  <span class="badge badge-info mb-1">{{ selectedS.start_date | formatDate}}</span>
                </div>
                <div class="mt-3">
                  <span class="badge badge-info mb-1">{{ selectedS.end_date | formatDate}}</span>
                </div>
                <div class="mt-4">
                  {{ selectedS.message }}
                </div>
              </div>
            </div>
          </div>

        </b-tab>


        <!-- Data tab -->
        <b-tab title="Open tasks" class="mt-3 row" @click="openTasksTabClicked">
          <div class="card row" v-if="open_tasks">
            <h6 class="text-dark">
              {{ $t('tasks.open_tasks') }}
              <b-button class="float-right"
                        variant="light"
                        @click="sendReminders(object)"
                        qid="delete-s-cancel-button"
              >
                {{ $t('tasks.send_reminders') }}
              </b-button>
            </h6>

            <table class="table table-responsive-md small">
              <thead>
              <tr>
                <th width="130">
                  {{ $t('projects.table.content') }}
                </th>
                <th width="130">
                  {{ $t('tasks.table.title') }}
                </th>
                <th width="90">
                  {{ $t('tasks.table.responsibles') }}
                </th>
                <th width="130"></th>
              </tr>
              </thead>

              <tbody v-if="open_tasks && open_tasks.length">
                <tr v-for="(object, index) in open_tasks" :key="index">
                  <td>
                    <span>
                      {{ object.model.name }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ object.title }}
                    </span>
                  </td>
                  <td>
                    <span v-if="object.users && object.users.length">
                      <span class="mr-1"
                            v-for="(user, user_index) in getReducedUsers(object.users)"
                            :key="user_index"
                      >
                        <span qid="profile-photo">
                          <Avatar :object="user" size="xs"></Avatar>
                        </span>
                      </span>
                      <button v-if="object.users && object.users.length > 3"
                              class="badge badge-info mr-1 rounded-circle"
                              @click="openUsersModal(object)"
                      >
                        + {{ object.users.length - 3 }}
                      </button>
                    </span>
                  </td>
                  <td>
                    <b-button class="mr-2 small"
                              variant="light"
                              @click="showEditUsersModal(object)"
                              qid="delete-s-cancel-button"
                    >
                      {{ $t('tasks.re_assign_button') }}
                    </b-button>
                  </td>
                </tr>
                <tr v-if="open_tasks && !open_tasks.length" qid="no-results-row" class="text-center">
                  <td colspan="10">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
              </tbody>
            </table>
          </div>
        </b-tab>


        <!-- Data tab -->
        <b-tab title="Closed tasks" class="mt-3" @click="closedTasksTabClicked">
          <div class="card row" v-if="closed_tasks">
            <h6 class="text-dark">{{ $t('tasks.closed_tasks') }}</h6>
            <table class="table table-responsive-md small">
              <thead>
              <tr>
                <th>
                  {{ $t('projects.table.content') }}
                </th>
                <th>
                  {{ $t('tasks.table.title') }}
                </th>
                <th width="100">
                  {{ $t('tasks.table.responsibles') }}
                </th>
              </tr>
              </thead>

              <tbody v-if="closed_tasks && closed_tasks.length">
                <tr v-for="(object, index) in closed_tasks" :key="index">
                  <td>
                    <span>
                      {{ object.model.name }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ object.title }}
                    </span>
                  </td>
                  <td>
                    <span v-if="object.users && object.users.length">
                      <span class="mr-1"
                            v-for="(user, user_index) in getReducedUsers(object.users)"
                            :key="user_index"
                      >
                        <span qid="profile-photo">
                          <Avatar :object="user" size="xs"></Avatar>
                        </span>
                      </span>
                      <button v-if="object.users && object.users.length > 3"
                              class="badge badge-info mr-1 rounded-circle"
                              qid="show-data-users"
                              @click="openUsersModal(object)"
                      >
                        + {{ object.users.length - 3 }}
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="closed_tasks && !closed_tasks.length">
                <tr qid="no-results-row" class="text-center">
                  <td colspan="10">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

      </b-tabs>

    </b-modal>

    <!-- Re-assign modal -->
    <b-modal ref="re-assign" hide-footer @hide="hideEditUsersModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('tasks.re_assign.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="reassignFormSubmit" qid="create-p-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedAssignUsers"
                :options="selectedTask.model.users"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="slug"
                :placeholder="$t('projects.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditUsersModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Run project manually modal -->
    <b-modal
          ref="run-item"
          @hide="hideRunManuallyModal"
          modal-class="task-modal"
          hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideRunManuallyModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="run-p-title">
            {{ $t('projects.run_manually_title') }}
          </h5>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <div class="mt-2" v-if="activity.scheduled">
        <font-awesome-icon
            icon="exclamation-triangle"
            class="risk-9 mr-1"
        />
        {{ $t('projects.schedule.next_run_date_label') }}  {{ activity.nextRun | formatDate }}
      </div>
      <b-form @submit="runProjectFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label class="mt-2">{{ $t('table.name') }}</label>
            <b-form-input
              type="text"
              aria-describedby="inputGroup"
              v-model="form.title"
              :placeholder="$t('table.name')"
              qid="create-p-links"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.start_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-start-date"
                calendar-class="left"
                v-model="form.start_date"
                :required="true"
                :clear-button="true"
                @selected="updateDisabled"
                :placeholder="$t('projects.schedule.start_date_placeholder')"
                :disabledDates="disabledDates"
            ></datepicker>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.due_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.due_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.due_date_placeholder')"
                :disabledDates="disabledEndDates"
            ></datepicker>
          </b-form-group>
          <b-form-group v-if="activity.type === 'control_assessment'">
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.data_due_date_label') }}
            </label>
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.data_collection_due_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.data_due_date_placeholder')"
                :disabledDates="disabledEndDates"
            ></datepicker>
          </b-form-group>
          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-p-users-label">
              {{ $t('projects.schedule.message_label') }}
            </label>
            <b-form-textarea
                qid="project-message-description"
                v-model="form.schedule_message"
                rows="6"
                max-rows="6"
                :placeholder="$t('projects.schedule.message_placeholder')"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-rr-attachments-label">
              {{ $t('maintenance.retention_rules.create.attachments') }}
              &nbsp;
              <span class="text-muted">({{ $t('attachments.file_size_label') }})</span>
            </label>
            <br>
            <p v-for="file in getFiles" :key="file.name">{{ file.name }}</p>
            <label class="btn btn-secondary cursor-pointer" qid="create-rr-attachments-button">
              <input type="file"
                     name="fileupload"
                     value="fileupload"
                     multiple
                     id="file-rr"
                     @change="onFileChange"
                     @click="resetFile"
              >
              <span>
                <font-awesome-icon icon="cloud-upload-alt" />
                {{ $t('attachments.upload_button') }}
              </span>
            </label>
          </b-form-group>
          <b-form-group v-if="activity.type !== 'control_assessment'">
            <label qid="create-tp-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>
            <p v-for="(link, index) in form.links" :key="index">
              <span class="float-right text-muted cursor-pointer"
                    @click="removeLinkItem(index)"
                    qid="create-tp-link-remove"
              >
                <font-awesome-icon icon="trash-alt" />
              </span>
              <a :href="link.url" target="_blank" qid="create-p-link-item">{{ link.name ? link.name : link.url }}</a>
            </p>
            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLink"
                :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                qid="create-p-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLinkName"
                :placeholder="inputLink ? inputLink : $t('table.name')"
                qid="create-p-links"
              ></b-form-input>
            </div>
            <span class="btn btn-link cursor-pointer float-right"
                  @click="handleLink"
                  qid="create-tp-add-link"
            >
              + {{ $t('system.add_link') }}
            </span>
          </b-form-group>

          <b-form-checkbox
            v-if="activity.type !== 'control_assessment'"
            v-model="includeAttachments"
            name="checkbox-disable"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t('system.include_project_attachments') }}
          </b-form-checkbox>

        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideRunManuallyModal"
                            qid="create-p-cancel-button"
                  >
                    {{ $t('projects.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-3 ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-p-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('projects.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import moment from "moment";

  export default {
    name: 'ProjectsTable',
    data: function() {
      return {
        sForm: {
          items: []
        },
        form: {
          title: '',
          due_date: '',
          data_collection_due_date: '',
          start_date: '',
          schedule_message: '',
          links: []
        },
        eform: {
          due_date: '',
          start_date: '',
          message: ''
        },
        disabledDates: {
          to: new Date(Date.now() - 8640000)
        },
        disabledEndDates: {
          to: new Date(Date.now() - 8640000)
        },

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',

        activity: {},
        sTableItems: [],
        selectedS: [],
        sLoading: true,
        error: '',
        itemToDelete: {},
        itemToClone: {},
        itemObject: {},
        buttonDisabled: false,
        open_tasks: {},
        closed_tasks: {},
        showStatusForm: false,
        selectedStatus: [],
        options: [
          { name: this.$t('projects.status.pending'), value: 'pending' },
          { name: this.$t('projects.status.in_progress'), value: 'in progress' },
          { name: this.$t('projects.status.completed'), value: 'completed' },
          { name: this.$t('projects.status.closed'), value: 'closed' }
        ],
        selectedTask: [],
        selectedAssignUsers: [],
        selectedItem: {},
        showUsersModal: false,
        selectedFiles: [],
        inputLink: '',
        inputLinkName: '',
        showDocuments: false,
        showAttachments: false,
        includeAttachments: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      Datepicker,
      ShowMoreModal
    },
    computed: {
      rows() {
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getFiles() {
        return this.selectedFiles
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      }
    },
    methods: {
      debounceInput: _.debounce(function() {

      }, 400),
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadProjects(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadProjects(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadProjects(1, self.selectedPerPage)

      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      resetFields() {
        this.selectedRC = null
      },
      cancelForm() {
        this.showStatusForm = false
      },
      hideEditUsersModal() {
        this.selectedTask = {}
        this.$refs['re-assign'].hide()
      },
      showEditUsersModal(object) {
        this.error = '';
        this.selectedTask = object
        this.$refs['re-assign'].show()
      },
      resetFile() {
        let imagefile = document.querySelector('#file-rr');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file-rr');
        // in order for v-for to re-render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.form.links.push({url: this.inputLink, name: this.inputLinkName})
          // Reset link input form
          this.inputLink = ''
          this.inputLinkName = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.form.links.splice(index, 1);
      },
      reassignFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        evt.preventDefault();

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }

        if (userIds && userIds.length) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            users: userIds
          }).then(function() {
            self.hideEditUsersModal();
            self.loadOpenTasks()
            self.$toastr('success', self.$t('tasks.re_assign.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          self.error = self.$t('tasks.re_assign.required')
          self.buttonDisabled = false;
        }
      },
      showFormFunction() {
        let self = this
        this.selectedStatus = []
        if (this.selectedS.status) {
          this.selectedStatus = _.find(this.options, function(item) {
            return item.value === self.selectedS.status;
          });
        }
        this.showStatusForm = true
      },
      formStatusSubmit() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.processing_role = this.processingRole ? this.processingRole.value : '';

        piincHttp.put('projects/' + this.selectedS.slug, {
          status: this.selectedStatus.value
        }).then(function(response) {
          self.selectedS = response.data
          self.cancelForm();
          self.loadProjects(self.currentPage);
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      sendReminders(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + object.slug + '/reminders').then(function() {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      openTasksTabClicked() {
        this.loadOpenTasks()
        this.showClosedTasksTab = false
        this.showOpenTasksTab = true
      },
      closedTasksTabClicked() {
        this.loadClosedTasks()
        this.showClosedTasksTab = true;
        this.showOpenTasksTab = false
      },
      loadOpenTasks() {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.selectedS.slug + '/tasks', { params:
          {
            status: 'Pending',
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.open_tasks = response.data.items;
        }, function() {});
      },
      loadClosedTasks() {
        let self = this;
        // Call API for closed tasks
        piincHttp.get('projects/' + this.selectedS.slug + '/tasks', { params:
          {
            status: 'Completed',
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.closed_tasks = response.data.items;
        }, function() {});
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      showEditModal(object) {
        this.error = '';

        this.selectedS = object
        this.loadOpenTasks()
        this.loadClosedTasks()
        this.eform.due_date = this.selectedS.end_date;
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      updateDisabled(date) {
        this.disabledEndDates.to = date ? date : new Date(this.form.start_date - 8640000)
      },
      showRunManuallyModal(object) {
        let moment = require('moment');
        this.error = '';

        // Set default name
        this.form.title = this.object.title + ' ' + moment().format('DD MMM YYYY');

        // Logic for date from interval

        if (object.due_after) {
          if (object.due_after_interval === 'Days') {
            this.form.due_date = moment().add(object.due_after, 'day').toDate();
          }
          if (object.due_after_interval === 'Months') {
            this.form.due_date = moment().add(object.due_after, 'month').toDate();
          }
          if (object.due_after_interval === 'Years') {
            this.form.due_date = moment().add(object.due_after, 'year').toDate();
          }
        } else {
          this.form.due_date = moment().add(14, 'day').toDate();
        }

        if (object.data_collection_due_after) {
          if (object.data_collection_due_after_interval === 'Days') {
            this.form.data_collection_due_date = moment().add(object.data_collection_due_after, 'day').toDate();
          }
          if (object.data_collection_due_after_interval === 'Months') {
            this.form.data_collection_due_date = moment().add(object.data_collection_due_after, 'month').toDate();
          }
          if (object.data_collection_due_after_interval === 'Years') {
            this.form.data_collection_due_date = moment().add(object.data_collection_due_after, 'year').toDate();
          }
        } else {
          this.form.data_collection_due_date = moment().add(14, 'day').toDate();
        }

        this.form.start_date = moment().toDate();

        this.updateDisabled()
        this.form.schedule_message = this.object.schedule_message;
        this.$refs['run-item'].show()
      },
      hideRunManuallyModal() {
        this.form.start_date = '';
        this.form.end_date = '';
        this.form.links = [];
        this.inputLink = '';
        this.selectedFiles = [];
        this.form.schedule_message = '';
        this.form.title = '';
        this.$refs['run-item'].hide()
      },
      runProjectFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        let sizeError = false;
        let files = document.querySelector('#file-rr');

        // Call handle link function to gather link from link input
        this.handleLink()

        let formData = new FormData();

        if (files) {

          // Append list of attachments to FormData
          for (let i = 0; i < files.files.length; i++) {
            // Equal to 10 MB
            if (files.files[i].size > 10485760) {
              sizeError = true;
            }
            formData.append("files[]", files.files[i]);
          }
        }
        // Append list of links to FormData
        for (let j = 0; j < this.form.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.form.links[j]));
        }

        formData.append("title", this.form.title);

        formData.append("include_attachments", this.includeAttachments);

        formData.append("compliance_activity", this.activity.slug);

        this.form.due_date = this.form.due_date ? moment(this.form.due_date).format('YYYY-MM-DD') : '';
        formData.append("due_date", this.form.due_date);

        this.form.data_collection_due_date = this.form.data_collection_due_date ? moment(this.form.data_collection_due_date).format('YYYY-MM-DD') : '';
        formData.append("data_collection_due_date", this.form.data_collection_due_date);

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '';
        formData.append("start_date", this.form.start_date);

        if (this.form.schedule_message && this.form.schedule_message.length) {
          formData.append("schedule_message", this.form.schedule_message);
        }

        if (sizeError) {
          this.error = this.$t('system.file_size')
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;

          if (this.form.start_date <= this.form.due_date && this.form.title && this.form.title.length) {
            piincHttp.post('projects/' + this.activity.slug + '/projects', formData, {

            }).then(function() {
              self.hideRunManuallyModal();
              self.$toastr('success', self.$t('projects.create.project_success'))
              self.loadProjects()
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
                if (error.data.errors.start_date) {
                  self.error += ' ' + error.data.errors.start_date[0]
                }
                if (error.data.errors.due_date) {
                  self.error += ' ' + error.data.errors.due_date[0]
                }
              }
            })
          } else {
            self.buttonDisabled = false;
            self.error = ''
            if (!this.form.title.length) {
              self.error = self.error + ' ' + self.$t('projects.schedule.name_error');
            }
            if (this.form.start_date > this.form.due_date) {
              self.error = self.error + ' ' + self.$t('projects.schedule.start_date_greater_error');
            }
          }
        }

      },
      editFormSubmit(evt) {
        let moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.eform.due_date = this.eform.due_date ? moment(this.eform.due_date).format('YYYY-MM-DD') : '';

        piincHttp.put('projects/' + this.selectedS.slug, {end_date: this.eform.due_date}).then(function() {
          self.hideEditModal();
          self.loadProjects(self.currentPage);
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object;
        this.$refs['delete-project'].show();
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {};
        this.$refs['delete-project'].hide();
      },
      showConfirmCloneModal(object) {
        let moment = require('moment');

        this.error = '';
        this.itemToClone = object;

        // Set default name
        this.form.title = this.object.title + ' ' + moment().format('DD MMM YYYY');

        // Logic for date from interval
        if (this.activity.due_after) {
          if (this.activity.due_after_interval === 'Days') {
            this.form.due_date = moment().add(this.activity.due_after, 'day').toDate();
          }
          if (this.activity.due_after_interval === 'Months') {
            this.form.due_date = moment().add(this.activity.due_after, 'month').toDate();
          }
          if (this.activity.due_after_interval === 'Years') {
            this.form.due_date = moment().add(this.activity.due_after, 'year').toDate();
          }
        } else {
          this.form.due_date = moment().add(14, 'day').toDate();
        }

        if (this.activity.data_collection_due_after) {
          if (this.activity.data_collection_due_after_interval === 'Days') {
            this.form.data_collection_due_date = moment().add(this.activity.data_collection_due_after, 'day').toDate();
          }
          if (this.activity.data_collection_due_after_interval === 'Months') {
            this.form.data_collection_due_date = moment().add(this.activity.data_collection_due_after, 'month').toDate();
          }
          if (this.activity.data_collection_due_after_interval === 'Years') {
            this.form.data_collection_due_date = moment().add(this.activity.data_collection_due_after, 'year').toDate();
          }
        } else {
          this.form.data_collection_due_date = moment().add(14, 'day').toDate();
        }

        this.form.start_date = moment().toDate();

        this.updateDisabled()
        this.form.schedule_message = this.object.schedule_message;

        this.$refs['clone-project'].show();
      },
      hideConfirmCloneModal() {
        this.itemToClone = {};
        this.$refs['clone-project'].hide();
      },
      showEditSModal(object) {
        this.eform.due_date = object.end_date;
        this.$refs['edit-system'].show();
      },
      hideEditSModal() {
        this.$refs['edit-system'].hide();
      },
      confirmDeleteProject(object) {
        let self = this;
        piincHttp.delete('projects/' + object.slug).then(function() {
          self.hideConfirmDeleteModal();
          self.loadProjects();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('projects.project.delete.success'));
        }, function() {});
      },
      confirmCloneProject(object) {
        let moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let sizeError = false;
        let files = document.querySelector('#file-rr');

        // Call handle link function to gather link from link input
        this.handleLink()

        let formData = new FormData();

        if(files){
          // Append list of attachments to FormData
          for (let i = 0; i < files.files.length; i++) {
            // Equal to 10 MB
            if (files.files[i].size > 10485760) {
              sizeError = true;
            }
            formData.append("files[]", files.files[i]);
          }

        }

        // Append list of links to FormData
        for (let j = 0; j < this.form.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.form.links[j]));
        }

        formData.append("title", this.form.title);

        formData.append("include_attachments", this.includeAttachments);

        formData.append("compliance_activity", this.activity.slug);

        this.form.due_date = this.form.due_date ? moment(this.form.due_date).format('YYYY-MM-DD') : '';
        formData.append("due_date", this.form.due_date);

        this.form.data_collection_due_date = this.form.data_collection_due_date ? moment(this.form.data_collection_due_date).format('YYYY-MM-DD') : '';
        formData.append("data_collection_due_date", this.form.data_collection_due_date);

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '';
        formData.append("start_date", this.form.start_date);

        if (this.form.schedule_message && this.form.schedule_message.length) {
          formData.append("schedule_message", this.form.schedule_message);
        }

        if (sizeError) {
          this.error = this.$t('system.file_size')
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;

          if (this.form.start_date <= this.form.due_date && this.form.title && this.form.title.length) {
            piincHttp.post('projects/' + object.slug + '/clone', formData, {

            }).then(function() {
              self.hideConfirmCloneModal();
              self.loadProjects();
              self.buttonDisabled = false;

              // Trigger toastr message after modal close
              self.$toastr('success', self.$t('projects.clone.success'));
            }, function() {});

          } else {
            self.buttonDisabled = false;
            self.error = ''
            if (!this.form.title.length) {
              self.error = self.error + ' ' + self.$t('projects.schedule.name_error');
            }
            if (this.form.start_date > this.form.due_date) {
              self.error = self.error + ' ' + self.$t('projects.schedule.start_date_greater_error');
            }
          }
        }
      },
      loadProjects(page, perPage) {
        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('projects/' + this.$route.params.slug + '/projects', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.sTableItems = response.data.items
          // Set number of total items
          self.totalRows = response.data.total_count;

          self.sLoading = false;
        }, function() {});
      }
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadProjects();
    }
  }
</script>
