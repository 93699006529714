<template>
  <div>
    <!-- Generic controls table -->
    <div class="card"
         v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], activity)"
         qid="processes-section"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="mb-2" qid="processes-table-title">
              {{ $t('projects.generic_controls.title') }}
            </h5>
            <span v-if="getGuide('project_generic_controls')">
              <GuideButton :guide="getGuide('project_generic_controls')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success ml-3 pl-3 pr-3"
                    @click="showEditSModal()"
                    qid="processes-button-create"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
            >
              <font-awesome-icon icon="plus"/> {{ $t('projects.generic_controls.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('processes.table.name') }}
            </th>
            <th scope="col" v-if="activity.scope_owners && activity.type != 'control'">
              {{ $t('table.owner_assignee') }}
            </th>
            <th scope="col" v-if="activity.scope_members && activity.type != 'control'">
              {{ $t('table.member_assignees') }}
            </th>
            <th scope="col" class="text-center" v-if="activity.type != 'control'">
              {{ $t('table.alternative_responsible') }}
            </th>
            <th scope="col" v-if="activity.control_scope_owners && activity.type == 'control'">
              {{ $t('table.owner_assignee') }}
            </th>
            <th scope="col" v-if="activity.control_scope_members && activity.type == 'control'">
              {{ $t('table.member_assignees') }}
            </th>
            <th scope="col" class="text-center" v-if="activity.type == 'control'">
              {{ $t('table.alternative_responsible') }}
            </th>
            <th width="100"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in sTableItems">
              <td v-if="object.model">
                <router-link
                    :to="{ name: 'generic_controls.view', params: { slug: object.model.slug }}"
                    qid="p-view-page-link"
                >
                  {{ object.model.name }}
                </router-link>
              </td>
              <td v-if="activity.scope_owners && activity.type != 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.model.users, 'owner')">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
              </td>
              <td v-if="activity.scope_members && activity.type != 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.model.users, 'member')">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
              </td>
              <td class="text-center" v-if="activity.type != 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <button v-if="object.users.length > 3"
                        class="badge mr-1 rounded-circle"
                        @click="openUsersModal(object)"
                >
                  + {{ object.users.length - 3 }}
                </button>
                <button class="text-muted btn btn-sm btn-link mr-4 ml-2"
                        qid="edit-activity-modal"
                        @click="showEditModal(object)"
                        v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                >
                  <font-awesome-icon icon="pen" />
                </button>
              </td>
              <td v-if="activity.control_scope_owners && activity.type == 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.model.users, 'owner')">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
              </td>
              <td v-if="activity.control_scope_members && activity.type == 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.model.users, 'member')">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
              </td>
              <td class="text-center" v-if="activity.type == 'control' && object.model">
                <span class="mr-1" v-for="user in getReducedUsers(object.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <button v-if="object.users.length > 3"
                        class="badge mr-1 rounded-circle"
                        @click="openUsersModal(object)"
                >
                  + {{ object.users.length - 3 }}
                </button>
                <button class="text-muted btn btn-sm btn-link mr-4 ml-2"
                        qid="edit-activity-modal"
                        @click="showEditModal(object)"
                        v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                >
                  <font-awesome-icon icon="pen" />
                </button>
              </td>
              <td align="right" v-if="object.model">
                <button class="btn btn-delete"
                        qid="delete-activity-modal"
                        @click="showSConfirmDeleteModal(object)"
                        v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                >
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </td>
              <td v-if="!object.model" colspan="6">
                <span>
                  Content deleted
                </span>
              </td>
            </tr>
            <tr v-if="!sLoading && !sTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-3 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                        v-model="perPage"
                        :options="perPageOptions"
                        track-by="value"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        label="value"
                        qid="create-org-form-organization-level"
                        @select="perPageChanged"
                        :placeholder="selectedPerPage"
                        @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="selectedPerPage"
                    aria-controls="my-table"
                    @change="pageChanged"
                    limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete system modal template -->
    <b-modal ref="delete-ac" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add system modal -->
    <b-modal ref="edit-system"
             hide-footer
             @hide="hideEditSModal"
             size="xl"
    >
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('projects.generic_controls.create_title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="addItemsFormSubmit" qid="create-p-form">
        <div>
          <div class="row">
            <div class="col">
              <b-form-group>
                <label qid="edit-lb-category-label">
                  {{ $t('projects.generic_controls.create.add_label') }}
                </label>
                <div>
                  <b-form-input
                    type="text"
                    v-model="keywordValue"
                    @click="showSelectionTable = true"
                    qid="search-do-input"
                    v-on:input="debounceInput"
                    :placeholder="$t('projects.generic_controls.search_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>
              <div>
                <label class="float-right cursor-pointer ml-3 pt-2"
                       @click="unselectAll"
                       qid="system-unselect-button"
                >
                  {{ $t('system.unselect_all') }}
                </label>
                <label class="float-right cursor-pointer pt-2"
                       @click="selectAll"
                       qid="system-select-button"
                >
                  {{ $t('system.select_all') }}
                </label>
              </div>
              <br>
              <div class="form-control scrollable-height-400"
                   qid="tp-source-data-objects"
              >
                <div>
                  <div class="table-item-div cursor-pointer"
                       v-for="(item, index) in DOItems"
                       @click="selectDataObject(index, item)"
                       :class="{'table-item-div-selected': item.selected}"
                  >
                    <div class="text-bold">
                      {{ item.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="item.tags_translated && item.tags_translated.length" qid="activity-tags-list">
                          <span v-for="tag in getReducedTags(item.tags_translated)" :title="tag.name_translated" qid="p-tags-list">
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="item.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(item, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ item.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3">
                        <span v-if="item.type" >
                          <span class="badge mb-1">
                            <font-awesome-icon icon="shield-alt"/>
                            {{ item.type }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sLoading && !DOItems.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="text-muted mt-2 mb-3">
                {{ $t('projects.create.selected_items_tip') }}
              </div>
            </div>
            <div class="col-1">
              <b-button class="mt-300 btn-block"
                        variant="success"
                        @click="addData"
                        size="sm"
                        qid="system-select-button"
              >
                {{ $t('system.add') }} >
              </b-button>
            </div>
            <div class="col">
              <b-form-group>
                <label qid="edit-s-data-label">
                  {{ $t('projects.generic_controls.create.select_item_label') }}
                </label>
                <div>
                  <b-form-input
                    type="text"
                    v-model="keywordFilterValue"
                    qid="search-do-input"
                    v-on:input="filterSelectedData"
                    :placeholder="$t('projects.generic_controls.create.filter_placeholder')"
                  ></b-form-input>
                </div>
              </b-form-group>
              <br>
              <div class="form-control scrollable-height-400 mt-3" qid="tp-source-data-objects">
                <div>
                  <div class="table-item-div cursor-pointer"
                       v-for="(itemDO, index) in allDataObjectsFiltered"
                       :class="{'table-item-div-selected': itemDO.related}"
                  >
                    <span class="pt-2 pb-2 pl-2 pr-2 float-right">
                      <font-awesome-icon
                          icon="times"
                          size="sm"
                          @click="removeDataItem(itemDO)"
                          qid="tp-source-data-object-remove"
                          v-if="itemDO.new_item"
                      />
                    </span>
                    <div class="text-bold">
                      {{ itemDO.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="itemDO.tags_translated && itemDO.tags_translated.length" qid="activity-tags-list">
                          <span v-for="tag in getReducedTags(itemDO.tags_translated)" :title="tag.name_translated" qid="p-tags-list">
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="itemDO.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(itemDO, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ itemDO.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3">
                        <span v-if="itemDO.type" >
                          <span class="badge mb-1">
                            <font-awesome-icon icon="shield-alt"/>
                            {{ itemDO.type }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="table-item-div cursor-pointer"
                       v-for="(itemDO, index) in sTableItemsFull"
                       v-if="itemDO.model"
                       :class="{'table-item-div-selected': itemDO.related}"
                  >
                    <div class="text-bold">
                      {{ itemDO.model.name }}
                    </div>
                    <div>
                      <span class="mr-3">
                        <span v-if="itemDO.model.tags_translated && itemDO.model.tags_translated.length" qid="activity-tags-list">
                          <span v-for="tag in getReducedTags(itemDO.model.tags_translated)" :title="tag.name_translated" qid="p-tags-list">
                            <span class="badge" :title="tag.name_translated">
                              <font-awesome-icon icon="tag"/>
                              {{ tag.name_translated | truncate(35) }}
                            </span>
                          </span>
                          <span v-if="itemDO.model.tags_translated.length > 1"
                                class="badge cursor-pointer"
                                @click="openTagsModal(itemDO.model, $event)"
                          >
                            <font-awesome-icon icon="tags"/>
                            + {{ itemDO.model.tags_translated.length - 1 }}
                          </span>
                        </span>
                      </span>
                      <span class="mr-3">
                        <span v-if="itemDO.model.type" >
                          <span class="badge mb-1">
                            <font-awesome-icon icon="shield-alt"/>
                            {{ itemDO.model.type }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="text-center table-item-div" v-if="!sTableItemsFull.length && !allDataObjectsFiltered.length">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditSModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('systems.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    :disabled="buttonDisabled"
                    variant="success"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('systems.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Show data objects modal -->
    <b-modal ref="view-data-objects" hide-footer @hide="hideDataObjectsModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('maintenance.third_parties.data_objects.title') }}
        </h4>
      </div>
      <div class="mt-5" v-if="filteredDataObjects && filteredDataObjects.length">
        <div v-for="dataObject in filteredDataObjects">
          <div class="mb-4">
            <span class="badge float-right">
              {{ dataObject.classification }}
            </span>
            <span>{{ dataObject.name }}</span>
          </div>
        </div>
      </div>
      <div class="float-right">
        <b-button class="mt-3"
                  variant="success"
                  @click="hideDataObjectsModal"
                  qid="delete-tp-cancel-button"
        >
          {{ $t('maintenance.third_parties.data_objects.close') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Edit system modal -->
    <b-modal ref="edit-item" hide-footer @hide="hideEditModal">
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('projects.generic_controls.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('table.alternative_responsible') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                @search-change="loadUsers"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('projects.update.responsibles_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'GenericControlsTable',
    data: function() {
      return {
        sForm: {
          items: []
        },

        activity: {},

        // Systems
        sTableItems: [],
        sTableItemsFull: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        sItems: [],
        sLoading: true,
        selectedItem: {},
        showUsersModal: false,
        showTagsModal: false,

        currentPage: 1,
        totalRows: 0,
        perPage: '',
        selectedPerPage: "10",
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' },
        ],

        // Data objects
        allDataObjects: [],
        allDataObjectsFiltered: [],
        dataObjects: [],
        systemDataObjects: [],
        newDataObjects: [],
        DOItems: [],
        selectedDataObjects: [],
        availableDataObjects: [],

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        selectedUsers: [],
        users: [],
        radioOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0}
        ],
        buttonDisabled: false,
        keywordValue: '',
        keywordFilterValue: '',
        showSelectionTable: false,
        selectedFiles: [],
        inputLink: '',
        showDocuments: false,
        filteredDataObjects: [],
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      ShowMoreModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value;
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadDO(this.keywordValue)
      }, 400),
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Project' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Project',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.sForm.links.push(this.inputLink)
          // Reset link input form
          this.inputLink = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.sForm.links.splice(index, 1);
      },
      openTagsModal(object, evt) {
        this.selectedItem = object
        this.showTagsModal = true
        evt.stopPropagation();
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 1) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 1);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users, type) {
        let reducedUsers = []

        if (type == 'owner') {
          _.forEach(users, function (item) {
            if (item.owner) {
              reducedUsers.push(item)
            }
          })
        } else if (type == 'member') {
          _.forEach(users, function (item) {
            if (!item.owner) {
              reducedUsers.push(item)
            }
          })
        } else {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }

        return reducedUsers;
      },
      addData() {
        let self = this;
        _.forEach(this.DOItems, function (item) {
          if (item.selected) {
            self.updateDOList(item)
          }
        })
        this.keywordValue = '';
        this.loadDO()
      },
      filterSelectedData() {
        let self = this;
        this.allDataObjectsFiltered = [];
        if (self.keywordFilterValue) {
          _.forEach(this.allDataObjects, function (item) {
            if (
              item.name.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1 ||
              (item.category && item.category.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1) ||
              (item.classification && item.classification.toLowerCase().indexOf(self.keywordFilterValue.toLowerCase()) !== -1)
            ) {
              self.allDataObjectsFiltered.push(item)
            }
          })
        } else {
          this.allDataObjectsFiltered = _.cloneDeep(this.allDataObjects);
        }
      },
      resetFields() {
        this.selectedRC = null
        this.selectedP = null
      },
      showDataObjectsModal(object, classification) {
        let self = this;
        this.selectedS = object

        if (!object) {
          this.availableDataObjects = [];
        }

        _.forEach(object.data_objects, function (item) {
          if (item.classification == classification) {
            self.filteredDataObjects.push(item)
          }
        })

        this.$refs['view-data-objects'].show()
      },
      hideDataObjectsModal() {
        this.selectedS = {}
        this.filteredDataObjects = []
        this.$refs['view-data-objects'].hide()
      },
      showEditModal(object) {
        let self = this;
        this.error = '';
        this.loadUsers();

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
              self.selectedUsers.push(user)
          })
        }

        this.selectedS = object
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      editFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let responsibles = []
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            responsibles.push(this.selectedUsers[i].id);
          }
        }

        piincHttp.put('projects/' + this.$route.params.slug + '/models/' + this.selectedS.id, {
          users: responsibles
        }).then(function(response) {
          self.hideEditModal();
          self.loadGenericControls();
          self.loadGenericControlsFull();
          // Emit event to parent component to update newest validation
          self.$emit('reloadContent', response.data);
          self.$toastr('success', self.$t('projects.generic_controls.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.users) {
              self.error += ' ' + error.data.errors.users[0]
            }
          }
        })
      },
      showCreateSModal() {
        this.$refs['add-access-control'].show()
      },
      hideCreateSModal() {
        this.error = ''
        this.sForm.links = [];
        this.selectedFiles = [];
        this.selectedS = []
        this.$refs['add-access-control'].hide()
      },
      showSConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-ac'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-ac'].hide()
      },
      showDataObjects(object) {
        this.availableDataObjects = []
        if (object && object.data_objects && object.data_objects.length) {
          this.availableDataObjects = object.data_objects;
        }
      },
      hideDataObjects() {
        this.availableDataObjects = []
      },
      handleDO(index, item) {
        // Toggle related/not related property
        item.related = item.related ? false : true;
        this.$set(this.allDataObjects, index, item)
      },
      selectDataObject(index, item) {
        // Toggle related/not related property
        item.selected = item.selected ? false : true;
        this.$set(this.DOItems, index, item)
      },
      selectAll() {
        let newDataObjectsArray = []
        let self = this;
        _.forEach(this.DOItems, function (item) {
          item.selected = true;
          newDataObjectsArray.push(item)
        })
        this.DOItems = newDataObjectsArray;
      },
      unselectAll() {
        let newDataObjectsArray = []
        let self = this;
        _.forEach(this.DOItems, function (item) {
          item.selected = false;
          newDataObjectsArray.push(item)
        })
        this.DOItems = newDataObjectsArray;
      },
      relateAll() {
        let newDataObjectsArray = []
        _.forEach(this.allDataObjects, function (item) {
          item.related = true;
          newDataObjectsArray.push(item)
        })
        // make cloned array to avoid double actions on arrays
        this.allDataObjects = _.cloneDeep(newDataObjectsArray);
        this.allDataObjectsFiltered = _.cloneDeep(newDataObjectsArray);
      },
      unrelateAll() {
        let newDataObjectsArray = []
        _.forEach(this.allDataObjects, function (item) {
          item.related = false;
          newDataObjectsArray.push(item)
        })
        // make cloned array to avoid double actions on arrays
        this.allDataObjects = _.cloneDeep(newDataObjectsArray);
        this.allDataObjectsFiltered = _.cloneDeep(newDataObjectsArray);
      },
      updateDOList(selectedOption) {
        let self = this;
        let inArray = false;
        _.forEach(this.allDataObjects, function (item, index) {
          if (item.id == selectedOption.id) {
            // if item is already in array
            item.related = true;
            self.$set(self.allDataObjects, index, item)
            inArray = true;
            self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
          }
        })
        if (!inArray) {
          // If not in array, push it in array as related
          selectedOption.related = true;
          selectedOption.new_item = true;
          self.allDataObjects.push(selectedOption)
          self.allDataObjectsFiltered = _.cloneDeep(self.allDataObjects);
        }
      },
      removeDataItem(item) {
        // find indexes for both arrays
        let filteredIndex = _.findIndex(this.allDataObjectsFiltered, function(o) { return o.id == item.id; });
        let itemIndex = _.findIndex(this.allDataObjects, function(o) { return o.id == item.id; });

        // Remove item from data objects arrays
        this.allDataObjectsFiltered.splice(filteredIndex, 1);
        this.allDataObjects.splice(itemIndex, 1);

        this.loadDO()
      },
      showEditSModal(object) {
        let self = this;
        let inArray = false;

        this.loadDO();

        this.allDataObjectsFiltered = _.cloneDeep(this.allDataObjects);
        this.$refs['edit-system'].show()
      },
      hideEditSModal() {
        this.selectedDataObjects = []
        this.keywordValue = ''
        this.keywordFilterValue = ''
        this.showSelectionTable = false
        this.allDataObjects = []
        this.systemRoleInput = ''
        this.error = ''
        this.$refs['edit-system'].hide()
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('projects/' + this.$route.params.slug + '/models/' + object.id).then(function() {
          self.hideSConfirmDeleteModal();
          self.loadGenericControls();
          self.loadGenericControlsFull();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('projects.generic_controls.delete.success'))
        }, function() {});
      },

      addItemsFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        self.sForm.items = []

        _.forEach(this.allDataObjects, function (item) {
          self.sForm.items.push({model: item.who_am_i, id: item.id})
        })

        if (self.sForm.items && self.sForm.items.length) {
          piincHttp.post('/projects/' + this.$route.params.slug + '/models', {
            project_template: this.$route.params.slug,
            items: this.sForm.items
          }).then(function(response) {
            self.$toastr('success', self.$t('projects.generic_controls.create.success'))
            self.hideEditSModal();
            self.loadGenericControls();
            self.loadGenericControlsFull();
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors.process_id) {
                self.error += ' ' + error.data.errors.process_id[0]
              }
            }
          })
        } else {
          this.error = this.$t('projects.generic_controls.create.required')
          self.buttonDisabled = false;
        }

      },
      loadGenericControls(page, perPage) {
        let self = this;

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        self.sTableItems = []
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;

        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            model_type: 'App\\GenericControl\\Models\\GenericControl',
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.totalRows = response.data.total_count;
          self.sTableItems = response.data.items;
          self.sLoading = false;
        }, function() {});
      },
      loadGenericControlsFull() {
        let self = this;

        self.sTableItemsFull = []

        piincHttp.get('projects/' + this.$route.params.slug + '/models', { params:
          {
            per_page: 100,
            model_type: 'App\\GenericControl\\Models\\GenericControl',
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.sTableItemsFull = response.data.items;
        }, function() {});
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadGenericControls(page)
      },
      perPageChanged(perPage) {
        let self = this;
        // Load Third parties when page changed listener is triggered
        this.loadGenericControls(1, perPage.value)
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadGenericControls(1, self.selectedPerPage)

      },
      loadDO(query) {
        let self = this;
        self.DOItems = [];
        piincHttp.get('generic-controls', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined,
            type: this.object.type,
            scopes: this.object.scope? [this.object.scope] : undefined,
          }
        }).then(function(response) {
          let results = response.data.items
          _.forEach(results, function (item) {
            if (_.findIndex(self.sTableItemsFull, function(o) {
              if (o.model) {
                return o.model.id == item.id
              }
             }) == -1 && _.findIndex(self.allDataObjects, function(o) { return o.id == item.id }) == -1) {
              self.DOItems.push(item)
            }
          })
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        let role_name = [];

        role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        if (self.activity.type == 'validation') {
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        if (self.activity.type == 'control' || self.activity.type == 'security_measure') {
          role_name.push(self.permissions.ROLE_MEMBER);
          role_name.push(self.permissions.ROLE_VALIDATOR);
        }

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            role_name: role_name,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {
      }
      // Table items
      this.loadGenericControls();
      this.loadGenericControlsFull();
      this.loadGuides('project_generic_controls');
    }
  }
</script>
