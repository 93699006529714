<template>
    <div class="tree-result-filter card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="h5" v-if="dataSelectedCategory && dataSelectedCategory.id > 0">
              {{ $t('tasks.assessment.selected_tasks_title') }}:
              {{ dataSelectedCategory.name }}
            </h5>
            <h5 style="margin-bottom:5px;" v-else>
              {{ $t('tasks.assessment.all_tasks_title') }}
            </h5>
            <p v-if="dataSelectedCategory.description && dataSelectedCategory.description.length">
              {{ dataSelectedCategory.description }}
            </p>
          </div>
        </div>
      </div>


      <!-- View measure category -->
      <b-modal ref="view-category" modal-class="task-modal" @hide="hideViewMCModal">
        <template #modal-header="{ close }">
          <div class="" style="width: 100%;">
            <span @click="hideViewMCModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
              <font-awesome-icon icon="times"/>
            </span>
            <h5 class="mb-1 text-center" qid="view-mc-title">
              {{ $t('maintenance.measure_categories.view.title') }}
            </h5>
          </div>
        </template>

        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ error }}
        </b-alert>

        <label class="mt-3">
          {{ $t('maintenance.measure_categories.create.name_label') }}
        </label>
        <br>
        <span v-if="dataSelectedCategory.name" qid="view-mc-name">
          {{ dataSelectedCategory.name }}
        </span>
        <br>

        <label class="mt-3" v-if="dataSelectedCategory && dataSelectedCategory.scope">
          {{ $t('maintenance.measure_categories.create.scope_label') }}
        </label>
        <br>
        <span v-if="dataSelectedCategory && dataSelectedCategory.scope" qid="view-mc-scope">
          <span>
            <span v-for="(scope, index) in dataSelectedCategory.scope" :key="'view_mc_scope_' + index">
              <span v-if="scope == 'activity'" class="mr-1 text-normal">
                <font-awesome-icon icon="cubes"/>
                  {{ $t('projects.scope.activity') }}
              </span>

              <span v-if="scope == 'system'" class="text-normal ml-2">
                <font-awesome-icon icon="desktop"/>
                  {{ $t('projects.scope.system') }}
              </span>

              <span v-if="scope == 'third_party'" class="text-normal ml-2">
                <font-awesome-icon icon="exchange-alt"/>
                  {{ $t('projects.scope.third_party') }}
              </span>
            </span>
          </span>
        </span>

        <template #modal-footer="{ close }">
          <div class="" style="width: 100%;">
            <div class="alert alert-menu-color color-secondary mb-0" role="alert">
              <div class="row">
                <div class="col-sm-6">

                </div>
                <div class="col-sm-6">
                  <div class="text-right">
                    <b-button class=""
                              variant="secondary"
                              @click="hideViewMCModal"
                              qid="view-mc-cancel-button"
                    >
                      {{ $t('processes.policies.view.close') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'MeasureTopBar',
    data: function() {
      return {
        // loading: true,
        MCForm: {
          name: '',
          scope: []
        },
        error: '',
        selectedScopes: [],
        scopes: [
          { name: this.$t('projects.scope.activity'), value: 'activity' },
          { name: this.$t('projects.scope.system'), value: 'system' },
          { name: this.$t('projects.scope.third_party'), value: 'third_party' }
        ],
        buttonDisabled: false,
        itemToDelete: {},
        // copy of selectedCategory prop
        dataSelectedCategory: {}
      }
    },
    props: {
      selectedCategory: {},
    },
    watch: {
      selectedCategory: function (newValue, oldValue) {
        // Watching the prop and setting it's copy object which is used in this component
        if (newValue) {
          this.dataSelectedCategory = newValue
        } else {
          this.dataSelectedCategory = {}
        }
      },
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
    },
    methods: {
      // Measure category methods
      showViewMCModal() {
        this.$refs['view-category'].show()
      },
      hideViewMCModal() {
        this.$refs['view-category'].hide()
      },

      // Edit MC
      showEditMCModal(object) {
        let self = this;
        self.selectedScopes = [];
        this.error = '';
        if (object.scope && object.scope.length) {

          _.forEach(object.scope, function (value, key) {
            _.find(self.scopes, function(item) {
              if (item.value === value) {
                self.selectedScopes.push(item)
              }
            });
          })
        }

        this.MCForm.slug = object.slug;
        this.MCForm.name = object.name;
        this.$refs['edit-category'].show()
      },

      hideEditMCModal() {
        this.error = ''
        this.openCategory = {};
        this.$refs['edit-category'].hide()
      },

      editMCFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();
        let scopes = []

        // Append list of types
        if (this.selectedScopes && this.selectedScopes.length) {
          for (var i = 0; i < this.selectedScopes.length; i++) {
            scopes.push(this.selectedScopes[i].value)
          }
        }

        this.MCForm.client = this.getClient.slug;
        this.MCForm.scope = scopes;

        if (this.MCForm.scope.length && this.MCForm.name && this.MCForm.name.length) {
          piincHttp.put('measure-categories/' + this.MCForm.slug, this.MCForm).then(function(response) {
            self.hideEditMCModal();
            self.buttonDisabled = false;
            // Store new value from API for updated category
            self.dataSelectedCategory = response.data
            // Emit event together with new value
            self.$emit('forceSideFilterRenderComponent', self.dataSelectedCategory)
            self.$toastr('success', self.$t('maintenance.measure_categories.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.buttonDisabled = false;
          this.error = ''
          if (this.MCForm.name == '') {
            this.error +=  ' ' + this.$t('maintenance.incidents.incident_categories.create.name_error')
          }
          if (this.selectedScopes && !this.selectedScopes.length) {
            this.error +=  ' ' + this.$t('system.scope_error_message')
          }
        }
      },
      // Delete MC
      showConfirmDeleteMCModal(object) {
        this.itemToDelete = object
        this.$refs['delete-category'].show()
      },
      hideConfirmDeleteMCModal() {
        this.itemToDelete = {}
        this.$refs['delete-category'].hide()
      },
      confirmDeleteMC(object) {
        let self = this;
        piincHttp.delete('measure-categories/' + object.slug).then(function(response) {
          self.hideConfirmDeleteMCModal();
          self.$emit('forceSideFilterRenderComponent', null)
          // Trigger toastr message after MCModal close
          self.$toastr('success', self.$t('maintenance.measure_categories.delete.success'))
        }, function() {});
      }
    },
    created () {
      let self = this;
      // Create a copy of prop object to avoid directly mutating a prop (throws error)
      this.dataSelectedCategory = this.selectedCategory
    }
  }
</script>
