<template>

<div>
  <div class="card pagination-margin"
       v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], project)"
       qid="integrity-section"
  >
    <div class="card-header">
      <span class="float-right btn btn-success"
            @click="showFormFunction"
            qid="integrity-edit-button"
            v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed') && ((project.additional_client && getClient.slug != project.additional_client.slug) || project.additional_client == null)"
      >
        <font-awesome-icon icon="pen"/> {{ $t('system.edit') }}
      </span>
      <!--<h5 class="float-left"-->
          <!--qid="schedule-table-title"-->
      <!--&gt;-->
        <!--{{ $t('projects.view.schedule_title') }}-->
      <!--</h5>-->
    </div>

    <div class="row" v-if="project.control_type === 'continuous'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.scheduled_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <b-alert v-if="error.length"
                     show
                     variant="danger"
                     qid="create-s-error"
            >
              {{ error }}
            </b-alert>

            <b-form-radio-group
              v-model="form.scheduled"
              :options="radioOptions"
              qid="scheduled-radio-option"
            ></b-form-radio-group>
          </div>
          <div v-if="!showForm">
            <div class="section-container">
              <span v-if="project.scheduled">
                <font-awesome-icon icon="check-circle" class="icon-success"/>
                {{ $t('system.yes') }}
              </span>
              <span v-if="!project.scheduled">
                <font-awesome-icon icon="times-circle" class="icon-danger"/>
                {{ $t('system.no') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- One time -->

    <div class="row" v-if="project.control_type === 'one time'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.start_date_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <b-alert v-if="error.length"
                     show
                     variant="danger"
                     qid="create-s-error"
            >
              {{ error }}
            </b-alert>
            <datepicker
                class="date-picker"
                qid="form-start-date"
                calendar-class="left"
                v-model="form.start_date"
                @selected="form.end_date = ''"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.start_date_placeholder')"
            ></datepicker>
          </div>
          <div v-if="!showForm">
            <div class="section-container">
              <span v-if="project.start_date">
                {{ project.start_date | formatDate }}
              </span>
              <span v-if="!project.start_date" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'one time'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.due_date_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.end_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.due_date_placeholder')"
            ></datepicker>
          </div>
          <div v-if="!showForm">
            <div class="section-container">
              <span v-if="project.end_date">
                {{ project.end_date | formatDate }}
              </span>
              <span v-if="!project.end_date" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="project.control_type === 'one time' && project.type === 'control_assessment'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.data_due_date_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <datepicker
                class="date-picker"
                qid="form-end-date"
                calendar-class="left"
                v-model="form.data_collection_end_date"
                :required="true"
                :clear-button="true"
                :placeholder="$t('projects.schedule.data_due_date_placeholder')"
            ></datepicker>
          </div>
          <div v-if="!showForm">
            <div class="section-container">
              <span v-if="project.data_collection_end_date">
                {{ project.data_collection_end_date | formatDate }}
              </span>
              <span v-if="!project.data_collection_end_date" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Continuous -->

    <div class="row" v-if="project.control_type === 'continuous' && form.scheduled">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.start_date_label') }}</label>
          <div class="mw-500" v-if="showForm" :class="{'datepicker-disabled': !form.scheduled}">
            <datepicker
                class="date-picker"
                qid="form-start-date"
                calendar-class="left"
                v-model="form.start_date"
                :required="true"
                :clear-button="form.scheduled"
                :placeholder="$t('projects.schedule.start_date_placeholder')"
                :disabledDates="disabledDates"
            ></datepicker>
          </div>
          <div v-if="!showForm">
            <div class="section-container" :class="{'section-container-disabled': !project.scheduled}">
              <span v-if="project.start_date">
                {{ project.start_date | formatDate }}
              </span>
              <span v-if="!project.start_date" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'continuous' && form.scheduled">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.every_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <div class="row">
              <div class="col-sm-3">
                <b-form-input
                  qid="create-s-form-name"
                  type="number"
                  min="0"
                  :placeholder="$t('projects.schedule.every_placeholder')"
                  v-model="form.repeat_after"
                ></b-form-input>
              </div>
              <div class="col-sm-9">
                <multiselect
                    v-model="repeatInterval"
                    :options="repeatIntervalOptions"
                    :placeholder="$t('projects.schedule.interval_placeholder')"
                    label="text"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-p-form-pg"
                ></multiselect>
              </div>
            </div>
          </div>

          <div v-if="!showForm">
            <div class="section-container" :class="{'section-container-disabled': !project.scheduled}">
              <span v-if="project.repeat_after">
                {{ project.repeat_after }} &nbsp; {{ project.repeat_after_interval }}
                <div class="text-muted mt-2">
                  <span>{{ $t('projects.schedule.next_run_table_label') }}</span>:
                  {{ project.nextRun | formatDate }}
                </div>
              </span>
              <span v-if="!project.repeat_after" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'continuous'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.due_after_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <div class="row">
              <div class="col-sm-3">
                <b-form-input
                  qid="create-s-form-name"
                  type="number"
                  max="365"
                  min="0"
                  :placeholder="$t('projects.schedule.due_after_placeholder')"
                  v-model="form.due_after"
                ></b-form-input>
              </div>
              <div class="col-sm-9">
                <multiselect
                    v-model="dueInterval"
                    :options="repeatIntervalOptions"
                    :placeholder="$t('projects.schedule.interval_placeholder')"
                    label="text"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-p-form-pg"
                ></multiselect>
              </div>
            </div>
          </div>
          <div v-if="!showForm">
            <div class="section-container" :class="{'section-container-disabled': !project.scheduled}">
              <span v-if="project.due_after">{{ project.due_after }} &nbsp; {{ project.due_after_interval }}</span>
              <span v-if="!project.due_after" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'continuous' && project.type === 'control_assessment'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.data_due_after_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <div class="row">
              <div class="col-sm-3">
                <b-form-input
                  qid="create-s-form-name"
                  type="number"
                  max="365"
                  min="0"
                  :placeholder="$t('projects.schedule.data_due_after_placeholder')"
                  v-model="form.data_collection_due_after"
                ></b-form-input>
              </div>
              <div class="col-sm-9">
                <multiselect
                    v-model="dueDataInterval"
                    :options="repeatIntervalOptions"
                    :placeholder="$t('projects.schedule.interval_placeholder')"
                    label="text"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-p-form-pg"
                ></multiselect>
              </div>
            </div>
          </div>
          <div v-if="!showForm">
            <div class="section-container" :class="{'section-container-disabled': !project.scheduled}">
              <span v-if="project.data_collection_due_after">{{ project.data_collection_due_after }} &nbsp; {{ project.data_collection_due_after_interval }}</span>
              <span v-if="!project.data_collection_due_after" class="text-muted">
                {{ $t('system.missing_input') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'one time' && project.type !== 'control_assessment'">
      <div class="col-sm-12">
        <div class="card-body pb-0">
          <label class="text-bold">{{ $t('projects.schedule.message_label') }}</label>
          <div class="mw-500" v-if="showForm">
            <div class="row">
              <div class="col-sm-12">
                <b-form-textarea
                  qid="project-message-description"
                  v-model="form.schedule_message"
                  rows="6"
                  max-rows="6"
                  :placeholder="$t('projects.schedule.message_placeholder')"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div v-if="!showForm">
            <div class="section-container">
              <p v-if="project.schedule_message"
                 class="text-formatted"
              >{{ project.schedule_message }}</p>
              <p v-if="!project.schedule_message" class="text-muted">
                {{ $t('system.missing_input') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showForm">
      <div class="col-sm-12">
        <div class="card-body">
          <div class="mw-500 text-right" v-if="showForm">
            <div>
              <b-button class="mt-3"
                        variant="secondary"
                        @click="cancelForm"
                        qid="risk-form-cancel-button"
              >
                {{ $t('processes.legal_basis.create.cancel') }}
              </b-button>
              <b-button class="mt-3 ml-3"
                        variant="success"
                        @click="formSubmit"
                        qid="risk-form-submit-button"
              >
                {{ $t('processes.legal_basis.create.submit') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'one time' && project.type !== 'control_assessment'">
      <div class="col-sm-12">
        <div class="card-body">
          <label v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed') && ((project.additional_client && getClient.slug != project.additional_client.slug) || project.additional_client == null)"
                 class="btn btn-secondary cursor-pointer float-right"
                 qid="create-p-file-button"
          >
            <input type="file"
                   name="fileupload"
                   value="fileupload"
                   multiple
                   id="file"
                   @change="onFileChange"
                   @click="resetFile"
            >
            <span>
              <font-awesome-icon icon="cloud-upload-alt" />
              {{ $t('system.upload') }}
            </span>
          </label>
          <label>
            <span class="text-bold">{{ $t('attachments.label') }}</span>
            <br>
            <span>({{ $t('attachments.file_size_label') }})</span>
          </label>
          <div>
            <b-alert v-if="attachmentError.length"
                     show
                     variant="danger"
                     qid="attachments-p-attachmentError"
            >
              {{ attachmentError }}
            </b-alert>
            <div v-if="getMedia && getMedia.length">
              <p v-for="(file, file_index) in getMedia" class="section-container" :key="file_index">
                <span class="float-right text-muted cursor-pointer"
                      @click="openDeleteAttachmentModal(file)"
                      qid="remove-media-item"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
                <span class="mr-3 float-right text-muted cursor-pointer"
                      @click="downloadMedia(file)"
                      qid="download-media"
                >
                  <font-awesome-icon icon="cloud-download-alt" />
                </span>
                <a @click="downloadMedia(file)" qid="create-p-media-item">
                  {{ file.file_name }}
                </a>
              </p>
            </div>

            <p v-if="!getMedia.length" class="text-muted section-container">
              {{ $t('system.missing_input') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="project.control_type === 'one time' && project.type !== 'control_assessment'">
      <div class="col-sm-12">
        <div class="card-body">
          <span class="btn btn-link cursor-pointer float-right"
                @click="showInput = true"
                qid="add-another-link"
                v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed') && ((project.additional_client && getClient.slug != project.additional_client.slug) || project.additional_client == null)"
          >
            <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
          </span>
          <label class="text-bold">
            {{ $t('links.label') }}
          </label>
          <div>
            <div v-if="getLinks && getLinks.length">
              <p v-for="(link, link_index) in getLinks" class="section-container" :key="link_index">
                <span class="float-right text-muted cursor-pointer"
                      @click="openDeleteLinkModal(link)"
                      qid="view-p-remove-link"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
                <a :href="link.url" target="_blank" qid="create-p-link-item">{{ link.name ? link.name : link.url }}</a>
              </p>
            </div>
            <p v-if="!getLinks.length" class="text-muted section-container">
              {{ $t('system.missing_input') }}
            </p>
            <div v-if="showInput">
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLink"
                :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                qid="create-p-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                type="text"
                aria-describedby="inputGroup"
                v-model="inputLinkName"
                :placeholder="inputLink ? inputLink : $t('table.name')"
                qid="create-p-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup"
                      @click="handleLink"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="mb-3"></div>-->
  </div>

  <ConfirmRemoveAttachment
      :object="selectedFile"
      :show="showDeleteAttachmentModal"
      type="file"
      @closeConfirmModal="closeDeleteAttachmentModal"
  ></ConfirmRemoveAttachment>

  <ConfirmRemoveAttachment
      :object="selectedLink"
      :show="showDeleteLinkModal"
      type="link"
      @closeConfirmModal="closeDeleteLinkModal"
  ></ConfirmRemoveAttachment>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Datepicker from 'vuejs-datepicker';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';

  let dateObj = new Date();
   // subtract one day from current time
  dateObj.setDate(dateObj.getDate() - 1);

  export default {
    name: 'ScheduleTable',
    data: function() {
      return {
        form: {
          scheduled: '',
          start_date: '',
          end_date: '',
          repeat_after: '',
          repeat_after_interval: '',
          due_after: '',
          due_after_interval: '',
          data_collection_due_after: '',
          data_collection_due_after_interval: '',
          schedule_message: ''
        },
        disabledDates: {
          to: new Date(Date.now())
        },
        disabledDatesOneTime: {
          to: dateObj
        },
        error: '',
        attachmentError: '',
        loadingItems: false,
        showForm: false,
        project: {},
        radioOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        repeatIntervalOptions: [
          {text: this.$t('system.interval_days'), value: 'Days'},
          {text: this.$t('system.interval_months'), value: 'Months'},
          {text: this.$t('system.interval_years'), value: 'Years'},
        ],
        repeatInterval: [],
        dueInterval: [],
        dueDataInterval: [],
        showInput: false,
        inputLink: '',
        inputLinkName: '',
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
      }
    },
    props: {
      object: {
        type: Object
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getMedia() {
        let media = []

        media = _.filter(this.project.media, function(mediaItem) {
          return mediaItem.collection_name === 'documents' || mediaItem.collection_name === 'project';
        });

        return media
      },
      getLinks() {
        let links = []

        links = _.filter(this.project.links, function(link) {
          return link.collection == null;
        });

        return links
      }
    },
    components: {
      Datepicker,
      ConfirmRemoveAttachment
    },
    watch: {
      object: function (value) {
        this.project = value
        this.setDefaultForm(value)
      }
    },
    methods: {

      formSubmit() {
        var moment = require('moment');
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.repeat_after_interval = this.repeatInterval ? this.repeatInterval.value : '';
        this.form.due_after_interval = this.dueInterval ? this.dueInterval.value : '';
        this.form.data_collection_due_after_interval = this.dueDataInterval ? this.dueDataInterval.value : '';

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : ''
        this.form.end_date = this.form.end_date ? moment(this.form.end_date).format('YYYY-MM-DD') : ''
        this.form.data_collection_end_date = this.form.data_collection_end_date ? moment(this.form.data_collection_end_date).format('YYYY-MM-DD') : ''

        if (this.project.type === 'control_assessment') {
          if (this.project.control_type === 'one time') {
            if (!this.form.start_date || !this.form.end_date || !this.form.data_collection_end_date) {
              if (!this.form.start_date) {
                this.error = this.$t('projects.schedule.start_date_error')
              }
              if (!this.form.end_date) {
                this.error = this.error + ' ' + this.$t('projects.schedule.end_date_error')
              }
              if (!this.form.data_collection_end_date) {
                this.error = this.error + ' ' + this.$t('projects.schedule.data_end_date_error')
              }
            } else if (this.form.start_date > this.form.end_date) {
              this.error = this.$t('projects.schedule.start_date_greater_error')
            } else {
              piincHttp.put('projects/' + this.project.slug, this.form).then(function(response) {
                self.project = response.data
                self.$emit('updated');
                self.showForm = false;
                self.setDefaultForm(self.project);
                self.$toastr('success', self.$t('projects.update.success'))
              }, function(error) {
                if (error.status !== 200) {
                  self.error = error.data.message;
                }
              })
            }
          }

          if (this.project.control_type === 'continuous') {
            if (!this.form.start_date && this.form.scheduled) {
              this.error = this.$t('projects.schedule.start_date_error')
            } else if ((this.form.due_after < 0 || this.form.due_after > 365) && this.form.scheduled) {
              this.error = this.$t('projects.schedule.max_due_after_error')
            } else if ((!this.form.repeat_after_interval || !this.form.due_after_interval || !this.form.repeat_after || !this.form.due_after || !this.form.data_collection_due_after || !this.form.data_collection_due_after_interval) && this.form.scheduled) {
              this.error = this.$t('projects.schedule.required_error')
            } else {
              piincHttp.put('projects/' + this.project.slug, this.form).then(function(response) {
                self.project = response.data
                self.$emit('updated');
                self.showForm = false;
                self.setDefaultForm(self.project);
                self.$toastr('success', self.$t('projects.update.success'))
              }, function(error) {
                if (error.status !== 200) {
                  self.error = error.data.message;
                }
              })
            }
          }

        } else {
          if (this.project.control_type === 'one time') {
            if (!this.form.start_date || !this.form.end_date) {
              if (!this.form.start_date) {
                this.error = this.$t('projects.schedule.start_date_error')
              }
              if (!this.form.end_date) {
                this.error = this.error + ' ' + this.$t('projects.schedule.end_date_error')
              }
            } else if (this.form.start_date > this.form.end_date) {
              this.error = this.$t('projects.schedule.start_date_greater_error')
            } else {
              piincHttp.put('projects/' + this.project.slug, this.form).then(function(response) {
                self.project = response.data
                self.$emit('updated');
                self.showForm = false;
                self.setDefaultForm(self.project);
                self.$toastr('success', self.$t('projects.update.success'))
              }, function(error) {
                if (error.status !== 200) {
                  self.error = error.data.message;
                }
              })
            }
          }

          if (this.project.control_type === 'continuous') {
            if (!this.form.start_date && this.form.scheduled) {
              this.error = this.$t('projects.schedule.start_date_error')
            } else if ((this.form.due_after < 0 || this.form.due_after > 365) && this.form.scheduled) {
              this.error = this.$t('projects.schedule.max_due_after_error')
            } else if ((!this.form.repeat_after_interval || !this.form.due_after_interval || !this.form.repeat_after || !this.form.due_after) && this.form.scheduled) {
              this.error = this.$t('projects.schedule.required_error')
            } else {
              piincHttp.put('projects/' + this.project.slug, this.form).then(function(response) {
                self.project = response.data
                self.$emit('updated');
                self.showForm = false;
                self.setDefaultForm(self.project);
                self.$toastr('success', self.$t('projects.update.success'))
              }, function(error) {
                if (error.status !== 200) {
                  self.error = error.data.message;
                }
              })
            }
          }
        }

      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.loadContent();
        this.$emit('updated');
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.loadContent();
        this.$emit('updated');
      },
      loadContent() {
        let self = this;
        piincHttp.get('projects/' + this.$route.params.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.project = response.data;
        }, function() {});
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("model_type", this.project.who_am_i);
        formData.append("model_id", this.project.id);

        piincHttp.post('links', formData).then(function() {
          self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
          self.loadContent()
          self.$emit('updated');
          self.inputLink = ''
          self.inputLinkName = ''
          self.showInput = false
        }, function() {})
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.project.who_am_i);
        formData.append("model_id", this.project.id);

        if (sizeError) {
          this.attachmentError = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
            self.attachmentError = ''
            self.loadContent()
            self.$emit('updated');
          }, function(error) {
            self.attachmentError = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
      cancelForm() {
        this.showForm = false
      },
      showFormFunction() {
        this.setDefaultForm(this.project)
        this.showForm = true
        this.error = ''
      },
      setDefaultForm(object) {
        var moment = require('moment');

        this.form.scheduled = object.scheduled ? object.scheduled : 0;

        if (this.project.control_type === 'one time') {
          this.form.start_date = object.start_date ? object.start_date : moment().toDate();
          if (object.end_date) {
            this.form.end_date = object.end_date
          } else {
            this.form.end_date = moment().add(14, 'day').toDate()
          }
        } else {
          this.form.start_date = object.start_date ? object.start_date : moment().add(1,'days').toDate();
          this.form.end_date = object.end_date ? object.end_date : ''
        }

        this.form.repeat_after = object.repeat_after;
        this.form.due_after = object.due_after;
        this.form.data_collection_due_after = object.data_collection_due_after;
        this.form.schedule_message = object.schedule_message;

        this.repeatInterval = _.find(this.repeatIntervalOptions, function(item) {
          return item.value === object.repeat_after_interval;
        });

        this.dueInterval = _.find(this.repeatIntervalOptions, function(item) {
          return item.value === object.due_after_interval;
        });

        this.dueDataInterval = _.find(this.repeatIntervalOptions, function(item) {
          return item.value === object.data_collection_due_after_interval;
        });

      },
    },
    created () {
      this.project = this.object
    }
  }
</script>
