<template>
  <div>
    <div class="card"
         v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], project) && project.control_type === 'one time' && project.type !== 'control_assessment'"
         qid="integrity-section"
    >
      <div class="card-header">
        <h5 class="float-left"
            qid="integrity-table-title"
        >
          {{ $t('projects.create.task_assignees') }}
        </h5>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card-body pt-0 pb-0">

            <div class="mw-500" v-if="showForm">
              <div>
                <label>
                  {{ $t('projects.create.default_task_assignees') }}
                </label>
                <multiselect
                    v-model="selectedUsers"
                    :options="users"
                    :multiple="true"
                    :taggable="false"
                    @search-change="loadUsers"
                    select-label="✔"
                    selected-label="✔"
                    deselect-label="x"
                    track-by="slug"
                    :placeholder="$t('projects.create.default_task_assignees')"
                    label="name"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <span class="mr-3 user-tag">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
                <br>
                <b-form-checkbox
                  v-model="applyOnTasks"
                  v-if="project.control_type != 'continuous'"
                  name="checkbox-disable"
                  :value="1"
                  :unchecked-value="0"
                >
                  Apply for all tasks
                </b-form-checkbox>
                <div>
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="cancelForm"
                            qid="risk-form-cancel-button"
                  >
                    {{ $t('processes.legal_basis.create.cancel') }}
                  </b-button>
                  <b-button class="mt-3 ml-3"
                            variant="success"
                            @click="formSubmit"
                            qid="risk-form-submit-button"
                  >
                    {{ $t('processes.legal_basis.create.submit') }}
                  </b-button>
                </div>
                <br>
              </div>
              <br>
            </div>
            <div v-if="!showForm">
              <div class="">
                <span class="float-right btn btn-secondary btn-sm"
                      @click="showFormFunction"
                      qid="integrity-edit-button"
                      style="margin-top:-10px;"
                      v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status !== 'closed' && project.status !== 'completed') && ((project.additional_client && getClient.slug !== project.additional_client.slug) || project.additional_client === null)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                <label>
                  {{ $t('projects.create.default_task_assignees') }}
                </label>
                <div class="section-container" v-if="project.scope_users && project.scope_users.length">
                  <span class="mr-1"
                        v-for="(user, index) in project.scope_users"
                        :key="index"
                        qid="activity-users-list">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </div>
                <p v-if="!project.scope_users || !project.scope_users.length" class="text-muted section-container">
                  {{ $t('system.missing_input') }}
                </p>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card-body">
            <div class="mw-500" v-if="showDataCollectionForm">
              <div>
                <label>{{ $t('projects.create.data_collection_task_assignees') }}</label>
                <multiselect
                    v-model="selectedDataCollectionUsers"
                    :options="users"
                    :multiple="true"
                    :taggable="false"
                    @search-change="loadUsers"
                    select-label="✔"
                    selected-label="✔"
                    deselect-label="x"
                    track-by="slug"
                    :placeholder="$t('projects.create.data_collection_task_assignees')"
                    label="name"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <span class="mr-3 user-tag">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>

                <br>

                <b-form-checkbox
                  v-model="applyOnDataCollectionTasks"
                  v-if="project.control_type != 'continuous'"
                  name="checkbox-disable"
                  :value="1"
                  :unchecked-value="0"
                >
                  Apply for all tasks
                </b-form-checkbox>
                <div>
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="cancelDataCollectionForm"
                            qid="risk-form-cancel-button"
                  >
                    {{ $t('processes.legal_basis.create.cancel') }}
                  </b-button>
                  <b-button class="mt-3 ml-3"
                            variant="success"
                            @click="formDataCollectionSubmit"
                            qid="risk-form-submit-button"
                  >
                    {{ $t('processes.legal_basis.create.submit') }}
                  </b-button>
                </div>
                <br>
              </div>
            </div>
            <div v-if="!showDataCollectionForm">
              <div>
                <span class="float-right btn btn-secondary btn-sm"
                      @click="showDataCollectionFormFunction"
                      qid="integrity-edit-button"
                      style="margin-top:-10px;"
                      v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status !== 'closed' && project.status !== 'completed') && ((project.additional_client && getClient.slug !== project.additional_client.slug) || project.additional_client === null)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                <label>
                  {{ $t('projects.create.data_collection_task_assignees') }}
                </label>
                <div class="section-container" v-if="project.data_collection_scope_users && project.data_collection_scope_users.length">
                  <span class="mr-1"
                        v-for="(user, index) in project.data_collection_scope_users"
                        :key="index"
                        qid="activity-users-list">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </div>
                <p v-if="!project.data_collection_scope_users || !project.data_collection_scope_users.length" class="text-muted section-container">
                  {{ $t('system.missing_input') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="project.control_type === 'continuous' && project.include_mapping">
        <div class="col-sm-12">
          <div class="card-body">

            <div class="mw-500" v-if="showMeasureForm">
              <div>
                <label>
                  {{ $t('projects.measures.mapping_task_assignee_label') }}
                </label>
                <multiselect
                    v-model="selectedMeasureUsers"
                    :options="users"
                    :multiple="true"
                    :taggable="false"
                    @search-change="loadUsers"
                    select-label="✔"
                    selected-label="✔"
                    deselect-label="x"
                    track-by="slug"
                    :placeholder="$t('projects.measures.mapping_task_assignee_placeholder')"
                    label="name"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <span class="mr-3 user-tag">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
                <div>
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="cancelMeasureForm"
                            qid="risk-form-cancel-button"
                  >
                    {{ $t('processes.legal_basis.create.cancel') }}
                  </b-button>
                  <b-button class="mt-3 ml-3"
                            variant="success"
                            @click="formMeasureSubmit"
                            qid="risk-form-submit-button"
                  >
                    {{ $t('processes.legal_basis.create.submit') }}
                  </b-button>
                </div>
                <br>
              </div>
              <br>
            </div>
            <div v-if="!showMeasureForm">
              <div class="">
                <span class="float-right btn btn-secondary btn-sm"
                      @click="showMeasureFormFunction"
                      qid="integrity-edit-button"
                      style="margin-top:-10px;"
                      v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status !== 'closed' && project.status !== 'completed') && ((project.additional_client && getClient.slug !== project.additional_client.slug) || project.additional_client === null)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>
                <label>
                  {{ $t('projects.measures.mapping_task_assignee_label') }}
                </label>
                <div class="section-container" v-if="project.measure_users && project.measure_users.length">
                  <span class="mr-1"
                        v-for="(user, index) in project.measure_users"
                        :key="index"
                        qid="activity-users-list">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </div>
                <p v-if="!project.measure_users || !project.measure_users.length" class="text-muted section-container">
                  {{ $t('system.missing_input') }}
                </p>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-3"
         v-if="isGranted([permissions.PROJECT_VIEW, permissions.PROJECT_VIEW_OWN], project) && project.control_type === 'one time'"
         qid="integrity-section"
    >
      <div class="card-header">
        <span class="float-right btn btn-success"
              @click="showScopeFormFunction"
              qid="integrity-edit-button"
              v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status !== 'closed' && project.status !== 'completed') && ((project.additional_client && getClient.slug !== project.additional_client.slug) || project.additional_client === null)"
        >
          <font-awesome-icon icon="pen"/> {{ $t('system.edit') }}
        </span>
        <h5 class="float-left"
            qid="schedule-table-title"
        >
          {{ $t('projects.view.scope_period_title') }}
        </h5>
      </div>

      <!-- One time -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card-body pt-0 pb-0">
            <label for="">{{ $t('projects.scope.start_date_label') }}</label>
            <div class="mw-500" v-if="showScopeForm">
              <b-alert v-if="error.length"
                       show
                       variant="danger"
                       qid="create-s-error"
              >
                {{ error }}
              </b-alert>
              <datepicker
                      class="date-picker"
                      qid="form-start-date"
                      calendar-class="left"
                      v-model="form.scope_start_date"
                      @selected="form.scope_end_date = ''"
                      :required="true"
                      :clear-button="true"
                      :placeholder="$t('projects.scope.start_date_placeholder')"
              ></datepicker>
            </div>
            <div v-if="!showScopeForm">
              <div class="section-container">
                <span v-if="project.scope_start_date">
                  {{ project.scope_start_date | formatDate }}
                </span>
                <span v-if="!project.scope_start_date" class="text-muted">
                  {{ $t('system.missing_input') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card-body pb-0">
            <label for="">{{ $t('projects.scope.end_date_label') }}</label>
            <div class="mw-500" v-if="showScopeForm">
              <datepicker
                      class="date-picker"
                      qid="form-end-date"
                      calendar-class="left"
                      v-model="form.scope_end_date"
                      :required="true"
                      :clear-button="true"
                      :placeholder="$t('projects.scope.end_date_placeholder')"
              ></datepicker>
            </div>
            <div v-if="!showScopeForm">
              <div class="section-container">
                <span v-if="project.scope_end_date">
                  {{ project.scope_end_date | formatDate }}
                </span>
                <span v-if="!project.scope_end_date" class="text-muted">
                  {{ $t('system.missing_input') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-sm-12">
          <div class="card-body pb-3">
            <label for="">{{ $t('projects.scope.message_label') }}</label>
            <div class="mw-500" v-if="showScopeForm">
              <div class="row">
                <div class="col-sm-12">
                  <b-form-textarea
                          qid="project-message-description"
                          v-model="form.scope_description"
                          rows="6"
                          max-rows="6"
                          :placeholder="$t('projects.scope.message_placeholder')"
                  ></b-form-textarea>
                </div>
              </div>
            </div>
            <div v-if="!showScopeForm">
              <div class="section-container">
                <p v-if="project.scope_description"
                   class="text-formatted"
                >{{ project.scope_description }}</p>
                <p v-if="!project.scope_description" class="text-muted">
                  {{ $t('system.missing_input') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="showScopeForm">
        <div class="col-sm-12">
          <div class="card-body">
            <div class="mw-500 text-right" v-if="showScopeForm">
              <div>
                <b-button class="mt-3"
                          variant="secondary"
                          @click="cancelScopeForm"
                          qid="risk-form-cancel-button"
                >
                  {{ $t('processes.legal_basis.create.cancel') }}
                </b-button>
                <b-button class="mt-3 ml-3"
                          variant="success"
                          @click="formScopeSubmit"
                          qid="risk-form-submit-button"
                >
                  {{ $t('processes.legal_basis.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';

  export default {
    name: 'ControlAssessmentScopeTable',
    data: function() {
      return {
        form: {
          control_scope_users: [],
          scope_start_date: '',
          scope_end_date: '',
          scope_description: ''
        },
        dataCollectionform: {
          control_scope_users: []
        },
        applyOnTasks: 0,
        applyOnDataCollectionTasks: 0,
        error: '',
        showForm: false,
        showScopeForm: false,
        showDataCollectionForm: false,
        project: {},
        scopeOptions: [],
        radioOptions: [
          {text: this.$t('system.yes'), value: 'Yes'},
          {text: this.$t('system.no'), value: 'No'},
        ],
        users: [],
        selectedUsers: [],
        selectedDataCollectionUsers: [],
        showMeasureForm: false,
        selectedMeasureUsers: [],
      }
    },
    props: {
      object: {
        type: Object
      },
      measureTask: {
        type: Object
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    components: {
      Avatar,
      Datepicker
    },
    watch: {
      object: function (value) {
        this.project = value
      }
    },
    methods: {
      cancelScopeForm() {
        this.showScopeForm = false
      },
      formScopeSubmit() {
        var moment = require('moment');
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;


        this.form.scope_start_date = this.form.scope_start_date ? this.form.scope_start_date : ''
        this.form.scope_end_date = this.form.scope_end_date ? this.form.scope_end_date : ''

        if (!this.form.scope_start_date || !this.form.scope_end_date) {
          if (!this.form.scope_start_date) {
            this.error = this.$t('projects.schedule.start_date_error')
          }
          if (!this.form.scope_end_date) {
            this.error = this.error + ' ' + this.$t('projects.schedule.end_date_error')
          }
        } else if (this.form.scope_start_date > this.form.scope_end_date) {
          this.error = this.$t('projects.schedule.start_date_greater_error')
        } else {
          piincHttp.put('projects/' + this.project.slug, this.form).then(function(response) {
            self.project = response.data
            self.$emit('updated');
            self.showScopeForm = false;
            self.setDefaultForm(self.project);
            self.$toastr('success', self.$t('projects.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.error = error.data.message;
            }
          })
        }
      },
      formSubmit(evt) {
        let self = this;

        this.form.scope_users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.form.scope_users.push(this.selectedUsers[i].id);
          }
        }

        this.form.apply_on_tasks = this.applyOnTasks

        piincHttp.put('projects/' + this.project.slug, {
          scope_users: this.form.scope_users,
          apply_on_tasks: this.applyOnTasks
        }).then(function(response) {
          self.project = response.data
          self.applyOnTasks = 0;
          self.$emit('updated');
          self.showForm = false;
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      formMeasureSubmit(evt) {
        let self = this;

        let measure_users = [];
        if (this.selectedMeasureUsers && this.selectedMeasureUsers.length) {
          for (var i = 0; i < this.selectedMeasureUsers.length; i++) {
            measure_users.push(this.selectedMeasureUsers[i].id);
          }
        }

        piincHttp.put('projects/' + this.project.slug, {
          measure_task_users: measure_users,
        }).then(function(response) {
          self.project = response.data
          self.$emit('updated');
          self.showMeasureForm = false;
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })

      },
      cancelMeasureForm() {
        this.showMeasureForm = false;
        this.error = ''
      },
      showMeasureFormFunction() {
        let self = this;
        this.loadUsers();

        this.selectedMeasureUsers = [];
        _.forEach(this.project.measure_users, function (user) {
          self.selectedMeasureUsers.push(user)
        })

        this.showMeasureForm = true
      },
      formDataCollectionSubmit(evt) {
        let self = this;

        this.form.data_collection_scope_users = [];
        if (this.selectedDataCollectionUsers && this.selectedDataCollectionUsers.length) {
          for (var i = 0; i < this.selectedDataCollectionUsers.length; i++) {
            this.form.data_collection_scope_users.push(this.selectedDataCollectionUsers[i].id);
          }
        }

        this.form.apply_on_data_collection_tasks = this.applyOnDataCollectionTasks

        piincHttp.put('projects/' + this.project.slug, {
          data_collection_scope_users: this.form.data_collection_scope_users,
          apply_on_tasks: this.applyOnDataCollectionTasks
        }).then(function(response) {
          self.project = response.data
          self.applyOnDataCollectionTasks = 0;
          self.$emit('updated');
          self.showDataCollectionForm = false;
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      cancelForm() {
        this.showForm = false;
        this.applyOnTasks = 0;
        this.form.scopeOptions = '';
      },
      cancelDataCollectionForm() {
        this.showDataCollectionForm = false;
        this.applyOnDataCollectionTasks = 0;
        this.form.scopeOptions = '';
      },
      showFormFunction() {
        let self = this;
        this.loadUsers();

        this.selectedUsers = [];
        _.forEach(this.project.scope_users, function (user) {
          self.selectedUsers.push(user)
        })

        this.showForm = true
      },
      showScopeFormFunction() {
        let self = this;
        this.setDefaultForm(this.project)
        this.showScopeForm = true
      },
      showDataCollectionFormFunction() {
        let self = this;
        this.loadUsers();

        this.selectedDataCollectionUsers = [];
        _.forEach(this.project.data_collection_scope_users, function (user) {
          self.selectedDataCollectionUsers.push(user)
        })

        this.showDataCollectionForm = true
      },
      loadProcess() {
        let self = this;
        piincHttp.get('processes/' + this.project.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.project = response.data;
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
        }, function() {});
      },
      setDefaultForm(object) {
        var moment = require('moment');

        this.form.scope_start_date = object.scope_start_date ? object.scope_start_date : moment().toDate();
        if (object.scope_end_date) {
          this.form.scope_end_date = object.scope_end_date
        } else {
          this.form.scope_end_date = moment().add(14, 'day').toDate()
        }

        this.form.scope_description = object.scope_description;

      },
    },
    created () {
      this.project = this.object
      this.loadUsers();
    }
  }
</script>
